import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { Constants } from 'react-native-unimodules'
import Slider from '@react-native-community/slider'
import { LinearGradient } from 'expo-linear-gradient'
import { useDebouncedCallback } from 'use-debounce'
import moment from 'moment-timezone'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import Svg, { G, Rect, Path, Polygon } from 'react-native-svg'
import { EventEmitter } from '../../services/events'

const Controls = props => {
  const [value, setValue] = useState(props.value)
  const [isDragging, setDragging] = useState(false)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const debounced = useDebouncedCallback(
    (val) => {
      console.log('debounced')
      if (Math.abs(value - val) > 0.05) {
        setValue(val)
        props.onChange(val)
      }
    },
    250
  );

  useEffect(() => {
    if (isDragging || !props.showBar) return

    EventEmitter.subscribe('controls.time', time => {
      console.log('time', time)
      setValue(time / props.duration * 100)
    })

    return () => {
      EventEmitter.unsubscribe('controls.time')
    }
  }, [isDragging, props.showBar])

  const currentTime = moment.duration(value * props.duration / 100, 'seconds')
  const cHours = currentTime.hours()
  const cMinutes = currentTime.minutes()
  const cSeconds = currentTime.seconds()

  const gradientColors = props.position === 'top' ? ['rgba(0, 0, 0, 0.6)', 'rgba(0, 0, 0, 0.1)'] : ['rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.6)']

  return (
    <View style={props.style}>
      <StyledLinearGradient $isVisible={props.showBar} $isFull colors={gradientColors}>
        <BarSlider>
          <StyledText>{ `${cHours}:${cMinutes < 10 ? `0${cMinutes}` : cMinutes}:${cSeconds < 10 ? `0${cSeconds}` : cSeconds}` }</StyledText>
          <StyledSlider
            minimumValue={0}
            maximumValue={100}
            minimumTrackTintColor="#ffffff"
            thumbTintColor="#ffffff"
            tapToSeek
            value={value}
            onValueChange={debounced.callback}
            onSlidingStart={() => setDragging(true)}
            onSlidingComplete={() => setDragging(false)}
          />
        </BarSlider>
      </StyledLinearGradient>
    </View>
  )
}
Controls.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
}

const StyledText = styled.Text`
  color: #ffffff;
  font-size: 14px;
`

const StyledSlider = styled(Slider)`
  flex: 1 1 auto;
  margin: 0 10px;
`

const StyledLinearGradient = styled(LinearGradient)`
  display: ${props => props.$isVisible ? 'flex' : 'none'};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${props => props.$isFull ? 'flex: 1 1 auto' : 'flex: 0 0 30px'};
`

const BarSlider = styled.View`
  padding-left: 10px;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: center;
  height: 30px;
`

export default styled(Controls)`
  position: absolute;
  left: ${props => props.leftInset || 0}px;
  right: ${props => props.rightInset || 0}px;
  align-items: center;
  ${props => props.position === 'top' ? `top: ${props.topInset || 0}px` : `bottom: ${props.bottomInset || 0}px`};
  height: 30px;
  flex-direction: row;
  z-index: 100;
  justify-content: flex-end;
`
