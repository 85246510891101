import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Platform, FlatList, SafeAreaView, View } from 'react-native'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import moment from 'moment-timezone'
import Question from '../Chat/message/Question'
import Avatar from '../Chat/message/Avatar'
import { makeFilteredQuestionsSelector } from '../../selectors'
import i18n from '../../services/i18n'

const timeZone = moment.tz.guess()
const getHumanCreatedAt = val => moment(val).tz(timeZone).format('LT')

const areEqual = (prev, next) => prev.item.data.answer === next.item.data.answer

const RenderItem = React.memo(({ item }) => (
  <QuestionContainer key={item.id}>
    <Wrapper>
      <Avatar isAdmin={!item.author.cid} avatarUrl={item.author.avatar_url} size="large" />
      <Body>
        <QuestionTitle>
          <Author>{ item.author.name }</Author>
          <SentAt>{ getHumanCreatedAt(item.created_at) }</SentAt>
        </QuestionTitle>
        <Question data={{
          question: item.data.question,
          answer: item.data.answer,
          answeredBy: item.answered_by
        }} />
      </Body>
    </Wrapper>
  </QuestionContainer>
), areEqual)

const QA = props => {
  const [mode, setMode] = useState('answered')
  const selectQuestions = useMemo(makeFilteredQuestionsSelector, [])
  const questions = useSelector(state => selectQuestions(state, mode === 'answered'))
  const firstQuestion = questions[0]
  const flatList = useRef(null)
  const prevScrollOffset = useRef()
  const prevFirstItem = useRef()
  const [isBottom, setIsBottom] = useState(!visitor.viewer_session.is_replay)

  const getLabel = (byMode, text) => (
    mode === byMode
    ? <AnsweredText>{ text }</AnsweredText>
    : <NonAnsweredText>{ text }</NonAnsweredText>
  )

  useEffect(() => {
    setTimeout(() => {
      if (flatList.current && isBottom && !visitor.viewer_session.is_replay && questions.length) {
        setTimeout(() => {
          flatList.current.scrollToItem({ item: questions[questions.length - 1], animated: false })
        }, 0)
      }
    })
  }, [questions.length])

  useEffect(() => {
    if (flatList.current && !visitor.viewer_session.is_replay && questions.length) {
      setTimeout(() => {
        flatList.current.scrollToItem({ item: questions[questions.length - 1], animated: false })
      }, 100)
    }
  }, [questions.length > 0])

  useEffect(() => {
    if (firstQuestion) {
      if (prevFirstItem.current && prevFirstItem.current !== firstQuestion) {
        if (flatList.current) {
          flatList.current && flatList.current.scrollToItem({ item: prevFirstItem.current, animated: false })
        }
      }

      prevFirstItem.current = firstQuestion
    }
  }, [firstQuestion && firstQuestion.id])

  const onScrollToIndexFailed = debounce(error => {
    const offset = error.averageItemLength * error.index
    if (flatList.current) {
      flatList.current.scrollToOffset({ offset, animated: true })
    }
    setTimeout(() => {
      if (flatList.current) {
        flatList.current.scrollToIndex({ index: error.index, animated: true })
      }
    }, 50)
  }, 50, { leading: true })

  useEffect(() => () => {
      onScrollToIndexFailed.cancel()
    }, [])

  const onScroll = e => {
    const scrollOffset = e.nativeEvent.contentOffset.y

    // if (!prevScrollOffset.current)

    let scrollDirection = scrollOffset > prevScrollOffset.current ? 'forward' : 'backward'
    if (!prevScrollOffset.current) {
      // first scroll
      scrollDirection = 'forward'
    }

    if (prevScrollOffset.current && prevScrollOffset.current === scrollOffset) {
      // end of scrolling
      return
    }

    // console.log(scrollDirection, scrollOffset, prevScrollOffset.current)
    prevScrollOffset.current = scrollOffset

    // const atBottom = listOuterRef.current.scrollHeight - scrollOffset - listOuterRef.current.clientHeight < 70
    const paddingToBottom = 20
    const atBottom = e.nativeEvent.layoutMeasurement.height + e.nativeEvent.contentOffset.y >= e.nativeEvent.contentSize.height - paddingToBottom

    if (visitor.viewer_session.is_replay) {
      setIsBottom(atBottom)
      if (atBottom && questions.length) {
        props.loadMoreItems()
      }
    } else {
      setIsBottom(atBottom)

      if (Platform.OS === 'web') {
        if (scrollDirection === 'backward' && scrollOffset < 5) {
          props.loadMoreItems()
        }
      }
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={props.style}>
        <Title>{ i18n.t('tabs.qa') }</Title>
        <FilterWrapper>
          <Filter isActive={mode === 'answered'} onPress={ () => setMode('answered') }>{ getLabel('answered', i18n.t('qa.answered')) }</Filter>
          <Filter isActive={mode === 'non-answered'} onPress={ () => setMode('non-answered') }>{ getLabel('non-answered', i18n.t('qa.not_answered')) }</Filter>
        </FilterWrapper>
        <FlatList
          ref={flatList}
          data={questions}
          removeClippedSubviews={Platform.OS !== 'web'}
          renderItem={({ item }) => <RenderItem item={item} />}
          keyExtractor={(item, i) => `${item.id}-${item.appear_in}-${i}`}
          onScrollToIndexFailed={onScrollToIndexFailed}
          onScroll={onScroll}
          onRefresh={props.loadMoreItems}
          refreshing={false}
        />
      </View>
    </SafeAreaView>
  )
}
QA.propTypes = {
  loadMoreItems: PropTypes.func,
}

const Title = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 28px;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
  margin-bottom: 20px;
`

const FilterWrapper = styled.View`
  flex: 0 0 auto;
  flex-direction: row;
  border-radius: 6px;
  background-color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.06)' : '#f4f6f7'};
  padding: 4px 5px;
`

const Filter = styled.TouchableOpacity`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 29px;
  border-radius: 4px;
  background-color: ${props => props.isActive ? (props.theme.darkScheme ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.08)') : 'transparent'};
`

const AnsweredText = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 18px;
  text-align: center;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
`

const NonAnsweredText = styled(AnsweredText)`
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.4)' : '#757f99'};
`

const QuestionContainer = styled.View`
  flex: 0 0 auto;
  margin-top: 15px;
`

const Wrapper = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
  padding: 11px;
  border-radius: 8px;
  background-color: rgba(59, 191, 158, 0.1);
`

const Body = styled.View`
  flex: 1;
  justify-content: flex-start;
  margin-left: 10px;
`

const QuestionTitle = styled.View`
  flex-direction: row;
  align-items: center;
`

const Author = styled.Text`
  font-size: 18px;
  font-family: Gilroy-SemiBold;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
`

const SentAt = styled.Text`
  margin-left: 9px;
  font-size: 14px;
  font-family: Gilroy-Regular;
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.3)' : '#9ea4b4'};
`

export default styled(React.memo(QA))`
  flex: 1;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
`
