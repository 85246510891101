import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { EventEmitter } from '../../../../services/events'
import visitor from '../../../../services/visitor'
import { durationToSeconds } from '../../../../helpers/time'

const OfferCountdown = props => {
  const [time, setTime] = useState(visitor.time)
  const [finished, setFinished] = useState(false)

  if (!props.value || props.value === '00:00:00') {
    return null
  }

  const seconds = durationToSeconds(props.value) - time + props.offsetSecs

  useEffect(() => {
    EventEmitter.subscribe(`offerCountdown_${props.id}.time`, time => {
      setTime(time)
    })

    return () => {
      EventEmitter.unsubscribe(`offerCountdown_${props.id}.time`)
    }
  }, [])

  if (seconds <= 0) {
    if (!finished) {
      props.onFinish()
      setFinished(true)
    }
    return null
  }

  let hours = Math.floor(seconds / 3600)
  let minutes = Math.floor((seconds - (hours * 3600)) / 60)
  let secs = Math.floor(seconds - (hours * 3600) - (minutes * 60))

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  if (secs < 10) {
    secs = `0${secs}`
  }

  if (!props.visible) return null

  return (
    <View style={props.style}>
      <Box>
        <Value>{ hours }</Value>
        <Title>hrs</Title>
      </Box>
      <Delimiter>:</Delimiter>
      <Box>
        <Value>{ minutes }</Value>
        <Title>mins</Title>
      </Box>
      <Delimiter>:</Delimiter>
      <Box>
        <Value>{ secs }</Value>
        <Title>secs</Title>
      </Box>
    </View>
  )
}
OfferCountdown.propTypes = {
  style: PropTypes.array,
  value: PropTypes.string,
  visible: PropTypes.bool
}

const Box = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
`

const Value = styled.Text`
  font-size: 38px;
  font-family: Gilroy-Medium;
  color: #ff7e5c;
  font-weight: 500;
`

const Title = styled.Text`
  font-size: 16px;
  font-family: Gilroy-Medium;
  color: #02091b;
`

const Delimiter = styled.Text`
  line-height: 30px;
`

export default styled(React.memo(OfferCountdown))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`
