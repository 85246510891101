import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import moment from 'moment-timezone'
import { EventEmitter } from '../../services/events'
import i18n from '../../services/i18n'
import getScreenInfo from '../../services/screen'

const ScreenSaver = props => {
  const [seconds, setSeconds] = useState(props.initialTime)

  useEffect(() => {
    EventEmitter.subscribe('screensaver.time', time => {
      setSeconds(time * -1)
    })

    return () => {
      EventEmitter.unsubscribe('screensaver.time')
    }
  }, [])

  const duration = moment.duration(seconds, 'seconds')
  const isDesktop = ['desktop'].includes(getScreenInfo.deviceType)

  return (
    <View style={props.style}>
      <WebinarTitle small={!isDesktop}>{ props.webinarName }</WebinarTitle>
      <Title>{ i18n.t('connect.starts_in') }</Title>
      <Body>
        <Timer>
          <Column>
            <ColumnTitle>{ i18n.t('connect.hours') }</ColumnTitle>
            <ColumnValue>{ Math.floor(duration.asHours()) }</ColumnValue>
          </Column>
          <Delimiter>:</Delimiter>
          <Column>
            <ColumnTitle>{ i18n.t('connect.minutes') }</ColumnTitle>
            <ColumnValue>{ duration.minutes() }</ColumnValue>
          </Column>
          <Delimiter>:</Delimiter>
          <Column>
            <ColumnTitle>{ i18n.t('connect.seconds') }</ColumnTitle>
            <ColumnValue>{ duration.seconds() }</ColumnValue>
          </Column>
        </Timer>
      </Body>
      <Notice>{ i18n.t('connect.notice') }</Notice>
    </View>
  )
}
ScreenSaver.propTypes = {
  className: PropTypes.string
}

const Title = styled.Text`
  margin-top: ${hp(5)}px;
  font-size: 28px;
  font-family: Gilroy-SemiBold;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#1c1d38'};
`

const WebinarTitle = styled(Title)`
  margin-top: 0;
  font-size: ${props => props.small ? 38 : 48}px;
  text-align: center;
`

const Body = styled.View`
  align-items: center;
  width: ${wp(90)}px;
  margin-top: ${hp(5)}px;
  border-radius: 8px;
  background-color: #1f2d42;
  padding-top: ${hp(5)}px;
  padding-bottom: ${hp(5)}px;
`

const Timer = styled.View`
  flex-direction: row;
  align-items: flex-end;
`

const Column = styled.View`
  width: ${wp(18)}px;
  align-items: center;
  margin: 0 ${wp(2)}px;
`

const ColumnTitle = styled.Text`
  opacity: 0.5;
  font-family: Gilroy-Regular;
  font-size: 16px;
  color: #3bbf9e;
  text-transform: uppercase;
`

const ColumnValue = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: ${wp(14.4)}px;
  line-height: ${wp(14.4)}px;
  color: #3bbf9e;
  margin-top: 3px;
`

const Delimiter = styled.Text`
  opacity: 0.2;
  font-family: Gilroy-Regular;
  font-size: ${wp(11.7)}px;
  color: #3bbf9e;
`

const Notice = styled.Text`
  margin-top: ${hp(5)}px;
  opacity: 0.6;
  font-family: Gilroy-Regular;
  font-size: 20px;
  line-height: 22px;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#1c1d38'};
  width: ${wp(70)}px;
  text-align: center;
`

export default styled(ScreenSaver)`
  background: ${props => props.theme.darkScheme ? '#1c1d38' : '#ffffff' };
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: ${hp(5)}px;
`
