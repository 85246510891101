const ja = {
  app: {
    title: 'Attend A Webinar',
    description: 'Enter your webinar join code below',
    type_code: 'Type code',
    submit: 'Join Webinar',
    token_not_found: 'Webinar is not available. This webinar or its recording was removed. Please contact the host for more information.',
    token_not_exist: 'Please enter your webinar join code.',
    admin: 'アドミン'
  },
  tabs: {
    messages: 'チャット',
    qa: '質疑応答',
    hosts: 'ホスト',
    attendees: '参加者',
    files: 'ファイル',
    dm: 'DM',
    quit: 'やめる',
    quit_confirmation_title: 'ウエビナーをやめる',
    quit_confirmation_message: '本当に退出されますか？'
  },
  finish: {
    title: 'オーガナイザーがウエビナーを閉じました',
    notice: 'このアプリは30秒で自動的に閉じます',
    close: '閉じる'
  },
  connect: {
    starts_in: '後にウエビナーを開始します',
    hours: '時間',
    minutes: '分' ,
    seconds: '秒',
    notice: 'オーガナイザーの到着後、ミーティングが開始されます'
  },
  player: {
    click_for_sound: 'クリックして音声を出す'
  },
  update: {
    title: 'アップデート中'
  },
  chat: {
    shared_file: 'ファイルをシェアしました',
    poll: '調査',
    asked_question: '質問をシェアしました',
    message_placeholder: 'メッセージを入力'
  },
  qa: {
    question_placeholder: '質問を入力',
    answered: '答えられました',
    not_answered: '答えられていません'
  }
}

export default ja
