import axios from 'axios'
import secrets from '../config/secrets'

const fetchHostsBegin = () => ({ type: 'FETCH_HOSTS_BEGIN' })
const fetchHostsSuccess = ({ hosts }) => ({ type: 'FETCH_HOSTS_SUCCESS', hosts })
const fetchHostsError = error => ({ type: 'FETCH_HOSTS_ERROR', error })

export function fetchHosts(cid) {
  return dispatch => {
    dispatch(fetchHostsBegin())

    return axios({
      url: `${secrets.domain}/meeting/hosts?cid=${cid}`
    })
    .then(response => {
      dispatch(fetchHostsSuccess(response.data))
    })
    .catch(error => {
      dispatch(fetchHostsError(error))
    })
  }
}
