const EventEmitter = {
  events: {},
  dispatch(event, data) {
    if (!this.events[event]) return

    this.events[event].forEach(ev => ev.fn(data))
  },
  subscribe(event, callback) {
    const [ns, eventName] = this.parseEvent(event)

    if (!this.events[eventName]) this.events[eventName] = []

    this.events[eventName].push({
      ns,
      fn: callback
    })
  },
  unsubscribe(event) {
    const [ns, eventName] = this.parseEvent(event)

    if (!this.events[eventName]) return

    if (ns) {
      this.events[eventName] = this.events[eventName].filter(ev => {
        return ev.ns !== ns
      })
    } else {
      delete this.events[eventName]
    }
  },
  reset() {
    this.events = {}
  },
  parseEvent(event) {
    const [ns, ev] = event.split('.')

    if (!ev) {
      return [null, ns]
    }

    return [ns, ev]
  }
}

window.EventEmitter = EventEmitter

export {
  EventEmitter
}
