import React from 'react'
import { View, KeyboardAvoidingView, ImageBackground } from 'react-native'
import styled from 'styled-components/native'
import Constants from 'expo-constants'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import Svg, { G, Polygon, Path } from 'react-native-svg'
import secrets from '../../config/secrets'
import i18n from '../../services/i18n'
import DebugInfo from './DebugInfo'

const ConnectDesktop = props => {
  return (
    <View style={props.style}>
      <StyledImageBackground
        source={require('../../../assets/connect.png')}
        imageStyle={{ resizeMode: 'contain' }}
      >
      </StyledImageBackground>
      <View>
        <Title>{i18n.t('app.title')}</Title>
        {
          props.errorMessage
          ? <ErrorMessage>{props.errorMessage}</ErrorMessage>
          : <Desc>{i18n.t('app.description')}</Desc>
        }
      </View>
      <Actions>
        <CodeInput
          placeholder={i18n.t('app.type_code')}
          placeholderTextColor="rgba(28, 29, 56, 0.4)"
          value={props.token}
          onChangeText={props.onChangeText}
        />
        <SubmitButton onPress={props.onSubmit}>
          <SubmitText>{i18n.t('app.submit')}</SubmitText>
        </SubmitButton>
      </Actions>
      { props.debugging && <DebugInfo /> }
    </View>
  )
}

const StyledImageBackground = styled.ImageBackground`
  width: 296px;
  height: 239px;
`

const Header = styled.View`
  flex: 1 1 auto
`

const Wrapper = styled.KeyboardAvoidingView`
  flex: 1 1 auto
`

const Info = styled.View`
  flex: 1 1 auto;
`

const Title = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 21px;
  text-align: center;
  color: #ffffff;
  margin-top: 9px;
`

const Desc = styled.Text`
  opacity: 0.6;
  font-family: Gilroy-Regular;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 13px;
`

const ErrorMessage = styled(Desc)`
  color: #ff0000;
`

const Actions = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-top: 17px;
`

const CodeInput = styled.TextInput`
  border-radius: 11px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #ffffff;
  width: 271px;
  height: 44px;
  padding: 14px;
  color: #757f99;
  font-size: 16px;
  font-family: Gilroy-Regular;
`

const SubmitButton = styled.TouchableOpacity`
  border-radius: 11px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 13px 18px;
  background: #ff7e5c;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubmitText = styled.Text`
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
`

export default styled(ConnectDesktop)`
  flex: 1;
  background-color: #1C1D38;
  justify-content: center;
  align-items: center;
`
