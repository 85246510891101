import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { WebView } from 'react-native-webview'
import { Platform } from 'react-native'
import { EventEmitter } from '../services/events'
import secrets from '../config/secrets'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const TrackingCodeWindow = props => {
  const [trackings, setTrackings] = useState([])

  useEffect(() => {
    EventEmitter.subscribe('tracking', type => {
      setTrackings(prev => [...prev, type])
    })

    return () => {
      EventEmitter.unsubscribe('tracking')
    }
  }, [])

  const iframeContent = frame => {
    if (!frame) return

    frame.style.width = 0
    frame.style.height = 0
    frame.style.border = 0
    frame.style.visibility = 'hidden'
  }

  const renderCode = (type, index) => {
    const url = new URL(`${secrets.domain}/meeting/tracking/${props.webinarId}`)
    url.searchParams.append('type', type)
    let additionalParams = []
    Object.keys(props.additionalParams).map(key => {
      if (key !== 'type') {
        url.searchParams.append(key, props.additionalParams[key])
      }
    })
    const key = `${type}-${index}`

    return (
      mobile
      ? <WebView key={key} originWhitelist={['*']} source={{ uri: url.toString() }} style={{ visibility: 'hidden' }} />
      : <iframe key={key} frameBorder="0" src={url} ref={iframeContent}></iframe>
    )
  }

  return (
    <View style={{ width: 0, height: 0, visibility: 'hidden' }}>
      { renderCode(props.isReplay ? 'room_replay' : 'room') }
      { trackings.map(renderCode) }
    </View>
  )
}
TrackingCodeWindow.propTypes = {
  webinarId: PropTypes.number,
  isReplay: PropTypes.bool,
}

export default React.memo(TrackingCodeWindow)
