import React, { useState, useCallback, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Platform, StatusBar, Keyboard, KeyboardAvoidingView } from 'react-native'
import styled, { ThemeProvider } from 'styled-components/native'
import { useSelector } from 'react-redux'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import Portal from '@burstware/react-native-portal'
import Svg, { G, Path } from 'react-native-svg'
import { WebinarContext } from '../Context'
import { getDirectMessagesLength } from '../../../selectors'
import Chat from '../../Chat'
import Hosts from '../Hosts'
import QA from '../QA'
import Attendees from '../Attendees'
import Files from '../Files'
import DM from '../DM'
import LiveOffers from './Offers/LiveOffers'
import ReplayOffers from './Offers/ReplayOffers'
import Tab from './Tab'
import VideoPlayer from './VideoPlayer'
import Info from '../Info'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const HorizontalLayout = props => {
  const { cid, webinar, is_replay, tabsNode, tabs, currentTab, onTabSelect, video, videoWidth, videoHeight, darkScheme,
    topPadding, fullScreenVideo, statusBarPadding, initialDeviceOrientation } = useContext(WebinarContext)
  const [hasOffers, setHasOffers] = useState(false)
  const [playbackStarted, setPlaybackStarted] = useState(!is_replay)
  const directMessagesLength = useSelector(getDirectMessagesLength)
  const [keyboardShown, setKeyboardShown] = useState(false)

  useEffect(() => {
    EventEmitter.subscribe('Layout.activeOffers', activeOffersCount => {
      setHasOffers(!!activeOffersCount)
    })
    Keyboard.addListener('keyboardDidShow', () => setKeyboardShown(true))
    Keyboard.addListener('keyboardDidHide', () => setKeyboardShown(false))

    return () => {
      EventEmitter.unsubscribe('Layout.activeOffers')
      Keyboard.removeListener('keyboardDidShow', () => setKeyboardShown(true))
      Keyboard.removeListener('keyboardDidHide', () => setKeyboardShown(false))
    }
  }, [])

  useEffect(() => {
    if (directMessagesLength) {
      onTabSelect('dm')
    }
  }, [directMessagesLength])

  const renderContent = () => {
    if (!currentTab) return null

    switch (currentTab.id) {
      case 'chat': return (
        <Chat
          cid={cid}
          loadMoreItems={props.loadMoreMessages}
          tabs={tabs}
        />
      )
      case 'qa': return (
        <QA loadMoreItems={props.loadMoreQuestions} />
      )
      case 'hosts': return <Hosts />
      case 'attendees': return <Attendees cid={cid} />
      case 'files': return <Files />
      case 'dm': return <DM />
      default: return null
    }
  }

  const Offers = is_replay ? ReplayOffers : LiveOffers
  const wrapperStyles = {
    top: topPadding,
    display: fullScreenVideo ? 'none' : 'flex'
  }

  const displayStyles = {
    display: fullScreenVideo || !playbackStarted ? 'none' : 'flex'
  }

  return (
    <KeyboardAvoidingView
      behavior={ Platform.OS === 'ios' ? 'position' : null }
      contentContainerStyle={{ flex: 1 }}
      keyboardVerticalOffset={0}
      style={[props.style, { backgroundColor: darkScheme ? '#1c1d38' : '#ffffff' }]}>
      <StatusBar hidden={Platform.OS === 'android'} barStyle={darkScheme ? 'light-content' : 'dark-content'} />
      {
        mobile
        ? <VideoPlayer
            width={ videoWidth }
            height={ videoHeight }
            link={ video.link }
            duration={ video.duration }
            currentTab={ currentTab && currentTab.id }
            opacity={1}
            fullScreenIcon={ webinar.fullscreen_icon !== 'disabled' }
            fullScreenVideo={ fullScreenVideo }
            setPlaybackStarted={setPlaybackStarted}
          />
        : <VideoPlayer
            width={ videoWidth }
            height={ videoHeight }
            link={ video.link }
            duration={ video.duration }
            currentTab={ currentTab && currentTab.id }
            opacity={1}
            fullScreenVideo={ fullScreenVideo }
            initialDeviceOrientation={initialDeviceOrientation}
            setPlaybackStarted={setPlaybackStarted}
          />
      }
      { !is_replay && <Info /> }
      {is_replay && !playbackStarted
        && <ReplayStartWrapper>
          <WebText>{ visitor.viewer_session.video.replay_start_text }</WebText>
          <ArrowWrapper><CurverArrow /></ArrowWrapper>
        </ReplayStartWrapper>
      }
      <ThemeProvider theme={{ isVertical: false, darkScheme }} style={ displayStyles }>
        <Wrapper style={wrapperStyles}>
          <ContentWrapper style={ displayStyles }>
            { renderContent() }
          </ContentWrapper>
          { !keyboardShown
            && <>
              <Tabs style={ displayStyles } hasOffers={hasOffers}>
                <TabsWrapper ref={tabsNode}
                  data={tabs}
                  renderItem={({ item }) => <Tab hidden={!item.visible} activeTab={currentTab.id} id={item.id} name={item.name} onTabSelect={onTabSelect} />
                  }
                  keyExtractor={item => item.id}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  ListHeaderComponent={ListHeader}
                  ListFooterComponent={ListFooter}
                  paddingBottom={hasOffers ? 20 : 40}
                />
              </Tabs>
              <Portal>
                <Offers cid={cid} fullScreenVideo={fullScreenVideo} />
              </Portal>
            </>
          }
        </Wrapper>
      </ThemeProvider>
    </KeyboardAvoidingView>
  )
}

HorizontalLayout.propTypes = {
  loadMoreMessages: PropTypes.func,
  loadMoreQuestions: PropTypes.func,
}

const Wrapper = styled.View`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
`

const ContentWrapper = styled.View`
  flex: 1;
`

const WebText = styled.Text`
  font-size: 24px;
  margin: 20px 10px;
  text-align: center;
`

const ArrowWrapper = styled.View`
  margin-top: 6px;
`

const ReplayStartWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const CurverArrow = () => (
  <Svg width="33px" height="30px" viewBox="0 0 33 30">
    <G stroke="none" strokeWidth="4" fill="none" fillRule="evenodd">
      <Path d="M27.9118316,9.04694808 C26.0245455,14.5252986 23.3517901,19.6547246 20.8060528,22.200462 C15.7854595,27.2210553 9.16932757,29.6539455 0.99058493,29.4999113 L0.490673582,29.4904963 L0.509503721,28.4906736 L1.00941507,28.5000887 C8.92673813,28.6491994 15.2789391,26.313362 20.098946,21.4933552 C22.4629052,19.129396 25.0131155,14.2803095 26.8479038,9.06155048 L23.0645545,9.11347694 L27.4406174,0.0525678392 L32.0637069,8.9899636 L27.9118316,9.04694808 Z" fill="#000000" fillRule="nonzero"></Path>
    </G>
  </Svg>
)

const Tabs = styled.View`
  ${props => props.hasOffers && 'margin-bottom: 71px;'}
`

const TabsWrapper = styled.FlatList`
  padding-top: 4px;
  padding-bottom: ${props => props.paddingBottom}px;
`

const ListHeader = styled.View`
  margin-left: ${wp(50) - 52}px;
`

const ListFooter = styled.View`
  margin-right: ${wp(50) - 32}px;
`

export default styled(HorizontalLayout)`
  flex: 1;
`
