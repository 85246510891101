const initialState = {
  data: [],
  loading: false,
  error: null
}

const isMessageExist = (list, id) => list.some(el => el.id === id)

const questions = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_QUESTION': {
      return state.data.find(q => q.id === action.question.id)
      ? {
          ...state,
          data: state.data.map(q => (
            q.id === action.question.id
            ? action.question
            : q
          ))
        }
      : {
          ...state,
          data: [...state.data, action.question]
        }
    }

    case 'ADD_QUESTIONS':
      return {
        ...state,
        data: [...state.data, ...action.questions]
      }

    case 'REMOVE_QUESTION':
      return {
        ...state,
        data: state.data.filter(m => m.id !== action.id)
      }

    case 'CLEAR_QUESTIONS':
      if (state.data.length) {
        return {
          ...state,
          data: []
        }
      }
      return state

    default:
      return state
  }
}

export default questions
