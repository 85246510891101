const initialState = {
  data: [],
  loading: false,
  error: null
}

const files = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_FILES_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'FETCH_FILES_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.files
      }

    case 'ADD_FILE':
      return {
        ...state,
        data: [...state.data, action.file]
      }

    case 'ADD_FILES':
      return {
        ...state,
        data: [...state.data, ...action.files]
      }

    case 'FETCH_FILES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case 'CLEAR_FILES':
      if (state.data.length) {
        return {
          ...state,
          data: []
        }
      }
      return state

    default:
      return state
  }
}

export default files
