import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { View, Image, TextInput, CheckBox } from 'react-native'
import { Button } from 'react-native-elements'
import BouncyCheckbox from "react-native-bouncy-checkbox"
import styled from 'styled-components/native'
import Avatar from './Avatar'
import { WebinarContext } from '../../Webinar/Context'
import visitor from '../../../services/visitor'
import i18n from '../../../services/i18n'

const Poll = props => {
  const { darkScheme } = useContext(WebinarContext)
  const { question, answers, answered_by: author } = props.data

  const [pollAnswers, setPollAnswers] = useState(visitor.polls[props.ctaId] || [])
  const [pollSubmited, setPollSubmited] = useState(!!visitor.polls[props.ctaId])

  const submitPoll = () => {
    visitor.answerPoll(props.id, pollAnswers)
    visitor.trackAnswerPoll(props.id, props.ctaId, pollAnswers)
    setPollSubmited(true)
  }

  const onPollChange = val => {
    if (pollSubmited) return

    if (pollAnswers.includes(val)) {
      setPollAnswers(pollAnswers.filter(a => a !== val))
    } else {
      setPollAnswers([...pollAnswers, val])
    }
  }

  const changePoll = () => {
    setPollSubmited(false)
  }

  const filteredPollAnswers = answers.filter(a => {
    if (pollSubmited && !pollAnswers.includes(a.value)) {
      return false
    } else {
      return true
    }
  })
  return (
    <View style={props.style}>
      <Title>{ i18n.t('chat.poll') }</Title>
      <QuestionText>{ question }</QuestionText>
      <Answers>
        {
          filteredPollAnswers.map((answer, index) => (
            <PollAnswer key={index}>
              <BouncyCheckbox
                isChecked={pollAnswers.includes(answer.value)}
                fillColor="#ff7e5c"
                borderColor={pollAnswers.includes(answer.value) ? '#ff7e5c' : '#dddfe2'}
                onPress={() => onPollChange(answer.value)}
                text={answer.value}
                color={darkScheme ? '#ffffff' : '#131d40'}
                fontSize={18}
                textDecoration
                size={20}
                textStyle={{ marginLeft: -8 }}
              />
            </PollAnswer>
          ))
        }
        {
          pollSubmited
          ? <Button buttonStyle={{ backgroundColor: darkScheme ? 'rgba(255, 255, 255, 0.25)' : 'rgba(255, 128, 92, 0.25)', borderRadius: 14, height: 40 }} titleStyle={{ color: darkScheme ? '#ffffff' : '#ff805c', fontSize: 17, fontWeight: 'bold' }} containerStyle={{ marginTop: 15 }} title="Edit" onPress={changePoll} />
          : pollAnswers.length
            ? <Button buttonStyle={{ backgroundColor: '#FF7E5C', borderRadius: 14, height: 40 }} titleStyle={{ color: '#ffffff', fontSize: 17, fontWeight: 'bold' }} containerStyle={{ marginTop: 15 }} title="Submit" onPress={submitPoll} />
            : null
        }
      </Answers>
    </View>
  )
}
Poll.propTypes = {
  style: PropTypes.array
}

const Title = styled.Text`
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.7)' : '#555c73'};
  text-transform: uppercase;
`

const QuestionText = styled.Text`
  color: #ef7a61;
  font-size: 22px;
  line-height: 21px;
  font-family: Gilroy-Regular;
`

const Answers = styled.View`
`

const PollAnswer = styled.View`
  margin-top: 9px;
  flex-direction: row;
  align-items: center;
`

export default styled(Poll)`
  border-radius: 6px;
  justify-content: center;
`
