class Poller {
  constructor() {
    this.collection = {};
    this.timeoutId = 100;
    this._latency = 100;
  }

  add(name, interval, fn) {
    let date = (new Date).getTime();

    this.collection[name] = {
      addedAt: date,
      lastRanAt: date,
      interval: interval,
      fn: fn
    };
  }

  stop() {
    clearTimeout(this.timeoutId);
  }

  clear() {
    this.collection = {};
  }

  runFunctions() {
    let functions = this.collection;
    for (let d in functions) {
      if (!functions.hasOwnProperty(d)) continue;
      let func = functions[d],
          date = (new Date).getTime(),
          g = date - (func.lastRanAt);
      if (!func.paused && g >= func.interval) {
        func.lastRanAt = date;
        try {
          func.fn();
        } catch (m) {
          // console.log(`error ${m}`);
          // console.log(`error func ${func.name}`);
          continue;
        }
      }
    }
  }

  start() {
    clearTimeout(this.timeoutId);

    if (!this._loopFn)
      this._loopFn = () => {
        this.runFunctions();
        this.timeoutId = setTimeout(this._loopFn, 100);
      }

    this._loopFn()
  }

  resume(name) {
    let func = this.collection[name];
    func.paused = false;
  }

  pause(name) {
    let func = this.collection[name];
    func.paused = true;
  }

  delete(name) {
    if (name instanceof RegExp) {
      Object.keys(this.collection).forEach(k => {
        if (k.match(name)) {
          delete this.collection[k]
        }
      })
    } else {
      delete this.collection[name]
    }
  }
}

export default Poller;
