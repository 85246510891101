import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Platform, View, TouchableOpacity, Image, TextInput } from 'react-native'
import styled from 'styled-components/native'
import Svg, { G, Path } from 'react-native-svg'
import { BlurView } from 'expo-blur'
import { FileSystem } from 'react-native-unimodules'
import * as Sharing from 'expo-sharing'
import Avatar from './Avatar'
import { WebinarContext } from '../../Webinar/Context'
import i18n from '../../../services/i18n'
import { humanFileSize } from '../../../helpers/bytes'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const File = props => {
  const [downloadProgress, setDownloadProgress] = useState()
  const { name, file_type, file_size, download_url, answer } = props.data

  const onDownload = () => {
    if (mobile) {
      const callback = downloadProgress => {
        const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite
        setDownloadProgress(progress)
      }

      const downloadResumable = FileSystem.createDownloadResumable(
        download_url,
        `${FileSystem.documentDirectory}${name}`,
        {},
        callback
      )

      downloadResumable.downloadAsync().then(({ uri }) => {
        Sharing.isAvailableAsync().then(res => {
          if (res) {
            Sharing.shareAsync(uri)
          }
        })
        console.log('Finished downloading to ', uri)
      })
    } else {
      const link = `${download_url}?response-content-disposition=attachment`
      // TODO: detect ios version and for iOS >= 11 make it _self
      window.open(link, '_blank')
    }
  }

  const getFilePreviewIcon = () => {
    return <PreviewIcon />
    // switch (true) {
    //   case file_type.match(/image\//):

    // }
  }

  if (props.isPreview) {
    return (
      <View style={props.style}>
        <Title>{ i18n.t('chat.shared_file') }</Title>
        <Body onPress={onDownload}>
          <IconWrapper><PreviewIcon /></IconWrapper>
          <FileName>{ name }</FileName>
        </Body>
      </View>
    )
  }

  return (
    <View style={props.style}>
      <Title>{ i18n.t('chat.shared_file') }</Title>
      <Wrapper>
        <Body onPress={onDownload}>
          <IconWrapper>{ getFilePreviewIcon() }</IconWrapper>
          <FileBody>
            <FileName>{ name }</FileName>
            <FileSize>{ humanFileSize(file_size) }</FileSize>
          </FileBody>
          <DownloadIconWrapper><DownloadIcon /></DownloadIconWrapper>
        </Body>
      </Wrapper>
    </View>
  )
}
File.propTypes = {
  style: PropTypes.array
}

const DownloadIcon = () => (
  <Svg width="20px" height="19px" viewBox="0 0 20 19">
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G transform="translate(-616.000000, -382.000000)" fill="#FF805C" fillRule="nonzero">
        <G transform="translate(162.000000, 148.000000)">
          <G transform="translate(104.000000, 172.000000)">
            <G transform="translate(350.000000, 62.000000)">
              <Path d="M16.1281007,5.01595938 C15.4552225,1.63986502 12.1729086,-0.551434825 8.7968142,0.121443346 C6.32751704,0.613600428 4.39673518,2.54316634 3.90305813,5.01215953 C1.50930202,5.28802895 -0.207776031,7.45212183 0.0679414008,9.84602992 C0.321771634,12.0491853 2.18780397,13.7113935 4.40555082,13.7098746 L7.52203914,13.7098746 C7.86630591,13.7098746 8.14536724,13.4308123 8.14536724,13.0865455 C8.14536724,12.7422787 7.86630591,12.4632174 7.52203914,12.4632174 L4.40555082,12.4632174 C2.68421693,12.4530338 1.29727019,11.0495197 1.30730179,9.32833778 C1.31748541,7.60700389 2.72099953,6.21990514 4.44233342,6.23008875 C4.76000121,6.23008875 5.02690296,5.99115393 5.06186163,5.67531008 C5.37831346,2.93971911 7.85262646,0.978538444 10.5883694,1.29499027 C12.8883451,1.56098008 14.7026994,3.3753344 14.9686892,5.67531008 C15.0209752,5.99677774 15.2994285,6.23221669 15.625,6.23008875 C17.3463339,6.23008875 18.7416403,7.62539518 18.7416403,9.34672911 C18.7416403,11.067911 17.3463339,12.4632174 15.625,12.4632174 L12.5085117,12.4632174 C12.1642449,12.4632174 11.8851836,12.7422787 11.8851836,13.0865455 C11.8851836,13.4308123 12.1642449,13.7098746 12.5085117,13.7098746 L15.625152,13.7098746 C18.0347155,13.6948261 19.9759849,11.7292376 19.9611746,9.31967412 C19.94741,7.1253344 18.306177,5.28255716 16.1281007,5.01595938 L16.1281007,5.01595938 Z"></Path>
              <Path d="M12.2186588,14.9876885 L10.6385275,16.5682758 L10.6385275,8.72340101 C10.6385275,8.37913424 10.3594662,8.10007296 10.0153514,8.10007296 C9.67108463,8.10007296 9.39202335,8.37913424 9.39202335,8.72340101 L9.39202335,16.5682758 L7.81189202,14.9876885 C7.57280521,14.739938 7.17807634,14.7330982 6.93047786,14.972337 C6.68287938,15.2114239 6.67603965,15.6060007 6.91512646,15.8535992 C6.92029424,15.858919 6.92531008,15.8639348 6.93047786,15.8689506 L9.57396035,18.5130411 C9.63171813,18.5706469 9.70011553,18.6163971 9.77535261,18.6477079 C9.92871475,18.7126094 10.1018361,18.7126094 10.2553502,18.6477079 C10.3305873,18.6163971 10.3989847,18.5706469 10.4565905,18.5130411 L13.100073,15.8689506 C13.3476714,15.6298638 13.3545112,15.235287 13.1154244,14.9876885 C12.8761856,14.74009 12.4816087,14.7332502 12.2340102,14.972337 C12.2288424,14.9773529 12.2236746,14.9823687 12.2186588,14.9876885 Z"></Path>
            </G>
          </G>
        </G>
      </G>
    </G>
  </Svg>
)

const PreviewIcon = () => (
  <Svg width="29px" height="24px" viewBox="0 0 34 29">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <G transform="translate(-340.000000, -372.000000)" fill="#FF805C" fillRule="nonzero">
              <G transform="translate(162.000000, 148.000000)">
                  <G transform="translate(101.000000, 14.000000)">
                      <G transform="translate(2.000000, 154.000000)">
                          <G transform="translate(61.000000, 45.000000)">
                              <G transform="translate(14.000000, 11.000000)">
                                  <G transform="translate(0.000000, 0.000000)">
                                      <G transform="translate(0.000000, -0.000000)">
                                          <Path d="M28.069824,4.39039715 L20.14717,4.39039715 C18.2221041,4.39039715 16.6514727,2.84327208 16.6514727,0.9317131 C16.6514727,0.416004742 16.2344909,0.00343805572 15.7132637,0.00343805572 L5.37211536,0.00343805572 C2.41154467,0.00343805572 0,2.38944873 0,5.3186722 L0,23.0934203 C0,26.0226437 2.41154467,28.4086544 5.37211536,28.4086544 L28.069824,28.4086544 C31.0303947,28.4086544 33.4419394,26.0226437 33.4419394,23.0934203 L33.4419394,9.69875519 C33.4419394,6.76953171 31.0303947,4.39039715 28.069824,4.39039715 Z M31.5655213,23.0865442 C31.5655213,24.991227 30.0018396,26.5452282 28.069824,26.5452282 L5.37211536,26.5452282 C3.44704945,26.5452282 1.87641804,24.9981031 1.87641804,23.0865442 L1.87641804,5.3186722 C1.87641804,3.41398933 3.44009975,1.85998814 5.37211536,1.85998814 L14.858451,1.85998814 C15.3032316,4.34914049 17.5062854,6.24694724 20.14717,6.24694724 L28.069824,6.24694724 C29.9948899,6.24694724 31.5655213,7.79407232 31.5655213,9.7056313 L31.5655213,23.0865442 L31.5655213,23.0865442 Z"></Path>
                                      </G>
                                  </G>
                              </G>
                          </G>
                      </G>
                  </G>
              </G>
          </G>
      </G>
  </Svg>
)

const DownloadIconWrapper = styled.View`
  flex: 0 0 20px;
`

const Wrapper = styled(BlurView).attrs({ intensity: 40 })`
  flex: 1 1 auto;
  border-radius: 8px;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.11)' : '#f8f9fa'};
`

const Title = styled.Text`
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.7)' : '#555c73'};
  text-transform: uppercase;
`

const Body = styled.TouchableOpacity`
  margin-top: 10px;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
`

const FileBody = styled.View`
  flex: 1;
`

const FileName = styled.Text`
  flex: 1 1 auto;
  margin-left: 7px;
  font-size: 15px;
  font-family: Gilroy-Regular;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
`

const FileSize = styled.Text`
  margin-top: 7px;
  flex: 1 1 auto;
  margin-left: 7px;
  font-size: 14px;
  font-family: Gilroy-Regular;
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.3)' : '#131d40'};
`

const IconWrapper = styled.View`
  flex: 0 0 29px;
`

export default styled(File)`

`
