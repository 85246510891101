const initialState = {
  data: null,
  status: 'initial',
  loading: false,
  error: null
}

const generateRandomNumber = (min, max) => (
  Math.floor(Math.random() * (max - min) + min)
)

const viewer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_VIEWER_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'FETCH_VIEWER_SUCCESS':
      const attendeesCount = action.viewer_session.attendees_count
      const attendees = Array.from(Array(attendeesCount)).map((_, index) => {
        const sessionStartTime = 0
        const sessionEndTime = action.viewer_session.video.duration
        const dynamicAttendeesCount = attendeesCount * 0.1

        const perc = generateRandomNumber(0, attendeesCount)

        let startTime = generateRandomNumber(0, sessionEndTime / 20)
        let endTime = sessionEndTime

        if (perc < dynamicAttendeesCount) {
          startTime = generateRandomNumber(0, sessionEndTime / 2)
          endTime = generateRandomNumber(sessionEndTime / 2, sessionEndTime)
        }

        return { index, startTime, endTime }
      })

      const { sec_remains, is_replay, video } = action.viewer_session

      let status = ''
      if (is_replay) {
        status = 'fetched'
      } else {
        if (sec_remains >= 0) {
          status = 'pending'
        } else {
          if (Math.abs(sec_remains) > video.duration) {
            status = 'finished'
          } else {
            status = 'fetched'
          }
        }
      }

      return {
        ...state,
        status,
        loading: false,
        data: {
          ...action.viewer_session,
          attendees
        }
      }

    case 'SYNC_VIEWER':
      console.log('SYNC_VIEWER', state, action)

      return {
        ...state,
        data: {
          ...state.data,
          ...action.viewer_session
        }
      }

    case 'FETCH_VIEWER_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case 'REMOVE_SESSION':
      return {
        ...state,
        loading: false,
        status: 'ready',
        data: null
      }

    case 'FINISH_SESSION':
      return {
        ...state,
        loading: false,
        status: 'finished',
        data: null
      }

    case 'START_SESSION':
      return {
        ...state,
        status: 'started'
      }

    case 'QUIT_SESSION':
      return {
        ...state,
        status: 'ready',
        data: null
      }

    case 'SET_VIEWER_STATUS':
      return {
        ...state,
        status: action.status
      }

    case 'ADD_VIEWER_CONVERSATION':
      return {
        ...state,
        data: {
          ...state.data,
          conversation_id: action.conversation_id
        }
      }

    default:
      return state
  }
}

export default viewer
