const initialState = {
  data: [],
  loading: false,
  error: null
}

const hosts = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_HOSTS_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'FETCH_HOSTS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.hosts
      }

    case 'FETCH_HOSTS_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default:
      return state
  }
}

export default hosts
