const initialState = {
  data: [],
  loading: false,
  error: null
}

const sticky_messages = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_STICKY_MESSAGES_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'FETCH_STICKY_MESSAGES_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.sticky_messages
      }

    case 'FETCH_STICKY_MESSAGES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case 'ADD_STICKY_MESSAGE':
      return {
        ...state,
        data: [...state.data, action.stickyMessage]
      }

    case 'CLEAR_STICKY_MESSAGES':
      if (state.data.length) {
        return {
          ...state,
          data: []
        }
      }
      return state

    default:
      return state
  }
}

export default sticky_messages
