const initialState = {
  loading: false,
  errors: false,
  data: [],
  unreadedCount: 0
}

const isMessageExist = (list, id) => list.some(el => el.id === id)

const direct_messages = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DIRECT_MESSAGES_BEGIN':
      return {
        ...state,
        loading: true,
        data: [],
      }

    case 'FETCH_DIRECT_MESSAGES_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.messages,
      }

    case 'FETCH_DIRECT_MESSAGES_ERROR':
      return {
        ...state,
        error: action.error,
        loading: false
      }

    case 'ADD_DIRECT_MESSAGE':
      if (isMessageExist(state.data, action.message.id)) {
        return state
      } else {
        const unreadedCount = action.unreaded ? state.unreadedCount += 1 : 0

        return {
          ...state,
          unreadedCount,
          data: [...state.data, action.message]
        }
      }

    case 'REMOVE_DIRECT_MESSAGE':
      return {
        ...state,
        data: state.data.filter(m => m.id !== action.id)
      }

    case 'CLEAR_UNREADED_COUNT':
      return {
        ...state,
        unreadedCount: 0
      }

    case 'CLEAR_DIRECT_MESSAGES':
      if (state.data.length || state.unreadedCount) {
        return {
          ...state,
          data: [],
          unreadedCount: 0
        }
      }
      return state

    default: return state
  }
}

export default direct_messages
