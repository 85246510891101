import React, { useState, useEffect, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { KeyboardAvoidingView, Platform, View, StatusBar } from 'react-native'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'
import Svg, { Path } from 'react-native-svg'
import { WebinarContext } from '../Context'
import { getDirectMessagesLength } from '../../../selectors'
import LiveOffers from '../Phone/Offers/LiveOffers'
import ReplayOffers from '../Phone/Offers/ReplayOffers'
import Chat from '../../Chat'
import Hosts from '../Hosts'
import QA from '../QA'
import Attendees from '../Attendees'
import Files from '../Files'
import DM from '../DM'
import Header from './Header'
import Tabs from './Tabs'
import VideoPlayer from './VideoPlayer'
import TabsHandler from './TabsHandler'
import Hoverable from './Hoverable'

const sidebarWidth = 100

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'
const direction = mobile ? 'column' : 'row'

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_SIDEBAR':
      return { tabsVisible: false, sidebarVisible: true }
    case 'CLOSE_SIDEBAR':
      return { tabsVisible: false, sidebarVisible: false }
    case 'OPEN_TABS':
      return { ...state, tabsVisible: true }
    case 'CLOSE_TABS':
      return { ...state, tabsVisible: false }

    default: return state
  }
}

const WebinarDesktop = props => {
  const [state, dispatch] = useReducer(reducer, {
    tabsVisible: direction === 'column',
    sidebarVisible: true
  })
  const { cid, webinar, is_replay, tabs, currentTab, onTabSelect, video, videoWidth, videoHeight, darkScheme,
    fullScreenVideo, statusBarPadding } = useContext(WebinarContext)
  const directMessagesLength = useSelector(getDirectMessagesLength)
  const [hasOffers, setHasOffers] = useState(false)

  useEffect(() => {
    if (directMessagesLength) {
      onTabSelect('dm')
    }
  }, [directMessagesLength])

  useEffect(() => {
    EventEmitter.subscribe('Layout.activeOffers', activeOffersCount => {
      setHasOffers(!!activeOffersCount)
    })

    return () => {
      EventEmitter.unsubscribe('Layout.activeOffers')
    }
  }, [])

  const onTabClick = tab => {
    dispatch({ type: 'OPEN_SIDEBAR' })
    onTabSelect(tab)
  }

  const onCloseSidebar = () => {
    dispatch({ type: 'CLOSE_SIDEBAR' })
  }

  const showTabs = () => {
    state.tabsVisible ? dispatch({ type: 'CLOSE_TABS' }) : dispatch({ type: 'OPEN_TABS' })
  }

  const closeTabs = () => {
    if (state.tabsVisible) {
      dispatch({ type: 'CLOSE_TABS' })
    }
  }

  const renderContent = () => {
    if (!currentTab) return null

    switch (currentTab.id) {
      case 'chat': return (
        <Chat
          cid={cid}
          loadMoreItems={props.loadMoreMessages}
          tabs={tabs}
        />
      )
      case 'qa': return (
        <QA
          loadMoreItems={props.loadMoreQuestions}
        />
      )
      case 'hosts': return <Hosts />
      case 'attendees': return <Attendees cid={cid} />
      case 'files': return <Files />
      case 'dm': return <DM />
      default: return null
    }
  }

  const isSidebarEmpty = () => {
    const { chat_tab, qa_tab, replay_chat_tab, replay_qa_tab } = webinar

    if (is_replay) {
      return replay_chat_tab === 'disabled' && replay_qa_tab === 'disabled'
    }

    return chat_tab === 'disabled' && qa_tab === 'disabled'
  }

  const Offers = is_replay ? ReplayOffers : LiveOffers

  const TabsWrapper = direction === 'column' ? ColumnTabWrapper : SideActionsWrapper

  return (
    <View
      style={[props.style, { backgroundColor: darkScheme ? '#1c1d38' : '#ffffff' }]}>
      <StatusBar barStyle={darkScheme ? 'light-content' : 'dark-content'} />
      <KeyboardAvoidingView behavior="position" style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
        <Layout statusBarPadding={(fullScreenVideo || !mobile) ? 0 : statusBarPadding} isColumn={direction === 'column' && !fullScreenVideo}>
          <Content isColumn={direction === 'column' && !fullScreenVideo}>
            {!fullScreenVideo
              && <Header
                sidebarVisible={state.sidebarVisible}
                logoUrl={webinar.logo_cf_url}
                isReplay={is_replay}
              />
            }
            <VideoWrapper
              backgroundColor={fullScreenVideo ? '#000000' : 'transparent'}
              isColumn={direction === 'column' && !fullScreenVideo}
              w={videoWidth}
              h={videoHeight}
            >
              {
                mobile
                ? <VideoPlayer
                    width={ videoWidth }
                    height={ videoHeight }
                    link={ video.link }
                    duration={ video.duration }
                    currentTab={ currentTab && currentTab.id }
                    fullScreenIcon={ webinar.fullscreen_icon !== 'disabled' }
                    fullScreenVideo={ fullScreenVideo }
                    statusBarPadding={ statusBarPadding }
                    sidebarWidth={ sidebarWidth }
                    dir={direction}
                  />
                : <VideoPlayer
                    width={ videoWidth }
                    height={ videoHeight }
                    link={ video.link }
                    duration={ video.duration }
                    currentTab={ currentTab && currentTab.id }
                    fullScreenVideo={ fullScreenVideo }
                    sidebarWidth={ sidebarWidth }
                    dir={direction}
                  />
              }
            </VideoWrapper>
            {state.tabsVisible && direction === 'row'
              && <Overlay onPress={closeTabs} activeOpacity={1}><View></View></Overlay>
            }
          </Content>
          {!fullScreenVideo
            && <Wrapper dir={direction} sidebarVisible={state.sidebarVisible}>
              {!isSidebarEmpty()
                && <Hoverable
                  onHoverIn={showTabs}
                  onHoverOut={closeTabs}
                >
                  <TabsWrapper sidebarVisible={state.sidebarVisible} tabsVisible={state.tabsVisible}>
                    <Tabs
                      onSelect={onTabClick}
                      activeTab={currentTab}
                      tabs={tabs}
                      active={state.sidebarVisible}
                      onClick={showTabs}
                      dir={direction}
                    />
                    {!state.tabsVisible && direction === 'row'
                      && <TabsHandler onPress={showTabs} />
                    }
                  </TabsWrapper>
                </Hoverable>
              }
              {state.sidebarVisible
                && <ContentWrapper dir={direction}>
                  {!isSidebarEmpty()
                    && <CloseSidebar onPress={onCloseSidebar}><CloseSidebarIcon /></CloseSidebar>
                  }
                  {!isSidebarEmpty() &&
                    <ContentContainer style={{ marginBottom: hasOffers ? 71 : 0 }}>
                      { renderContent() }
                    </ContentContainer>
                  }
                  <OffersWrapper pointerEvents="box-none">
                    <Offers cid={cid} isDesktop />
                  </OffersWrapper>
                </ContentWrapper>
              }
            </Wrapper>
          }

        </Layout>
      </KeyboardAvoidingView>
    </View>
  )
}

WebinarDesktop.propTypes = {
  loadMoreMessages: PropTypes.func,
  loadMoreQuestions: PropTypes.func,
}

const OffersWrapper = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const ContentContainer = styled.View`
  flex: 1 1 auto;
`

const Wrapper = styled.View`
  flex: ${props => props.dir === 'column' ? '1 1 auto' : '0 0 auto'};
  flex-direction: ${props => props.dir};
  align-items: center;

  ${props => !props.sidebarVisible
    && `
      position: absolute;
      top: 74px;
      right: 0;
      bottom: 74px;
    `
  }
`

const ContentWrapper = styled.View`
  ${props => props.dir === 'column'
    ? `
        width: 100%;
        flex: 1 1 auto;
      `
    : `
        flex: 0 0 408px;
        width: 408px;
        height: 100%;
        justify-content: flex-end;
      `
  }
  background-color: #ffffff;
`

const Layout = styled.View`
  background-color: #e0e4f3;
  flex: 1 1 auto;
  flex-direction: ${props => props.isColumn ? 'column' : 'row'};
  margin-top: ${props => props.statusBarPadding}px;
`

const Content = styled.View`
  flex: ${props => props.isColumn ? '0 0 auto' : '1 1 auto'};
`

const VideoWrapper = styled.View`
  ${
    props => props.isColumn
    ? `
        flex: 0 0 auto;
        height: ${props.h}px;
      `
    : `
        flex: 1 1 auto;
      `
  }
  background-color: ${props => props.backgroundColor};
`

const Overlay = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const SideActionsWrapper = styled.View`
  flex-direction: row;
  align-items: center;

  ${props => props.sidebarVisible && props.tabsVisible && `
    position: absolute;
    left: -100px;
  `}

  ${props => props.sidebarVisible && !props.tabsVisible && `
    position: absolute;
    left: -20px;
  `}

  ${props => !props.sidebarVisible && !props.tabsVisible && `
    position: absolute;
    left: -20px;
  `}
`

const ColumnTabWrapper = styled.View`
  flex: 0 0 90px;
  align-items: center;
`

const CloseSidebar = styled.TouchableOpacity`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 29px;
  left: -30px;
`

const CloseSidebarIcon = () => (
  <Svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <Path opacity="0.528623" d="M9.15207 7.49951L14.7604 1.89098C14.9146 1.73659 14.9998 1.53061 15 1.31098C15 1.09122 14.9149 0.885 14.7604 0.730854L14.2689 0.239512C14.1144 0.0847561 13.9084 0 13.6885 0C13.469 0 13.263 0.0847561 13.1085 0.239512L7.50024 5.84768L1.89171 0.239512C1.73744 0.0847561 1.53134 0 1.31159 0C1.09207 0 0.885976 0.0847561 0.731707 0.239512L0.24 0.730854C-0.08 1.05085 -0.08 1.57134 0.24 1.89098L5.84841 7.49951L0.24 13.1078C0.0856097 13.2624 0.000609756 13.4684 0.000609756 13.688C0.000609756 13.9077 0.0856097 14.1137 0.24 14.2682L0.731585 14.7595C0.885854 14.9141 1.09207 14.999 1.31146 14.999C1.53122 14.999 1.73732 14.9141 1.89159 14.7595L7.50012 9.15122L13.1084 14.7595C13.2629 14.9141 13.4689 14.999 13.6884 14.999C13.9083 14.999 14.1143 14.9141 14.2688 14.7595L14.7602 14.2682C14.9145 14.1138 14.9996 13.9077 14.9996 13.688C14.9996 13.4684 14.9145 13.2624 14.7602 13.1079L9.15207 7.49951Z" fill="#B8B7BB"/>
  </Svg>
)

const DisabledChatContainer = styled.View`
  flex: 1 1 auto;
  margin: 20px;
`

const DisabledChatText = styled.Text`
  font-size: 20px;
  color: #ff805c;
`

export default styled(WebinarDesktop)`
  flex: 1 1 auto;
`
