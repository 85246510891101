import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { View } from 'react-native'
import Timer from './Timer'

const getPosition = position => {
  switch (position) {
    case 'top_left':
      return { top: '10px', left: '10px' }
    case 'top_right':
      return { top: '10px', right: '10px' }
    case 'top_center':
      return { top: '10px', left: '50%', transform: 'translateX(-50%)' }
    case 'bottom_left':
      return { bottom: '10px', left: '10px' }
    case 'bottom_right':
      return { bottom: '10px', right: '10px' }
    case 'bottom_center':
      return { bottom: '10px', left: '50%', transform: 'translateX(-50%)' }
    default:
      return { top: '10px', right: '10px' }
  }
}
const Closeout = props => {
  const { enabled, button_text: buttonText, position, redirect_url: redirectUrl, url, expires_at: expiresAt, text } = props.options

  const secondsLeft = () => {
    const now = new Date()
    const expires = new Date(expiresAt)
    const timeLeft = Math.max(expires.getTime() - now.getTime(), 0)
    return timeLeft / 1000
  }

  const isExpired = () => secondsLeft() <= 0
  const secsLeft = secondsLeft()

  const onClick = () => {
    const link = isExpired() ? redirectUrl : url
    props.onClick(link)
  }

  const onExpire = () => (
    redirectUrl ? props.onClick(redirectUrl) : props.onExpire()
  )

  const wrapperStyle = {
    ...getPosition(position),
    visibility: props.display ? 'visible' : 'hidden'
  }

  if (!enabled) return null

  return <Wrapper style={wrapperStyle}>
    <Column>
      <TextWrapper style={{ flex: 0.5 }}><Text>{ text }</Text></TextWrapper>
      <StyledTimer seconds={secsLeft} onFinishCallback={onExpire} />
    </Column>
    <LastColumn style={{ flex: '0 0 90px;' }}>
      <Button onPress={onClick} activeOpacity={0.7}><ButtonText>{ buttonText }</ButtonText></Button>
    </LastColumn>
  </Wrapper>
}

Closeout.propTypes = {
  className: PropTypes.string,
  display: PropTypes.bool,
  options: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onExpire: PropTypes.func.isRequired
}

Closeout.defaultProps = {
  options: {}
}

const Button = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ff7e5c;
  padding: 8px 12px;
  margin-left: 10px;
  height: 35px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 12px;
  cursor: pointer;
`

const ButtonText = styled.Text`
  color: #ffffff;
  font-size: 0.8em;
`

const Column = styled.View`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`

const LastColumn = styled(Column)`
  flex: 0 0 90px;
  justify-content: center;
`

const StyledTimer = styled(Timer)`
  flex: 0.5;
`

const Text = styled.Text`
  font-size: 0.9em;
  font-weight: 500;
`

const TextWrapper = styled.View`
  width: 100%;
  text-align: left;
  margin-left: 15px;
`

const Wrapper = styled.View`
  position: absolute;
  width: 310px;
  min-height: 55px;
  padding: 10px;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 12px;
  z-index: 101;
`

export default Closeout
