import { useState, useEffect } from 'react'
import { Dimensions, PixelRatio, Platform } from 'react-native'

const iOS = () => {
  if (Platform.OS === 'ios') return true
  if (Platform.OS !== 'web') return false

  const platforms = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
  // iPad on iOS 13 detection
  const iPadDetection = (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

  return platforms.includes(navigator.platform) || iPadDetection
}

const fixHeight = dimensions => {
  window.scrollTo(0, 1)

  if (window.innerHeight !== dimensions.window.height) {
    setTimeout(() => fixHeight(dimensions), 100)
  }
}

const useDimensionsChange = () => {
  const [dimensions, setDimensions] = useState({ window: Dimensions.get('window'), screen: Dimensions.get('screen') })

  const wp = widthPercent => (
    PixelRatio.roundToNearestPixel(dimensions.window.width * widthPercent / 100)
  )

  const hp = heightPercent => (
    PixelRatio.roundToNearestPixel(dimensions.window.height * heightPercent / 100)
  )

  useEffect(() => {
    let listener
    if (iOS()) {
      listener = new ResizeObserver(entries => {
        const [{ contentRect: window }] = entries
        const screen = Dimensions.get('screen')
        setDimensions({ screen, window: { width: window.width, height: window.height - 20 } })

        fixHeight({ screen, window })
      })

      listener.observe(window.document.body)
    } else {
      Dimensions.addEventListener('change', newDimensions => setDimensions({ ...newDimensions }))
    }

    return () => {
      if (iOS()) {
        listener.unobserve(window.document.body)
      } else {
        Dimensions.removeEventListener('change', setDimensions)
      }
    }
  }, [])


  return { dimensions, wp, hp }
}

export default useDimensionsChange
