const es = {
  app: {
    title: 'Attend A Webinar',
    description: 'Enter your webinar join code below',
    type_code: 'Type code',
    submit: 'Join Webinar',
    token_not_found: 'Webinar is not available. This webinar or its recording was removed. Please contact the host for more information.',
    token_not_exist: 'Please enter your webinar join code.',
    admin: 'Admin'
  },
  tabs: {
    messages: 'Chat',
    qa: 'P&R',
    hosts: 'Anfitriones',
    attendees: 'Asistentes',
    files: 'Archivos',
    dm: 'DM',
    quit: 'Cerrar',
    quit_confirmation_title: 'Cerrar seminario web',
    quit_confirmation_message: '¿Estás seguro/a que quieres irte?',
  },
  finish: {
    title: 'El organizador del seminario web ha terminado el evento',
    notice: 'La aplicación cerrará automáticamente en 30 segundos',
    close: 'Cerrar'
  },
  connect: {
    starts_in: 'El seminario web empieza en',
    hours: 'Hrs',
    minutes: 'Min' ,
    seconds: 'Seg',
    notice: 'La reunión comienzará apenas llegue el anfitrión.'
  },
  player: {
    click_for_sound: 'Ház click para activar el sonido'
  },
  update: {
    title: 'Actualizando...'
  },
  chat: {
    shared_file: 'Compatió un archivo',
    poll: 'Encuesta',
    asked_question: 'Compartió una pregunta',
    message_placeholder: 'Escribe un mensaje'
  },
  qa: {
    question_placeholder: 'Escribe una pregunta',
    answered: 'Respondida',
    not_answered: 'No respondida'
  }
}

export default es
