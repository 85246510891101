import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { WebinarContext } from '../Context'
import HorizontalLayout from './HorizontalLayout'
import VerticalLayout from './VerticalLayout'

const WebinarPhone = props => {
  const { isVertical } = useContext(WebinarContext)

  return isVertical
    ? <VerticalLayout loadMoreMessages={props.loadMoreMessages} loadMoreQuestions={props.loadMoreQuestions} />
    : <HorizontalLayout loadMoreMessages={props.loadMoreMessages} loadMoreQuestions={props.loadMoreQuestions} />
}
WebinarPhone.propTypes = {
  loadMoreMessages: PropTypes.func,
  loadMoreQuestions: PropTypes.func,
}
export default WebinarPhone
