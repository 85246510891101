import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dimensions, Image } from 'react-native'

const PAD_WIDTH = 20

const SmartImage = React.memo(props => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const maxWidth = props.maxWidth || Dimensions.get('window').width

  useEffect(() => {
    if (!props.uri) return

    Image.getSize(props.uri, (imageWidth, imageHeight) => {
      const resWidth = Math.min(maxWidth, imageWidth) - (props.padWidth || PAD_WIDTH) * 2
      const resHeight = resWidth * imageHeight / imageWidth

      setWidth(resWidth)
      setHeight(resHeight)
    })
  }, [])

  if (!props.uri) return null

  return (
    <Image
      onLoadEnd={ props.onLoad }
      source={{ uri: props.uri }}
      style={[props.style, { width: width, height: height }]}
    />
  )
})
SmartImage.propTypes = {
  style: PropTypes.array,
  uri: PropTypes.string
}

export default SmartImage
