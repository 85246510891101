const en = {
  app: {
    title: 'Attend A Webinar',
    description: 'Enter your webinar join code below',
    type_code: 'Type code',
    submit: 'Join Webinar',
    token_not_found: 'Webinar is not available. This webinar or its recording was removed. Please contact the host for more information.',
    token_not_exist: 'Please enter your webinar join code.',
    admin: 'Admin'
  },
  tabs: {
    messages: 'Messages',
    qa: 'Q&A',
    hosts: 'Hosts',
    attendees: 'Attendees',
    files: 'Files',
    dm: 'DM',
    quit: 'Quit',
    quit_confirmation_title: 'Quit webinar',
    quit_confirmation_message: 'Are you sure you want to leave?',
  },
  finish: {
    title: 'Webinar Organizer has ended this Webinar',
    notice: 'This app closes automatically in 30 seconds',
    close: 'Close'
  },
  connect: {
    starts_in: 'Your Event Begins In',
    hours: 'Hrs',
    minutes: 'Min' ,
    seconds: 'Sec',
    notice: 'The meeting will begin when the organizer arrives.'
  },
  player: {
    click_for_sound: 'Click Here To Join Webinar In Progress',
    click_for_sound1: 'Join Webinar In Progress'
  },
  update: {
    title: 'Updating...'
  },
  chat: {
    shared_file: 'Shared a file',
    poll: 'Shared a poll',
    asked_question: 'Asked a question',
    message_placeholder: 'Type message and hit enter'
  },
  qa: {
    question_placeholder: 'Ask your question and hit enter',
    answered: 'Answered',
    not_answered: 'Non Answered'
  }
}

export default en
