import React, { useState } from 'react'
import * as Updates from 'expo-updates'
import AppLoading from 'expo-app-loading'
import * as SplashScreen from 'expo-splash-screen'
import { Platform } from 'react-native'
import { Provider } from 'react-redux'
import * as Font from 'expo-font'
import { store } from './src/store'
import Main from './src'
import getScreenInfo from './src/services/screen'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

SplashScreen.preventAutoHideAsync()
  .then(result => console.log(`SplashScreen.preventAutoHideAsync() succeeded: ${result}`))
  .catch(console.warn); // it's good to explicitly catch and inspect any error

let Bugsnag
if (mobile) {
  Bugsnag = require('@bugsnag/react-native')
  Bugsnag.start()
} else {
  Bugsnag = require('@bugsnag/js')
  Bugsnag.start("e8fc07c92ce42b0e6813380d23c1467c")
}

if (mobile) {
  global.document = {
    addEventListener() {},
    removeEventListener() {}
  }
} else {
  document.addEventListener('contextmenu', e => {
    e.preventDefault()
  }, false)
}

const loadFonts = async () => {
  Font.loadAsync({
    'Gilroy-Regular': require('./assets/fonts/Gilroy-Regular.otf'),
    'Gilroy-Medium': require('./assets/fonts/Gilroy-Medium.otf'),
    'Gilroy-SemiBold': require('./assets/fonts/Gilroy-SemiBold.otf'),
    'Gilroy-Bold': require('./assets/fonts/Gilroy-Bold.otf')
  })
}

const initApp = async () => {
  await loadFonts()
  await getScreenInfo.assignData()

  const nonePromise = new Promise(resolve => {
    console.log('Ready')
    resolve()
  })

  if (!mobile) return nonePromise

  const update = await Updates.checkForUpdateAsync()
  if (update.isAvailable) {
    await Updates.fetchUpdateAsync()
    await Updates.reloadAsync()
  }

  return nonePromise
}

const App = () => {
  const [loaded, setLoaded] = useState(false)

  const checkForUpdates = async () => {
    const update = await Updates.checkForUpdateAsync()
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync()
      await Updates.reloadAsync()
    }
  }

  console.log('[App.js] loaded', loaded)

  if (!loaded) return (
    <AppLoading
      startAsync={initApp}
      onFinish={() => {
        console.log('[AppLoading] onFinish')
        SplashScreen.hideAsync()
        setLoaded(true)
      }}
      onError={e => {
        Bugsnag.notify(e, event => {
          event.addMetadata('params', { type: 'AppLoading' })
        })
      }}
    />
  )

  const mainComponent = (
    <Provider store={store}>
      <Main />
    </Provider>
  )

  if (Bugsnag.getPlugin('react')) {
    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
    return <ErrorBoundary>{ mainComponent }</ErrorBoundary>
  }

  return mainComponent
}

export default App
