import en from './i18n/en'
import ru from './i18n/ru'
import fr from './i18n/fr'
import ja from './i18n/ja'
import es from './i18n/es'

// const locales = { en, fr, de, ru, es, ja }
const locales = { en, ru, fr, ja, es }

class I18n {
  set locale(val) {
    this._locale = val
  }

  get locale() {
    return this._locale || this.userLocale || 'en'
  }

  set userLocale(val) {
    if (!val) return

    this._userLocale = val.split('-')[0]
  }

  get userLocale() {
    return this._userLocale
  }

  t(key, fallback) {
    const localeValues = locales[this.locale] || locales.en
    return key.toLowerCase().split('.').reduce((acc, part) => (
      typeof acc === 'object' ? acc[part] : acc
    ), localeValues) || fallback
  }
}

export default new I18n()
