import React, { useState, useEffect } from 'react'
import { ScrollView } from 'react-native'
import styled from 'styled-components/native'
import Constants from 'expo-constants'
import * as Updates from 'expo-updates'
import secrets, { env } from '../../config/secrets'
import storage from './../../services/storage'

const DebugInfo = props => {
  const [expoToken, setExpoToken] = useState()
  const elements = [
    { label: 'ver', value: 15 },
    { label: 'Updates.updateId', value: Updates.updateId },
    { label: 'env', value: env },
    { label: 'secrets', value: secrets },
    { label: 'Constants.manifest.releaseChannel', value: Constants.manifest.releaseChannel },
    { label: 'Constants.manifest.extra', value: Constants.manifest.extra },
    { label: 'Constants.deviceId', value: Constants.deviceId },
    { label: 'Expo.token', value: expoToken }
  ]

  const getExpoToken = async () => {
    const storedToken = await storage.get('expoPushToken')
    setExpoToken(storedToken)
  }

  useEffect(() => {
    getExpoToken()
  })

  return (
    <ScrollView style={props.style}>
      {
        elements.map((el, i) => (
          <DebugEl key={i}>
            <DebugElText>{el.label}: {JSON.stringify(el.value)}</DebugElText>
          </DebugEl>
        ))
      }
    </ScrollView>
  )
}

const DebugEl = styled.View`
  font-size: 14px;
  margin-top: 10px;
`

const DebugElText = styled.Text`
  font-size: 14px;
  color: #ffffff;
`

export default styled(DebugInfo)`
  position: absolute;
  top: 30px;
  left: 0;
  color: white;
  background: black;
  padding: 10px;
  width: 100%;
`
