const isOwnMessage = (message, viewerSession) => message.author.cid === viewerSession.cid
const isNotOwnMessage = (message, viewerSession) => !message.author.cid || message.author.viewer_token !== viewerSession.viewer_token

const showRealMessage = (message, viewerSession) => {
  if (viewerSession.webinar.chat_tab === 'private') {
    return message.author.admin || isOwnMessage(message, viewerSession)
  }

    return viewerSession.webinar.chat_tab === 'enabled'
}

const showJitMessage = (message, viewerSession) => {
  if (viewerSession.webinar.chat_tab === 'enabled') {
    return isNotOwnMessage(message, viewerSession)
  }

  return false
}

const showBadMessage = (message, viewerSession) => message.author.cid === viewerSession.cid

const showQuestionMessage = (message, viewerSession) => {
  if (viewerSession.webinar.qa_tab === 'private') {
    return isOwnMessage(message, viewerSession)
  }

  return viewerSession.webinar.qa_tab === 'enabled'
}

const showJitQuestionMessage = (message, viewerSession) => {
  if (viewerSession.webinar.qa_tab === 'enabled') {
    return isNotOwnMessage(message, viewerSession)
  }

  return false
}

export default (message, viewerSession) => {
  if (!message.author || message.deleted_at) {
    return false
  }

  switch (message.type) {
    case 'real':
      return showRealMessage(message, viewerSession)

    case 'jit_message':
      return showJitMessage(message, viewerSession)

    case 'bad_message':
      return showBadMessage(message, viewerSession)

    case 'jit_bad_message':
      return false

    case 'question':
      return showQuestionMessage(message, viewerSession)

    case 'jit_question':
      return showJitQuestionMessage(message, viewerSession)

    case 'file':
    case 'poll':
    case 'announcement':
    case 'sticky':
    case 'normal':
      return true

    default:
      return false
  }
}
