export const durationToSeconds = duration => {
  const [h, m, s] = duration.split(':')
  return parseInt(h) * 60 * 60 + parseInt(m) * 60 + parseInt(s)
}

export const parseSeconds = seconds => {
  let hours = Math.floor(seconds / 3600)
  let minutes = Math.floor((seconds - (hours * 3600)) / 60)
  let secs = Math.floor(seconds - (hours * 3600) - (minutes * 60))

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  if (secs < 10) {
    secs = `0${secs}`
  }

  return { hours, minutes, secs }
}

export const secondsToDuration = seconds => {
  const { hours, minutes, secs } = parseSeconds(seconds)

  return `${hours}:${minutes}:${secs}`
}
