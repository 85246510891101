import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Animated, SafeAreaView, View, Text, TouchableOpacity } from 'react-native'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import styled from 'styled-components/native'
import throttle from 'lodash/throttle'
import moment from 'moment-timezone'
import '@expo/vector-icons'
import Svg, { Path } from 'react-native-svg'
import Controls from '../Controls'
import visitor from '../../../services/visitor'
import { EventEmitter } from '../../../services/events'
import i18n from '../../../services/i18n'
import Closeout from '../Closeout/Closeout'

const videoInit = (el, config, onReady) => {
  el.src = config.src
  el.controls = config.controls
  el.autoplay = config.autoplay
  el.muted = config.muted

  const api = {
    el: el,
    playAllowed: false,
    on: (event, callback) => {
      el.addEventListener(event, callback)
    },
    muted: flag => {
      el.muted = flag
    },
    play: () => {
      el.play()
    },
    pause: () => {
      el.pause()
    },
    currentTime: time => {
      if (time && time > 0) {
        return el.currentTime = time
      } else {
        return el.currentTime
      }
    },
    togglePlay: () => {
      if (el.paused) {
        el.play()
      } else {
        el.pause()
      }
    },
    removeControls: () => {
      if (el.hasAttribute("controls")) {
        el.removeAttribute("controls")
      }
    },
    toggleControls: () => {
      if (el.hasAttribute("controls")) {
        el.removeAttribute("controls")
      } else {
        el.setAttribute("controls","controls")
      }
    },
    dispose: () => {
      el.setAttribute('src', '')
      el.load()
      el.remove()
    },
    load: () => {
      el.load()
    },
    error: () => el.error
  }

  return api
}

const liveConfig = { autoplay: true, muted: true }
const replayConfig = { autoplay: false, muted: false }

const WebVideoPlayer = props => {
  const [webPlaceholder, setWebPlaceholder] = useState(false)
  const [controlsVisible, setControlsVisible] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playerDisabled, setPlayerDisabled] = useState(false)

  const videoWrapperRef = useRef(null)
  const videoRef = useRef(null)
  const [animatePause] = useState(new Animated.Value(0))
  const [animatePlay] = useState(new Animated.Value(0))

  useEffect(() => {
    const config = visitor.viewer_session.is_replay ? replayConfig : liveConfig
    config.src = props.link
    const videoEl = document.createElement('video')
    videoEl.setAttribute('playsinline', '')
    videoEl.style.width = '100%'
    videoEl.style.height = '100%'
    if (visitor.viewer_session.is_replay) {
      const poster = visitor.viewer_session.video.replay_start_image_cf_url
      if (poster) {
        videoEl.setAttribute('poster', poster)
      }
    }
    videoWrapperRef.current.appendChild(videoEl)
    videoRef.current = videoInit(videoEl, config, e => {
      console.log('ready')
    })
    window.videoRef = videoRef.current
    videoEl.addEventListener('loadedmetadata', () => {
      setWebPlaceholder(true)
    }, false)
    videoRef.current.on('play', () => {
      setIsPlaying(true)
    })
    videoRef.current.on('pause', () => {
      setIsPlaying(false)
    })
    videoRef.current.on('ended', () => {
      setIsPlaying(false)
    })
    if (visitor.viewer_session.is_replay) {
      videoRef.current.on('timeupdate', throttle(() => {
        EventEmitter.dispatch('time', videoRef.current.currentTime())
        visitor.setTime(videoRef.current.currentTime())
      }, 1000))
    } else {
      videoEl.addEventListener('loadedmetadata', () => {
        videoEl.currentTime = visitor.time
      }, false)
      videoRef.current.on('resume', e => {
        videoRef.current.currentTime(visitor.time)
      })
    }

    videoRef.current.on('error', () => {
      console.error('Video Error:', videoRef.current, videoEl)

      setTimeout(() => {
        videoRef.current.load()
        videoRef.current.currentTime(visitor.time)
      }, 3000)

      console.error(`Error ${videoRef.current.error().code}; details: ${videoRef.current.error().message}`)
    })

    EventEmitter.subscribe('timeChanged', () => {
      videoRef.current.currentTime(Math.abs(visitor.time))
    })

    const css = '.vsc-controller { display: none !important; visibility: hidden !important; }'
    const head = document.head || document.getElementsByTagName('head')[0]
    const style = document.createElement('style')

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet){
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    return () => {
      EventEmitter.unsubscribe('timeChanged')
      if (videoEl) {
        videoEl.pause()
        videoEl.removeAttribute('src')
        videoEl.load()
        videoEl.remove()
      }
    }
  }, [])

  const notifyPlay = () => {
    Animated.sequence([
      Animated.timing(animatePlay, {
        toValue: 0.6,
        duration: 150,
        useNativeDriver: true
      }),
      Animated.timing(animatePlay, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true
      })
    ]).start()
  }

  const notifyPause = () => {
    Animated.sequence([
      Animated.timing(animatePause, {
        toValue: 0.6,
        duration: 150,
        useNativeDriver: true
      }),
      Animated.timing(animatePause, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true
      })
    ]).start()
  }

  const toggleControls = () => {
    setControlsVisible(prev => {
      if (visitor.viewer_session.is_replay) {
        if (prev) {
          videoRef.current.play()
          notifyPlay()
        } else {
          videoRef.current.pause()
          notifyPause()
        }
      }

      return !prev
    })
  }

  const startVideo = () => {
    setWebPlaceholder(false)
    videoRef.current.play()
  }

  const enableSound = () => {
    videoRef.current.muted(false)
    videoRef.current.playAllowed = true
    setWebPlaceholder(false)
  }

  const proportionalHeight = (wp(100) - props.sidebarWidth) * props.height / props.width
  const defaultStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
    // height: props.dir === 'column' ? props.height : proportionalHeight
  }

  const fullScreenStyles = {
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }

  const playPauseStyles = {
    backgroundColor: '#ffffff',
    borderRadius: 40
  }

  const pauseStyles = {
    ...playPauseStyles,
    opacity: animatePause,
    transform: [{
      scale: animatePause.interpolate({
        inputRange: [0, 1],
        outputRange: [0.9, 1]
      }),
    }]
  }

  const playStyles = {
    ...playPauseStyles,
    opacity: animatePlay,
    transform: [{
      scale: animatePlay.interpolate({
        inputRange: [0, 1],
        outputRange: [0.9, 1]
      }),
    }]
  }

  const disablePlayer = () => {
    videoRef.current.playAllowed = false
    videoRef.current.controls = false
    videoRef.current.pause()
    videoRef.current.removeControls()
    setWebPlaceholder(false)
    setControlsVisible(false)
    setPlayerDisabled(true)
  }

  return (
    <View style={ props.fullScreenVideo ? fullScreenStyles : defaultStyles }>
      <Container>
        <View style={{ opacity: 1 || props.opacity, flexGrow: 1, flexShrink: 1, flexBasis: 'auto', alignItems: 'center' }}>
          <VideoWrapper activeOpacity={1}>
            <StyledVideo ref={videoWrapperRef} />
            <PlayPauseNotifier>
              <Animated.View style={pauseStyles}><PauseIcon /></Animated.View>
            </PlayPauseNotifier>
            <PlayPauseNotifier>
              <Animated.View style={playStyles}><PlayIcon /></Animated.View>
            </PlayPauseNotifier>
            <ControlsOverlay activeOpacity={1} onPress={toggleControls} />
            {controlsVisible &&
              <Controls
                duration={props.duration}
                value={(videoRef.current ? videoRef.current.currentTime() : 0) / props.duration * 100}
                onChange={value => {
                  videoRef.current.currentTime(value * props.duration / 100)
                }}
                showBar={visitor.showControls}
              />
            }
            {webPlaceholder &&
              (visitor.viewer_session.is_replay
              ? <WebWrapper onPress={startVideo}>
                  <PlayIcon fill="#ffffff" />
                  <WebText>{ visitor.viewer_session.video.replay_start_text }</WebText>
                </WebWrapper>
              : <WebWrapper onPress={enableSound}>
                  <WebIcon>
                    <ActivityIndicatorStyled size="large" />
                  </WebIcon>
                  <WebText>{ i18n.t('player.click_for_sound') }</WebText>
                </WebWrapper>
              )
            }
            { !webPlaceholder && playerDisabled && <WebWrapper activeOpacity={1} style={{ cursor: 'default' }} /> }
            {
              visitor.viewer_session.is_rewatch && (
                <Closeout
                  options={visitor.viewer_session.closeout}
                  onClick={url => visitor.openUrl(url, { newTab: true })}
                  onExpire={disablePlayer}
                  display={!isPlaying}
                />
              )
            }
          </VideoWrapper>
        </View>
      </Container>
    </View>
  )
}
WebVideoPlayer.propTypes = {
}

const Container = styled.View`
  flex: 1 1 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const ActivityIndicatorStyled = styled.ActivityIndicator.attrs({ color: '#ff805c' })`
  margin-top: ${hp(10)}px;
`

const VideoWrapper = styled.View`
  flex-basis: initial;
  flex-grow: initial;
  flex-shrink: initial;
`

const ControlsOverlay = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const SoundImage = styled.Image`
  width: 75px;
  height: 75px;
`

const StyledVideo = styled.View`
  flex-basis: initial;
  flex-grow: initial;
  flex-shrink: initial;
`

const WebWrapper = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 100;
  align-items: center;
  justify-content: center;
`

const WebText = styled.Text`
  color: #ffffff;
  font-size: 28px;
  margin-top: 20px;
`

const WebIcon = styled.View`
`

const PlayPauseNotifier = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PauseIcon = () => (
  <Svg viewBox="0 0 1792 1792" width="80" height="80">
    <Path d="M832 1184v-576q0-14-9-23t-23-9h-256q-14 0-23 9t-9 23v576q0 14 9 23t23 9h256q14 0 23-9t9-23zm448 0v-576q0-14-9-23t-23-9h-256q-14 0-23 9t-9 23v576q0 14 9 23t23 9h256q14 0 23-9t9-23zm384-288q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
  </Svg>
)

const PlayIcon = props => (
  <Svg viewBox="0 0 1792 1792" height="80" width="80">
    <Path fill={props.fill} d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5-103 385.5-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103zm384 823q32-18 32-55t-32-55l-544-320q-31-19-64-1-32 19-32 56v640q0 37 32 56 16 8 32 8 17 0 32-9z"/>
  </Svg>
)

export default React.memo(WebVideoPlayer)
