import axios from 'axios'
import secrets from '../config/secrets'

const fetchDirectMessagesBegin = () => ({ type: 'FETCH_DIRECT_MESSAGES_BEGIN' })
const fetchDirectMessagesSuccess = ({ messages }) => ({ type: 'FETCH_DIRECT_MESSAGES_SUCCESS', messages })
const fetchDirectMessagesError = error => ({ type: 'FETCH_DIRECT_MESSAGES_ERROR', error })

export function fetchDirectMessages(cid) {
  return dispatch => {
    dispatch(fetchDirectMessagesBegin())

    return axios({
      url: `${secrets.domain}/meeting/direct_messages?cid=${cid}`
    })
    .then(response => {
      dispatch(fetchDirectMessagesSuccess(response.data))
    })
    .catch(error => {
      dispatch(fetchDirectMessagesError(error))
    })
  }
}

export const addDirectMessage = (message, unreaded) => ({ type: 'ADD_DIRECT_MESSAGE', message, unreaded })
export const removeDirectMessage = id => ({ type: 'REMOVE_DIRECT_MESSAGE', id })
export const clearUnreadedCount = () => ({ type: 'CLEAR_UNREADED_COUNT' })
export const clearDirectMessages = () => ({ type: 'CLEAR_DIRECT_MESSAGES' })
