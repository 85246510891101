import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { View, Linking, Platform } from 'react-native'
import { Button } from 'react-native-elements'
import styled from 'styled-components/native'
import OfferCountdown from './OfferCountdown'
import SmartImage from '../../../SmartImage'
import secrets from '../../../../config/secrets'
import visitor from '../../../../services/visitor'
import { durationToSeconds } from '../../../../helpers/time'

export const openUrl = url => {
  if (Platform.OS == 'web') {
    window.open(url, '_blank')
  } else {
    Linking.openURL(url)
  }
}

const Offer = props => {
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    let timeoutId
    visitor.trackViewOffer(props.id, props.ctaId)
    visitor.triggerOffer(props.ctaId)
    if (!props.imageUrl || !props.imageUrl.length) {
      setRendered(true)
    } else {
      timeoutId = setTimeout(() => {
        setRendered(true)
      }, 1000)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [])

  const onFinish = () => {
    props.onCountdownFinish(props.id)
  }

  const onLinkClick = () => {
    const offerLink = encodeURIComponent(props.link)
    const offerUrl = `${secrets.domain}/offer/${props.cid}?url=${offerLink}&offer_id=${props.ctaId}&primary=true`
    openUrl(offerUrl)
    visitor.trackClickOffer(props.id, props.ctaId)
  }

  const onSecondaryLinkClick = () => {
    const offerLink = encodeURIComponent(props.secondaryLink)
    const offerUrl = `${secrets.domain}/offer/${props.cid}?url=${offerLink}&offer_id=${props.ctaId}&primary=false`
    openUrl(offerUrl)
    visitor.trackClickOffer(props.id, props.ctaId)
  }

  const onImageLoad = useCallback(() => {
    setRendered(true)
  }, [])

  return (
    <View style={[props.style, { opacity: rendered ? 1 : 0 }]}>
      {
        props.imageUrl && props.imageUrl.length
        ? <OfferImageClickable onPress={ onLinkClick }>
            <OfferImage uri={ props.imageUrl } onLoad={onImageLoad} maxWidth={408} />
          </OfferImageClickable>
        : null
      }
      {props.countdownEnabled &&
        <OfferCountdown
          id={props.id}
          value={props.countdown}
          visible={true}
          offsetSecs={durationToSeconds(props.appearIn)}
          onFinish={props.onCountdownFinish}
        />
      }
      <OfferText>{ props.offer }</OfferText>
      <OfferAdditionalText>{ props.offerText }</OfferAdditionalText>
      <Actions>
        <Button buttonStyle={{ backgroundColor: '#282a80', borderRadius: 8 }} titleStyle={{ color: '#ffffff', fontSize: 17 }} title={props.linkLabel} onPress={onLinkClick} />
        {
          props.secondaryLink
          ? <Button buttonStyle={{ backgroundColor: 'rgba(40, 42, 128, 0.2)', borderRadius: 8 }} titleStyle={{ color: '#282a80', fontSize: 17 }} containerStyle={{ marginTop: 10 }} title={props.secondaryLinkLabel} onPress={onSecondaryLinkClick} />
          : null
        }
      </Actions>
    </View>
  )
}
Offer.propTypes = {
  className: PropTypes.string,
  offer: PropTypes.string,
  offerText: PropTypes.string,
  cid: PropTypes.string,
  id: PropTypes.number,
  countdownNode: PropTypes.node,
  ctaId: PropTypes.number,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  secondaryLink: PropTypes.string,
  secondaryLinkLabel: PropTypes.string,
  imageUrl: PropTypes.string
}

const OfferText = styled.Text`
  font-size: 24px;
  font-family: Gilroy-Regular;
  color: #02091B;
  margin-top: 15px;
  text-align: center;
`

const OfferAdditionalText = styled.Text`
  font-size: 16px;
  font-family: Gilroy-Regular;
  color: #02091B;
  margin-top: 10px;
  text-align: center;
`

const OfferImageClickable = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
`

const OfferImage = styled(SmartImage)`
  width: 31px;
  height: 31px;
  border-radius: 4px;
`

const Actions = styled.View`
  margin-top: 8px;
`

const OfferButton = styled(Button)`
  background-color: ${props => props.primary ? '#282a80' : 'rgba(40, 42, 128, 0.2)'};
  border-radius: 8px;
  height: 45px;
  margin-top: ${props => props.primary ? 10 : 15}px;
`

const OfferButtonText = styled.Text`
  font-weight: bold;
  font-size: 18px;
  font-family: Gilroy-Regular;
  color: ${props => props.color};
`

export default styled(React.memo(Offer))`
  display: flex;
  flex-direction: column;
`
