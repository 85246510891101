import React, { useState, useEffect } from 'react'

const checkOrientation = (angle = 0) => (
  angle === 0 || angle === 180 ? 'portrait' : 'landscape'
)

const initialOrientation = checkOrientation(window.orientation)

const useOrientationChange = () => {
  const [orientation, setOrientation] = useState(checkOrientation(window.orientation))

  useEffect(() => {
    const listener = new ResizeObserver(entries => {
      const [{ target: body, contentRect }] = entries
      if (contentRect.height > contentRect.width) {
        // setFullScreenVideo(false)
        console.log('PORTRAIT', contentRect)
      } else {
        console.log('LANDSCAPE', contentRect)
      }
    })
    listener.observe(window.document.body)

    const handleChange = event => {
      console.log(`event`, event.target)
      const angle = window.orientation || event.target.screen?.orientation?.angle
      console.log("the orientation of the device is now " + angle)

      setOrientation(checkOrientation(angle))
    }

    window.addEventListener('orientationchange', handleChange)

    return () => {
      window.removeEventListener('orientationchange', handleChange)
      listener.unobserve(window.document.body)
    }
  }, [])

  return { orientation, initialOrientation }
}

export default useOrientationChange
