import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useDispatch } from 'react-redux'
import { removeSession } from '../../actions/viewer'
import i18n from '../../services/i18n'

const FinishWindow = props => {
  const dispatch = useDispatch()

  const close = () => {
    dispatch(removeSession())
  }

  return (
    <Body>
      <Title>{ i18n.t('finish.title') }</Title>
      <Notice>{ i18n.t('finish.notice') }</Notice>
      <ButtonWrapper>
        <FinishButton onPress={ close } title={i18n.t('finish.close')} />
      </ButtonWrapper>
    </Body>
  )
}

const Body = styled.View`
  background: #ffffff;
`

const Title = styled.Text`
  margin-top: 40px;
  font-size: 24px;
  text-align: center;
`

const Notice = styled.Text`
  margin-top: 15px;
  text-align: center;
  font-size: 20px;
`

const ButtonWrapper = styled.View`
  margin-top: 15px;
`

const FinishButton = styled.Button`
  padding: 6px 18px;
  font-size: 18px;
`


export default FinishWindow
