import viewer from './viewer'
import offers from './offers'
import messages from './messages'
import questions from './questions'
import files from './files'
import hosts from './hosts'
import direct_messages from './direct_messages'
import sticky_messages from './sticky_messages'

export default {
  viewer,
  offers,
  messages,
  questions,
  files,
  hosts,
  direct_messages,
  sticky_messages,
}
