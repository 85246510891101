import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Animated, Easing, View } from 'react-native'
import { Button } from 'react-native-elements'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import { heightPercentageToDP as hp } from 'react-native-responsive-screen'
import { PanGestureHandler, ScrollView } from 'react-native-gesture-handler'
import moment from 'moment-timezone'
import Offer from './Offer'
import visitor from '../../../../services/visitor'
import { EventEmitter } from '../../../../services/events'
import { selectOffers } from '../../../../selectors'
import { durationToSeconds } from '../../../../helpers/time'
import FullScreenOffer from './FullScreenOffer'

const filterOffers = offers => (
  orderBy(uniqBy(offers.filter(o => {
    const { countdown } = o.data
    if (!countdown || countdown === '00:00:00') {
      return true
    }

    const offsetSecs = durationToSeconds(o.appear_in)
    const seconds = durationToSeconds(countdown) - visitor.time + offsetSecs

    return seconds > 0
  }), o => o.webinar_cta.id), o => [o.appear_in], ['desc'])
)

const LiveOffers = props => {
  const rawOffers = useSelector(selectOffers)
  const [shouldToggle, setShouldToggle] = useState(false)

  const [headerVisible, setHeaderVisible] = useState(true)
  const [contentVisible, setContentVisible] = useState(false)
  const animatedValue = useRef(new Animated.Value(0))

  const toggleContent = () => {
    if (headerVisible) {
      setHeaderVisible(false)
    } else {
      setContentVisible(false)
    }

    visitor.offersClosed = !visitor.offersClosed
    Animated.timing(animatedValue.current, {
      toValue: +!contentVisible,
      duration: 300,
      easing: Easing.elastic(),
      useNativeDriver: false
    }).start(() => {
      if (headerVisible) {
        setContentVisible(true)
      } else {
        setHeaderVisible(true)
      }
    })
  }

  const interpolateColor = animatedValue.current.interpolate({
    inputRange: [0, 1],
    outputRange: ['rgb(255, 128, 92)', 'rgb(255, 255, 255)']
  })
  const interpolateHeight = animatedValue.current.interpolate({
    inputRange: [0, 1],
    outputRange: [71, hp(60)]
  })
  const [offers, setOffers] = useState(filterOffers(rawOffers))

  useEffect(() => {
    const filteredOffers = filterOffers(rawOffers)
    setOffers(prev => {
      const newOffersExists = filteredOffers.some(no => !prev.includes(no))

      if (newOffersExists) {
        const offersToTrigger = filteredOffers.filter(offer => (
          !visitor.viewedOfferIds.includes(offer.id) && !prev.map(o => o.id).includes(offer.id)
        ))
        if (offersToTrigger.length) {
          visitor.viewedOfferIds = visitor.viewedOfferIds.concat(offersToTrigger.map(o => o.id))
          setShouldToggle(true)
        }
        return filteredOffers
      }

      return prev
    })
  }, [rawOffers])

  useEffect(() => {
    EventEmitter.subscribe('offers.time', () => {
      const newOffers = filterOffers(offers)
      if (!offers.every(o => newOffers.includes(o))) {
        setOffers(newOffers)
      }
    })

    return () => {
      EventEmitter.unsubscribe('offers.time')
    }
  }, [offers])

  useEffect(() => {
    if (shouldToggle && headerVisible) {
      toggleContent()
      setShouldToggle(false)
    }
  }, [shouldToggle, headerVisible])

  useEffect(() => {
    EventEmitter.dispatch('activeOffers', offers.length)
  }, [offers.length])

  const onCountdownFinish = id => {
    setOffers(offers.filter(o => o.id !== id))
  }

  const onGestureEvent = e => {
    if (e.nativeEvent.translationY > 0 && e.nativeEvent.velocityY > 500) {
      setContentVisible(false)
      Animated.timing(animatedValue.current, {
        toValue: 0,
        duration: 300,
        easing: Easing.elastic(),
        useNativeDriver: false
      }).start(() => {
        setHeaderVisible(true)
      })
    }
  }

  const authorShortName = name => {
    const words = name.split(' ')
    return [words[0], words[1]?.substring(0, 1)].join(' ').trim()
  }

  if (!offers.length) return null

  const topOffer = offers[0]
  const timeZone = moment.tz.guess()
  const duration = moment.duration(topOffer.appear_in)
  let offerHumanCreatedAt
  if (visitor.viewer_session.scheduled_at) {
    offerHumanCreatedAt = moment(visitor.viewer_session.scheduled_at).add(duration).tz(timeZone).format('LT')
  }

  return (
    <View style={props.style} pointerEvents="box-none">
      {
        props.fullScreenVideo
        ? <FullScreenOffer cid={props.cid} topOffer={topOffer} offerHumanCreatedAt={offerHumanCreatedAt} />
        : <PanGestureHandler
            onGestureEvent={onGestureEvent}
            >
            <Container isDesktop={props.isDesktop}>
              <HeaderHandleWrapper><HeaderHandle visible={contentVisible} /></HeaderHandleWrapper>
              <Animated.View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: 'auto',
                  justifyContent: 'center',
                  height: interpolateHeight,
                  backgroundColor: interpolateColor,
                  borderTopLeftRadius: 12,
                  borderTopRightRadius: 12
                }}
              >
                {
                  contentVisible && (
                    <Content>
                      <ScrollView style={{ marginTop: 20 }}>
                        <List>
                          {
                            offers.map((offer, i) => (
                              <React.Fragment key={offer.id}>
                                { i !== 0 ? <OfferDivider /> : null }
                                <Offer
                                  cid={props.cid}
                                  id={offer.id}
                                  ctaId={offer.webinar_cta.id}
                                  link={offer.data.link}
                                  linkLabel={offer.data.link_label}
                                  secondaryLink={offer.data.secondary_link}
                                  secondaryLinkLabel={offer.data.secondary_link_label}
                                  imageUrl={offer.data.image_url}
                                  offer={offer.data.offer}
                                  offerText={offer.data.offer_text}
                                  countdownEnabled={offer.data.countdown_enabled === 'true'}
                                  countdown={offer.data.countdown}
                                  appearIn={offer.appear_in}
                                  onCountdownFinish={onCountdownFinish}
                                />
                              </React.Fragment>
                            ))
                          }
                        </List>
                      </ScrollView>
                    </Content>
                  )
                }
                {
                  headerVisible && (
                  <Header onPress={toggleContent}>
                      <HeaderWrapper>
                        <HeaderAvatar>
                          <HeaderAvatarImage source={{ uri: topOffer.author.avatar_url }} />
                        </HeaderAvatar>
                        <HeaderBody>
                          <HeaderTitle>
                            <HeaderAuthor>{ authorShortName(topOffer.author.name) }</HeaderAuthor>
                            <HeaderSentAt>{ offerHumanCreatedAt }</HeaderSentAt>
                          </HeaderTitle>
                          <HeaderContent>
                            <HeaderMessageText>{ topOffer.data.offer }</HeaderMessageText>
                          </HeaderContent>
                        </HeaderBody>
                      </HeaderWrapper>
                      <JoinButtonWrapper>
                        <Button buttonStyle={{ backgroundColor: '#ffffff', borderRadius: 8, paddingLeft: 12, paddingRight: 12 }} titleStyle={{ color: '#ff7e5c', fontSize: 17, fontWeight: 'bold' }} title={topOffer.data.join_label} onPress={toggleContent} />
                      </JoinButtonWrapper>
                    </Header>
                  )
                }
              </Animated.View>
            </Container>
          </PanGestureHandler>
        }
      {
        !headerVisible && !props.fullScreenVideo && (
          <Layout onPress={toggleContent} top={hp(100)}>
            <Animated.View
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(2, 3, 40, 0.85)',
              opacity: animatedValue.current
              }}
            />
          </Layout>
      )}
    </View>
  )
}
LiveOffers.propTypes = {
  className: PropTypes.string,
  offers: PropTypes.array,
  cid: PropTypes.string,
  isVertical: PropTypes.bool,
  fullScreenVideo: PropTypes.bool,
  isDesktop: PropTypes.bool,

}

const OfferDivider = styled.View`
  margin-top: 40px;
`

const Container = styled.View`
  position: relative;
  z-index: 1;
  max-height: ${props => props.isDesktop ? '100%' : '70%'};
`

const Header = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
`

const HeaderHandleWrapper = styled.View`
  position: absolute;
  top: 6px;
  width: 100%;
  align-items: center;
  z-index: 1;
`

const HeaderHandle = styled.View`
  width: 45px;
  height: 4px;
  border-radius: 2px;
  background-color: ${props => props.visible ? 'rgba(0, 0, 0, 0.59)' : 'rgba(255, 255, 255, 0.59)'};
`

const HeaderWrapper = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
  border-radius: 6px;
`

const HeaderAvatar = styled.View`
  width: 31px;
  height: 31px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
`

const HeaderAvatarImage = styled.Image`
  width: 31px;
  height: 31px;
  border-radius: 4px;
`

const HeaderBody = styled.View`
  flex: 1;
  justify-content: flex-start;
  margin-left: 10px;
`

const HeaderTitle = styled.View`
  flex-direction: row;
  align-items: center;
`

const HeaderAuthor = styled.Text`
  font-size: 17px;
  font-family: Gilroy-Bold;
  color: #ffffff;
`

const HeaderSentAt = styled.Text`
  margin-left: 6px;
  font-size: 13px;
  font-family: Gilroy-Regular;
  color: rgba(255, 255, 255, 0.6);
`

const HeaderContent = styled.View`
  margin-top: 6px;
`

const HeaderMessageText = styled.Text`
  font-size: 15px;
  line-height: 15px;
  font-family: Gilroy-Regular;
  color: rgba(255, 255, 255, 0.7);
`

const JoinButtonWrapper = styled.View`

`

const Content = styled.View`
  flex: 1;
  padding-bottom: 20px;
`

const List = styled.View`
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
`

const Layout = styled.TouchableOpacity`
  position: absolute;
  top: -${props => props.top}px;
  left: 0;
  right: 0;
  bottom: 0;
`

export default styled(React.memo(LiveOffers))`
  flex: 1 1 auto;
  justify-content: flex-end;
  width: 100%;
`
