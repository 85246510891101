import React from 'react'
import PropTypes from 'prop-types'
import { View, Platform } from 'react-native'
import styled from 'styled-components/native'
import isEqual from 'react-fast-compare'
import Avatar from './message/Avatar'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const StickyMessage = props => {
  const { author, data } = props

  return (
    <View style={props.style}>
      <BaseWrapper>
        <Avatar isAdmin={author.admin} avatarUrl={author.avatar_url} />
        <Content>
          <MessageText>{ data.message }</MessageText>
        </Content>
      </BaseWrapper>
    </View>
  )
}
StickyMessage.propTypes = {
  className: PropTypes.string,
  isVertical: PropTypes.bool,
  data: PropTypes.object,
  author: PropTypes.object,
}

const BaseWrapper = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
  padding: 9px;
`

const Content = styled.View`
  flex: 1;
  justify-content: flex-start;
  margin-left: 10px;
`

const MessageText = styled.Text`
  margin-top: 3px;
  font-size: 16px;
  line-height: 15px;
  font-family: Gilroy-Regular;
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.9)' : '#484d60'};
  ${() => mobile ? '' : 'white-space: pre-wrap;'}
`

export default styled(React.memo(StickyMessage, isEqual))`
  margin-top: 10px;
  margin-left: 12px;
  margin-right: 12px;
`
