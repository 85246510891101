import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, Text, ImageBackground, Platform } from 'react-native'
import styled from 'styled-components/native'
import Svg, { Circle, Path } from 'react-native-svg'
import i18n from '../../../services/i18n'
import Tab from './Tab'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const availableTabs = ['chat', 'qa', 'dm']
if (mobile) {
  availableTabs.push('quit')
}

const Tabs = props => {
  const tabsBgActive = require('../../../../assets/tabs-bg-active.png')
  const tabsBg = require('../../../../assets/tabs-bg.png')

  const isActive = tab => {
    if (props.dir === 'column') {
      return props.activeTab.id === tab.id
    } else {
      return props.active && props.activeTab.id === tab.id
    }
  }

  if (props.dir === 'column') {
    return (
      <ColumnTabs>
        {
          props.tabs.map(tab => (
            availableTabs.includes(tab.id) && tab.visible
            ? <Tab key={tab.id} id={tab.id} label={tab.name} active={isActive(tab)} onSelect={props.onSelect} />
            : null
          ))
        }
      </ColumnTabs>
    )
  }

  return (
    <TouchableOpacity style={props.style} onPress={props.onClick} activeOpacity={0.7}>
      <SideActionsLayoutWrapper>
        <StyledImageBackground
          source={props.active ? tabsBgActive : tabsBg}
          imageStyle={{ resizeMode: 'contain' }}
        >
          {
            props.tabs.map(tab => (
              availableTabs.includes(tab.id) && tab.visible
              ? <Tab key={tab.id} id={tab.id} label={tab.name} active={props.active && props.activeTab.id === tab.id} onSelect={props.onSelect} />
              : null
            ))
          }
        </StyledImageBackground>
      </SideActionsLayoutWrapper>
    </TouchableOpacity>
  )
}
Tabs.propTypes = {
}

const StyledImageBackground = styled.ImageBackground`
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
`

const SideActionsLayoutWrapper = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const ColumnTabs = styled.View`
  flex: 0;
  width: 100%;
  flex-direction: row;
`

export default styled(Tabs)`
  width: 100px;
  height: 432px;
  align-items: center;
  justify-content: center;
  align-self: center;
`
