import React, { useState, useEffect, useRef } from 'react'
import { Platform, SafeAreaView, View, Keyboard, KeyboardAvoidingView, ImageBackground } from 'react-native'
import styled from 'styled-components/native'
import Constants from 'expo-constants'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import { LinearGradient } from 'expo-linear-gradient'
import Svg, { G, Polygon, Path } from 'react-native-svg'
import {
  fetchViewerSuccess,
  fetchViewerError,
  removeSession,
  clearMessages,
  clearQuestions,
  clearFiles,
  clearDirectMessages
} from '../actions'
import secrets from '../config/secrets'
import i18n from '../services/i18n'
import getScreenInfo from '../services/screen'
import ConnectPhone from './Connect/ConnectPhone'
import ConnectDesktop from './Connect/ConnectDesktop'
import ConnectSession from './Connect/ConnectSession'

const UUID_REGEX = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/
const SHORT_CID_REGEX = /[0-9a-zA-Z]{6}/

const validateEmail = str => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(str).toLowerCase())
}

const Connect = props => {
  const error = useSelector(state => state.viewer.error)
  const dispatch = useDispatch()
  const [debugging, setDebugging] = useState(false)
  const [token, setToken] = useState('')
  const [errorMessage, setErrorMessage] = useState()
  const [sessions, setSessions] = useState()
  const [finishedSession, setFinishedSession] = useState()
  const tokenRef = React.useRef('')

  console.log('Connect', getScreenInfo.deviceType)

  useEffect(() => {
    Keyboard.addListener("keyboardDidHide", onTokenSubmit)

    return () => {
      Keyboard.removeListener("keyboardDidHide", onTokenSubmit)
    }
  }, [])

  useEffect(() => {
    if (error) {
      setErrorMessage(i18n.t('app.token_not_found'))
    }
  }, [error])

  const onChangeText = val => {
    console.log('val?.toLowerCase()', val?.toLowerCase())
    if (val?.toLowerCase() === 'webbydbg') {
      setDebugging(true)
    } else {
      setDebugging(false)
    }

    setErrorMessage(null)
    setToken(val)
    tokenRef.current = val
  }

  const onTokenSubmit = () => {
    console.log('fetch', token, secrets.domain)
    const currentToken = token || tokenRef.current
    if (currentToken && currentToken.length) {
      dispatch(removeSession())
      dispatch(clearMessages())
      dispatch(clearQuestions())
      dispatch(clearFiles())
      dispatch(clearDirectMessages())
      // dispatch(fetchViewer(currentToken))
      if (currentToken && currentToken.length) {
        if (validateEmail(currentToken)) {
          axios({ url: `${secrets.domain}/meeting/viewers/by_email?email=${currentToken}` })
            .then(response => {
              if (response.data.viewer_sessions.length > 1) {
                setSessions(response.data.viewer_sessions)
              } else {
                // dispatch(fetchViewerSuccess(response.data))

                const session = response.data.viewer_sessions[0]
                if (session.connection_type === 'finished') {
                  setFinishedSession(session)
                } else {
                  onSessionSelect(session)
                }
              }
            })
            .catch(error => {
              // Bugsnag.notify(error)
              setToken(currentToken)
              dispatch(fetchViewerError(error))
              // setLoading(false)
            })
        } else {
          axios({ url: `${secrets.domain}/meeting/viewers/${currentToken}` })
            .then(response => {
              visitor.init(response.data.viewer_session, dispatch, () => {
                dispatch(fetchViewerSuccess(response.data))
              })
            })
            .catch(error => {
              setToken(currentToken)
              dispatch(fetchViewerError(error))
            })
        }
      }
    } else {
      setErrorMessage(i18n.t('app.token_not_exist'))
    }
  }

  const onSessionSelect = session => {
    console.log('session', session)
    switch (session.connection_type) {
      case 'finished': {
        // setLoading(true)
        axios({ url: `${secrets.domain}/meeting/viewers/show_replay?id=${session.cid}` })
          .then(response => {
            console.log('response.data 2', response.data.viewer_session)
            visitor.init(response.data.viewer_session, dispatch, () => {
              dispatch(fetchViewerSuccess(response.data))
            })
            // setLoading(false)
          })
          .catch(error => {
            // Bugsnag.notify(error)
            dispatch(fetchViewerError(error))
            // setLoading(false)
          })

        return
      }
      case 'ongoing': {
        if (!session.is_replay) {
          // setLoading(true)
          if (!session.scheduled_at) {
            axios({ url: `${secrets.domain}/meeting/viewers/show_now?id=${session.cid}` })
              .then(response => {
                console.log('response.data 2', response.data.viewer_session)
                visitor.init(response.data.viewer_session, dispatch, () => {
                  dispatch(fetchViewerSuccess(response.data))
                })
                // setLoading(false)
              })
              .catch(error => {
                // Bugsnag.notify(error)
                dispatch(fetchViewerError(error))
                // setLoading(false)
              })

            return
          } else {
            visitor.init(session, dispatch, () => {
              dispatch(fetchViewerSuccess({ viewer_session: session }))
            })
          }
        }
      }
    }

    visitor.init(response.data.viewer_session, dispatch, () => {
      dispatch(fetchViewerSuccess({ viewer_session: session }))
    })
  }

  const onFinishedSessionSelect = session => {
    onSessionSelect(session)
    setFinishedSession(null)
  }

  const onSessionClick = sessionCid => {
    const session = sessions.find(s => s.cid === sessionCid)
    onSessionSelect(session)
  }

  console.log('CONNECT', debugging, sessions)

  if (sessions && sessions.length) {
    return (
      <SafeAreaView style={props.style}>
        <SessionList contentContainerStyle={{}}>
          <SessionListTitle>We found multiple registrations under your user profile. Please select the one you wish to watch</SessionListTitle>
          <SessionListWrapper>
            {
              sessions.map(session => (
                <ConnectSession
                  key={session.cid}
                  cid={session.cid}
                  hostAvatarUrl={session.host.avatar_cf_url}
                  hostName={session.host.name}
                  webinarName={session.webinar.name}
                  scheduledAt={session.scheduled_at}
                  buttonName={session.button_name}
                  onSelect={onSessionClick}
                />
              ))
            }
          </SessionListWrapper>
        </SessionList>
      </SafeAreaView>
    )
  }

  // const isDesktop = ['tablet', 'desktop'].includes(getScreenInfo.deviceType)
  const isDesktop = ['desktop'].includes(getScreenInfo.deviceType)

  console.log('isDesktop', isDesktop)

  if (isDesktop) {
    return (
      <ConnectDesktop
        onSubmit={onTokenSubmit}
        onChangeText={onChangeText}
        debugging={debugging}
        token={token}
        errorMessage={errorMessage}
        behavior={Platform.OS === 'ios' ? 'position' : null}
      />
    )
  } else {
    return (
      <ConnectPhone
        onSubmit={onTokenSubmit}
        onChangeText={onChangeText}
        debugging={debugging}
        token={token}
        errorMessage={errorMessage}
        behavior={Platform.OS === 'ios' ? 'position' : null}
      />
    )
  }
}

const SessionList = styled.ScrollView`
  flex: 1 1 auto;
`

const SessionListWrapper = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
`

const SessionListTitle = styled.Text`
  flex: 0 0 auto;
  font-size: 22px;
  margin-bottom: 10px;
  margin: 15px;
`

export default styled(Connect)`

`
