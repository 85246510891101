import React from 'react'
import PropTypes from 'prop-types'
import { Text, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'

const Tab = props => {
  const onPress = () => {
    props.onTabSelect(props.id)
  }

  if (props.hidden) return null

  return (
    <TouchableOpacity style={props.style} onPress={onPress}>
      <TabText active={props.activeTab === props.id ? 'true' : 'false'}>{ props.name }</TabText>
    </TouchableOpacity>
  )
}

Tab.propTypes = {
  session: PropTypes.object
}

const TabText = styled(Text)`
  font-size: 18px;
  font-family: Gilroy-Regular;
  color: ${props => props.theme.darkScheme ? (props => props.active === 'true' ? '#ffffff' : 'rgba(255, 255, 255, 0.5)') : (props => props.active === 'true' ? '#131d40' : '#757f99')};
`

export default styled(Tab)`
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 25px;
  padding-right: 25px;
`
