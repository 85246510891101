import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Platform, View, ScrollView, Linking } from 'react-native'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'
import Svg, { G, Polygon, Path } from 'react-native-svg'
import Avatar from '../../Chat/message/Avatar'

const Profile = props => {
  const triggerRef = useRef()
  const [rect, setRect] = useState({ top: 40, right: -10 })
  const [showPopover, setShowPopover] = useState(false)
  const hosts = useSelector(state => state.hosts.data)
  const showHosts = hosts.filter(host => host.avatar_url && host.avatar_url.length)

  const toggleTrigger = () => {
    setShowPopover(prev => !prev)
  }

  const openLink = url => {
    if (Platform.OS == 'web') {
      window.open(url, '_blank')
    } else {
      Linking.openURL(url)
    }
  }

  if (!showHosts.length) return null

  return (
    <View style={props.style}>
      <Item onPress={toggleTrigger} ref={triggerRef}>
        {showHosts.map((host, i) => (
          <ProfileImage
            style={{ zIndex: showHosts.length - i }}
            key={host.id}
            source={{ uri: host.avatar_url }}
            isActive={showPopover}
          />
        ))}
      </Item>
      <Popover
        placement="bottom"
        debug
        top={rect.top}
        right={rect.right}
        isVisible={showPopover}
        onRequestClose={() => setShowPopover(false)}
      >
        <Content>
          <ScrollView>
            {
              hosts.map(h => (
                <React.Fragment key={ h.id }>
                  <Attendee>
                    <Avatar isAdmin={false} avatarUrl={h.avatar_url} size="xlarge" borderRadius={12} />
                    <Body>
                      <Name>{ h.first_name } { h.last_name }</Name>
                      <Bio>{ h.bio }</Bio>
                      <SocialLinks>
                        {
                          h.facebook && h.facebook.length
                          ? <Link onPress={ () => openLink(h.facebook) }><FacebookIcon /></Link>
                          : null
                        }
                        {
                          h.twitter && h.twitter.length
                          ? <Link onPress={ () => openLink(h.twitter) }><TwitterIcon /></Link>
                          : null
                        }
                        {
                          h.instagram && h.instagram.length
                          ? <Link onPress={ () => openLink(h.instagram) }><InstagramIcon /></Link>
                          : null
                        }
                        {
                          h.vimeo && h.vimeo.length
                          ? <Link onPress={ () => openLink(h.vimeo) }><VimeoIcon /></Link>
                          : null
                        }
                      </SocialLinks>
                    </Body>
                  </Attendee>
                  <Divider />
                </React.Fragment>
              ))
            }
          </ScrollView>
        </Content>
      </Popover>
    </View>
  )
}
Profile.propTypes = {
}

const Item = styled.TouchableOpacity`
  height: 32px;
  border-radius: 16px;
  margin-left: 30px;
  flex-direction: row;
  align-items: center;
`

const Label = styled.Text`
  margin-left: 8px;
`

const LabelText = styled.Text`
  font-size: 14px;
  color: #272C5E;
`

const Bubble = styled.View`
  margin-left: 8px;
  background-color: #FF805C;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`

const DownloadsCount = styled.Text`
  color: #ffffff;
  font-size: 12px;
`

const Content = styled.View`
  background-color: #ffffff;
  box-shadow: 0px 7px 10px rgba(39, 44, 94, 0.05);
  border-radius: 18px;
  padding: 10px;
  width: 300px;
`

const Title = styled.Text`
  font-size: 14px;
  line-height: 16px;
`

const ProfileImage = styled.Image`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: transparent;
  border-width: 2px;
  border-color: ${props => props.isActive ? '#ff7e5c' : '#e0e4f3'};
  margin-left: -10px;
`

const Popover = styled.View`
  position: absolute;
  top: ${props => props.top}px;
  right: ${props => props.right}px;
  opacity: ${props => props.isVisible ? 1 : 0};
  display: ${props => props.isVisible ? 'flex' : 'none'};
  background: #FFFFFF;
  box-shadow: 0px 7px 10px rgba(39, 44, 94, 0.05);
  border-radius: 8px;
`

const FacebookIcon = () => (
  <Svg width="20px" height="20px" viewBox="0 0 20 20">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.3">
          <G transform="translate(-26.000000, -28.000000)" fill="#FFFFFF" fillRule="nonzero">
              <G transform="translate(26.000000, 28.000000)">
                  <Path d="M14.984127,0 L5,0 C2.25396825,0 0,2.25396825 0,5.01587302 L0,15 C0,17.7460317 2.25396825,20 5,20 L14.984127,20 C17.7460317,20 20,17.7460317 20,14.984127 L20,5.01587302 C20,2.25396825 17.7460317,0 14.984127,0 Z M12.6825397,10 L10.7936508,10 L10.7936508,16.1904762 L8.41269841,16.1904762 L8.41269841,10 L7.14285714,10 L7.14285714,7.46031746 L8.25396825,7.46031746 L8.25396825,6.38095238 C8.25396825,5.36507937 8.76190476,3.76190476 10.9047619,3.76190476 L12.8571429,3.76190476 L12.8571429,5.87301587 L11.4761905,5.87301587 C11.2539683,5.87301587 10.952381,6.01587302 10.952381,6.50793651 L10.952381,7.46031746 L12.9047619,7.46031746 L12.6825397,10 Z"></Path>
              </G>
          </G>
      </G>
  </Svg>
)

const TwitterIcon = () => (
  <Svg width="20px" height="20px" viewBox="0 0 20 20">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.3">
          <G transform="translate(-56.000000, -28.000000)" fill="#FFFFFF" fillRule="nonzero">
              <G transform="translate(56.000000, 28.000000)">
                  <Path d="M14.9544554,0.00792079208 L5.00594059,0.00792079208 C2.24950495,0.00792079208 0,2.25742574 0,5.01386139 L0,14.9782178 C0,17.7188119 2.24950495,19.9683168 5.00594059,19.9683168 L14.970297,19.9683168 C17.7267327,19.9683168 19.9762376,17.7188119 19.9762376,14.9623762 L19.9762376,5.01386139 C19.960396,2.25742574 17.7108911,0.00792079208 14.9544554,0.00792079208 Z M14.9386139,7.48514851 C14.9386139,7.5960396 14.9386139,7.70693069 14.9386139,7.81782178 C14.9386139,11.1445545 12.3722772,14.9782178 7.66732673,14.9782178 C6.22574257,14.9782178 4.87920792,14.5663366 3.75445545,13.8534653 C3.96039604,13.8693069 4.15049505,13.8851485 4.35643564,13.8851485 C5.56039604,13.8851485 6.65346535,13.4891089 7.52475248,12.8079208 C6.4,12.7920792 5.46534653,12.0633663 5.13267327,11.0653465 C5.29108911,11.0970297 5.44950495,11.1128713 5.60792079,11.1128713 C5.84554455,11.1128713 6.08316832,11.0811881 6.28910891,11.0178218 C5.14851485,10.780198 4.27722772,9.76633663 4.27722772,8.54653465 L4.27722772,8.51485149 C4.59405941,8.7049505 5.00594059,8.81584158 5.41782178,8.83168317 C4.73663366,8.38811881 4.27722772,7.61188119 4.27722772,6.74059406 C4.27722772,6.28118812 4.4039604,5.85346535 4.62574257,5.47326733 C5.89306931,6.99405941 7.76237624,7.99207921 9.88514851,8.1029703 C9.83762376,7.91287129 9.82178218,7.72277228 9.82178218,7.53267327 C9.82178218,6.13861386 10.9623762,5.01386139 12.3722772,5.01386139 C13.1009901,5.01386139 13.7663366,5.31485149 14.2415842,5.80594059 C14.8277228,5.6950495 15.3663366,5.48910891 15.8574257,5.18811881 C15.6673267,5.77425743 15.2554455,6.26534653 14.7326733,6.58217822 C15.2554455,6.51881188 15.7465347,6.39207921 16.2059406,6.18613861 C15.8574257,6.69306931 15.429703,7.13663366 14.9386139,7.48514851 Z"></Path>
              </G>
          </G>
      </G>
  </Svg>
)

const InstagramIcon = () => (
  <Svg width="20px" height="20px" viewBox="0 0 20 20">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.3">
          <G transform="translate(-86.000000, -28.000000)" fill="#FFFFFF" fillRule="nonzero">
              <G transform="translate(86.000000, 28.000000)">
                  <Path d="M11.7544554,8.7049505 C11.3584158,8.15049505 10.7089109,7.78613861 9.98019802,7.78613861 C9.25148515,7.78613861 8.6019802,8.15049505 8.20594059,8.7049505 C7.95247525,9.06930693 7.79405941,9.51287129 7.79405941,9.98811881 C7.79405941,11.1920792 8.77623762,12.1742574 9.98019802,12.1742574 C11.1841584,12.1742574 12.1663366,11.1920792 12.1663366,9.98811881 C12.1663366,9.51287129 12.0079208,9.06930693 11.7544554,8.7049505 Z"></Path>
                  <Path d="M13.1326733,8.72079208 C13.2910891,9.03762376 13.3861386,9.54455446 13.3861386,9.98811881 C13.3861386,11.8574257 11.8653465,13.3940594 9.98019802,13.3940594 C8.0950495,13.3940594 6.57425743,11.8732673 6.57425743,9.98811881 C6.57425743,9.52871287 6.66930693,9.03762376 6.82772277,8.72079208 L4.91089109,8.72079208 L4.91089109,13.8059406 C4.91089109,14.4712871 5.4970297,15.0574257 6.16237624,15.0574257 L13.7980198,15.0574257 C14.4633663,15.0574257 15.049505,14.4712871 15.049505,13.8059406 L15.049505,8.72079208 L13.1326733,8.72079208 Z"></Path>
                  <Polygon points="14.4792079 5.2039604 12.6415842 5.2039604 12.6415842 7.31089109 14.7326733 7.31089109 14.7326733 5.47326733 14.7326733 5.18811881"></Polygon>
                  <Path d="M14.9544554,0.00792079208 L5.00594059,0.00792079208 C2.24950495,0.00792079208 0,2.25742574 0,5.01386139 L0,14.9782178 C0,17.7188119 2.24950495,19.9683168 5.00594059,19.9683168 L14.970297,19.9683168 C17.7267327,19.9683168 19.9762376,17.7188119 19.9762376,14.9623762 L19.9762376,5.01386139 C19.960396,2.25742574 17.7108911,0.00792079208 14.9544554,0.00792079208 Z M16.1584158,8.7049505 L16.1584158,8.7049505 L16.1584158,13.8059406 C16.1584158,15.1366337 15.1287129,16.1663366 13.7980198,16.1663366 L6.16237624,16.1663366 C4.83168317,16.1663366 3.8019802,15.1366337 3.8019802,13.8059406 L3.8019802,8.7049505 L3.8019802,6.17029703 C3.8019802,4.83960396 4.83168317,3.80990099 6.16237624,3.80990099 L13.7980198,3.80990099 C15.1287129,3.80990099 16.1584158,4.83960396 16.1584158,6.17029703 L16.1584158,8.7049505 Z"></Path>
              </G>
          </G>
      </G>
  </Svg>
)

const VimeoIcon = () => (
  <Svg width="20px" height="20px" viewBox="0 0 20 20">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.3">
          <G transform="translate(-116.000000, -28.000000)" fill="#FFFFFF" fillRule="nonzero">
              <G transform="translate(116.000000, 28.000000)">
                  <Path d="M14.9544554,0.00792079208 L5.00594059,0.00792079208 C2.24950495,0.00792079208 0,2.25742574 0,5.01386139 L0,14.9782178 C0,17.7188119 2.24950495,19.9683168 5.00594059,19.9683168 L14.970297,19.9683168 C17.7267327,19.9683168 19.9762376,17.7188119 19.9762376,14.9623762 L19.9762376,5.01386139 C19.960396,2.25742574 17.7108911,0.00792079208 14.9544554,0.00792079208 Z M15.7306931,7.18415842 C15.0336634,11.0811881 11.1524752,14.3762376 9.98019802,15.1207921 C8.80792079,15.8811881 7.74653465,14.819802 7.35049505,14.0277228 C6.90693069,13.1247525 5.57623762,8.18217822 5.22772277,7.78613861 C4.87920792,7.37425743 3.83366337,8.1980198 3.83366337,8.1980198 L3.32673267,7.53267327 C3.32673267,7.53267327 5.44950495,5.01386139 7.04950495,4.71287129 C8.77623762,4.36435644 8.77623762,7.31089109 9.18811881,8.94257426 C9.58415842,10.5267327 9.85346535,11.429703 10.2019802,11.429703 C10.550495,11.429703 11.2158416,10.5425743 11.9445545,9.1960396 C12.6732673,7.84950495 11.9128713,6.64554455 10.4871287,7.5009901 C11.0574257,4.11089109 16.4277228,3.28712871 15.7306931,7.18415842 Z"></Path>
              </G>
          </G>
      </G>
  </Svg>
)

const Link = styled.TouchableOpacity`
  margin-right: 10px;
  background: ${props => props.theme.darkScheme ? 'transparent' : 'rgba(0, 0, 0, 0.5)'};
  border-radius: ${props => props.theme.darkScheme ? 0 : 4}px;
`

const Attendee = styled.View`
  flex-direction: row;
`

const AvatarInitial = styled.Text`
  font-size: 24px;
  font-family: Gilroy-Medium;
  text-transform: uppercase;
  color: rgb(${props => props.color});
`

const Body = styled.View`
  flex: 1;
  justify-content: center;
  margin-left: 10px;
`

const Name = styled.Text`
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
  font-family: Gilroy-SemiBold;
  font-size: 16px;
`

const Bio = styled.Text`
  margin-top: 2px;
  font-family: Gilroy-Regular;
  font-size: 13px;
  line-height: 18px;
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.7)' : 'rgba(19, 29, 64, 0.7)'};
`

const SocialLinks = styled.View`
  margin-top: 11px;
  flex-direction: row;
`

const Divider = styled.View`
  height: 1px;
  background-color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};
  margin: 10px 0;
`

export default Profile
