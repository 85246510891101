import AsyncStorage from '@react-native-async-storage/async-storage'

const storage = {
  async get(key) {
    try {
      const res = await AsyncStorage.getItem(this._getKey(key))
      const data = JSON.parse(res)
      if (data !== null && data.expireAt) {
        if (new Date(data.expireAt) > (new Date())) {
          return data.value
        }

        await AsyncStorage.removeItem(this._getKey(key))
        return null
      }
    } catch (error) {
      console.log('storage get ERROR', error)
    }
  },
  async set(key, value, expireSec) {
    try {
      const payload = {
        value,
        expireAt: this._getExpireAt(expireSec)
      }
      const objToStore = JSON.stringify(payload)
      return await AsyncStorage.setItem(this._getKey(key), objToStore)
    } catch (error) {
      console.log('storage set ERROR', error)
    }
  },
  async remove(key) {
    try {
      return await AsyncStorage.removeItem(this._getKey(key))
    } catch (error) {
      console.log('storage remove ERROR', error)
    }
  },
  _getExpireAt(seconds) {
    const now = new Date()
    const expireTime = new Date(now)
    expireTime.setSeconds(now.getSeconds() + seconds)
    return expireTime
  },
  _getKey(key) {
    return `@Webby:${key}`
  }
}

export default storage
