import React from 'react'
import PropTypes from 'prop-types'
import { View, Image } from 'react-native'
import styled from 'styled-components/native'
import Svg, { G, Rect, Path } from 'react-native-svg'

const mapSizes = {
  small: 25,
  normal: 35,
  large: 40,
  xlarge: 50
}

const Avatar = props => {
  const size = props.size || 'normal'

  return (
    <View style={props.style}>
      { props.isAdmin ? <IconWrapper><AdminIcon /></IconWrapper> : null }
      { props.avatarUrl && <AvatarImage size={size} source={{ uri: props.avatarUrl }} /> }
    </View>
  )
}
Avatar.propTypes = {
  style: PropTypes.array,
  isAdmin: PropTypes.bool,
  avatarUrl: PropTypes.string
}

const AdminIcon = () => (
  <Svg width="13px" height="13px" viewBox="0 0 16 16">
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G transform="translate(-1015.000000, -177.000000)">
        <G transform="translate(1015.000000, 177.000000)">
          <G>
            <Rect stroke="#FFFFFF" fill="#4853ba" x="0.5" y="0.5" width="15" height="15" rx="7.5"></Rect>
            <Path d="M6.32119205,11.0059603 L6.80248344,9.44884106 L8.89751656,9.44884106 L9.37880795,11.0059603 L10.7,11.0059603 L8.4634106,4.4 L7.2365894,4.4 L5,11.0059603 L6.32119205,11.0059603 Z M7.85,6.02317881 L8.54834437,8.31639073 L7.15165563,8.31639073 L7.85,6.02317881 Z" fill="#FFFFFF" fillRule="nonzero"></Path>
          </G>
        </G>
      </G>
    </G>
  </Svg>
)

const AvatarImage = styled.Image`
  width: ${props => mapSizes[props.size]}px;
  height: ${props => mapSizes[props.size]}px;
  border-radius: 4px;
`

const IconWrapper = styled.View`
  position: absolute;
  top: -6px;
  left: -6px;
  border-radius: 7px;
  z-index: 1;
`

export default styled(Avatar)`
  width: ${props => mapSizes[props.size || 'normal']}px;
  height: ${props => mapSizes[props.size || 'normal']}px;
  border-radius: ${props => props.borderRadius || 4}px;
  position: relative;
`
