import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Svg, { Circle, Path } from 'react-native-svg'
import i18n from '../../../services/i18n'

const Tab = props => {
  const onSelect = tab => {
    props.onSelect(tab)
  }

  const getIcon = () => {
    switch (props.id) {
      case 'chat': return ChatIcon
      case 'qa': return QAIcon
      case 'dm': return DMIcon
    }
  }

  const Icon = getIcon()

  return (
    <TouchableOpacity style={props.style} onPress={() => onSelect(props.id)} active={props.active}>
      { Icon && <Icon active={props.active} /> }
      <TabLabel active={props.active}>{ props.label }</TabLabel>
    </TouchableOpacity>
  )
}
Tab.propTypes = {
}

const TabLabel = styled.Text`
  margin-top: 8px;
  font-size: 8px;
  font-family: Gilroy-SemiBold;
  line-height: 9px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${props => props.active ? '#ffffff' : '#272C5E'};
`

const ChatIcon = ({ active }) => {
  const color = active ? '#ffffff' : '#272C5E'

  return (
    <Svg width="26" height="23" viewBox="0 0 26 23" fill="none">
      <Path d="M13.3726 4.54541C13.1233 4.54541 12.9209 4.74907 12.9209 4.99996C12.9209 5.25084 13.1233 5.4545 13.3726 5.4545C13.622 5.4545 13.8243 5.25084 13.8243 4.99996C13.8243 4.74907 13.622 4.54541 13.3726 4.54541Z" fill={color}/>
      <Path d="M4.33746 13.6364C4.58679 13.6364 4.78919 13.4327 4.78919 13.1818C4.78919 12.931 4.58679 12.7273 4.33746 12.7273C4.08813 12.7273 3.88574 12.931 3.88574 13.1818C3.88574 13.4327 4.08813 13.6364 4.33746 13.6364Z" fill={color}/>
      <Path d="M13.3292 12.7273H7.09172C6.81829 12.7273 6.59668 12.9308 6.59668 13.1818C6.59668 13.4329 6.81829 13.6364 7.09172 13.6364H13.3292C13.6026 13.6364 13.8242 13.4329 13.8242 13.1818C13.8242 12.9308 13.6026 12.7273 13.3292 12.7273Z" fill={color}/>
      <Path d="M12.4345 15.4546H4.37146C4.10318 15.4546 3.88574 15.6581 3.88574 15.9091C3.88574 16.1602 4.10318 16.3637 4.37146 16.3637H12.4345C12.7027 16.3637 12.9202 16.1602 12.9202 15.9091C12.9202 15.6581 12.7027 15.4546 12.4345 15.4546Z" fill={color}/>
      <Path d="M21.4601 4.54541H15.2226C14.9491 4.54541 14.7275 4.74889 14.7275 4.99996C14.7275 5.25102 14.9491 5.4545 15.2226 5.4545H21.4601C21.7335 5.4545 21.9551 5.25102 21.9551 4.99996C21.9551 4.74889 21.7335 4.54541 21.4601 4.54541Z" fill={color}/>
      <Path d="M20.7569 7.75208H13.0103C12.6707 7.75208 12.3955 7.95556 12.3955 8.20662C12.3955 8.45769 12.6707 8.66117 13.0103 8.66117H20.7569C21.0964 8.66117 21.3717 8.45769 21.3717 8.20662C21.3717 7.95556 21.0964 7.75208 20.7569 7.75208Z" fill={color}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M16.8733 0C12.4253 0 8.77454 3.06671 8.49218 6.92954C4.2561 7.14203 0.272461 10.1966 0.272461 14.3281C0.272461 16.0546 0.955669 17.7275 2.19934 19.053C2.44541 20.0674 2.14665 21.1426 1.40535 21.8839C1.26408 22.0252 1.22181 22.2376 1.29824 22.4223C1.37466 22.6068 1.55473 22.7273 1.75467 22.7273C3.16374 22.7273 4.52167 22.1747 5.53065 21.2011C6.51358 21.5361 7.79604 21.7391 8.96811 21.7391C13.4155 21.7391 17.0659 18.6732 17.349 14.8111C18.3834 14.7652 19.4594 14.5741 20.3107 14.2841C21.3197 15.2577 22.6776 15.8103 24.0867 15.8103C24.2866 15.8103 24.4667 15.6898 24.5431 15.5053C24.6196 15.3206 24.5773 15.1082 24.436 14.9669C23.6947 14.2256 23.396 13.1504 23.642 12.136C24.8857 10.8105 25.5689 9.13761 25.5689 7.41107C25.5689 3.11824 21.2784 0 16.8733 0ZM8.96785 20.7507C7.80177 20.7507 6.47299 20.5218 5.58289 20.1676C5.39221 20.0916 5.17431 20.1416 5.03555 20.2931C4.439 20.9452 3.66334 21.3999 2.81821 21.6089C3.28391 20.7167 3.4028 19.6649 3.11948 18.6682C3.09709 18.5897 3.0556 18.5179 2.99866 18.4594C1.87774 17.3049 1.26034 15.8376 1.26034 14.3278C1.26034 10.8461 4.78986 7.90483 8.96785 7.90483C12.9131 7.90483 16.3789 10.6483 16.3789 14.3278C16.3789 17.8694 13.0544 20.7507 8.96785 20.7507ZM22.7213 11.7506C22.7437 11.6723 22.7852 11.6005 22.8422 11.5418C23.9631 10.3873 24.5805 8.91995 24.5805 7.41014C24.5805 3.92848 21.051 0.987216 16.873 0.987216C12.9727 0.987216 9.76645 3.61216 9.48256 6.93054C10.7787 7.00041 12.038 7.33333 13.1595 7.90421L14.6774 8.89235C16.2708 10.1962 17.2028 11.9523 17.3472 13.8207C18.3861 13.7694 19.4865 13.5569 20.2579 13.25C20.4486 13.174 20.6665 13.2242 20.8053 13.3755C21.4018 14.0276 22.1775 14.4825 23.0226 14.6913C22.5569 13.7991 22.438 12.7473 22.7213 11.7506Z" fill={color}/>
    </Svg>
  )
}

const QAIcon = ({ active }) => {
  const color = active ? '#ffffff' : '#272C5E'

  return (
    <Svg width="16" height="24" viewBox="0 0 16 24" fill="none">
      <Path d="M5.86914 12.6909L7.90586 8.8563H5.86914L10.9196 4.27344L8.72016 7.92102H10.7569L5.86914 12.6909Z" fill={color}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M15.9699 8.48164C15.9699 4.30456 12.5712 0.905884 8.39412 0.905884C4.21703 0.905884 0.818359 4.30456 0.818359 8.48164C0.818359 11.3869 2.48076 14.0261 5.07972 15.2918V17.9513C4.55747 17.9513 4.13275 18.3761 4.13275 18.8983V19.8453C4.13275 20.3675 4.55747 20.7922 5.07972 20.7922H5.55321V21.2657C5.55321 21.788 5.97792 22.2127 6.50018 22.2127H6.97366V22.6862C6.97366 23.2084 7.39838 23.6332 7.92063 23.6332H8.8676C9.38986 23.6332 9.81457 23.2084 9.81457 22.6862V22.2127H10.2881C10.8103 22.2127 11.235 21.788 11.235 21.2657V20.7922H11.7085C12.2308 20.7922 12.6555 20.3675 12.6555 19.8453V18.8983C12.6555 18.3761 12.2308 17.9513 11.7085 17.9513V15.2918C14.3075 14.0261 15.9699 11.3869 15.9699 8.48164ZM7.92063 22.6862V22.2127H8.8676V22.6862H7.92063ZM6.50018 20.7922V21.2657H10.2881V20.7922H6.50018ZM11.7071 19.8465H5.07972V18.8995H11.7085L11.7071 19.8465ZM10.7614 14.6777L11.0455 14.5536C13.4612 13.4968 15.0228 11.1128 15.0228 8.47927C15.0228 4.82397 12.0493 1.85049 8.39397 1.85049C4.73867 1.85049 1.76518 4.82397 1.76518 8.47927C1.76518 11.1123 3.32626 13.4968 5.74245 14.5531L6.02654 14.6772V17.9494H7.92048V15.1085H8.86745V17.9494H10.7614V14.6777Z" fill={color}/>
    </Svg>
  )
}

const DMIcon = ({ active }) => {
  const color = active ? '#ffffff' : '#272C5E'

  return (
    <Svg width="23" height="23" viewBox="0 0 23 23" fill="none">
      <Circle cx="17.5886" cy="15.4235" r="0.94697" fill={color}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M15.625 21.1068C15.8859 21.1068 16.0985 20.8947 16.0985 20.6333V18.7393H17.0455V20.6333C17.0455 21.4164 16.4081 22.0537 15.625 22.0537H1.42045C0.637311 22.0537 0 21.4164 0 20.6333L0 10.6901C0 9.90696 0.637311 9.26965 1.42045 9.26965H2.84091V8.08594C2.84091 7.69437 3.15956 7.37571 3.55114 7.37571H4.26136V4.5348C4.26136 2.1849 6.1733 0.273438 8.52273 0.273438C10.8722 0.273438 12.7841 2.1849 12.7841 4.5348V7.37571H13.4943C13.8859 7.37571 14.2045 7.69437 14.2045 8.08594V9.26965H15.625C16.4081 9.26965 17.0455 9.90696 17.0455 10.6901V12.584H16.0985V10.6901C16.0985 10.4287 15.8859 10.2166 15.625 10.2166H1.42045C1.15956 10.2166 0.94697 10.4287 0.94697 10.6901V20.6333C0.94697 20.8947 1.15956 21.1068 1.42045 21.1068H15.625ZM11.3636 9.27119H13.2575V8.32422H11.3636V9.27119ZM5.20814 4.53705C5.20814 2.70987 6.69488 1.22266 8.52253 1.22266C10.3502 1.22266 11.8369 2.70987 11.8369 4.53705V7.37796H11.1267C10.7351 7.37796 10.4165 7.69661 10.4165 8.08819V9.2719H6.6286V8.08819C6.6286 7.69661 6.30994 7.37796 5.91837 7.37796H5.20814V4.53705ZM5.68163 8.32422H3.78769V9.27119H5.68163V8.32422Z" fill={color}/>
      <Path d="M2.36719 12.3469V14.4776H3.31416V12.5837H5.2081V11.6367H3.07741C2.68584 11.6367 2.36719 11.9554 2.36719 12.3469Z" fill={color}/>
      <Path fillRule="evenodd" clipRule="evenodd" d="M17.0884 14.9495C17.3152 13.6081 18.4819 12.582 19.8867 12.582C21.4535 12.582 22.7276 13.8562 22.7276 15.4229C22.7276 16.9897 21.4535 18.2638 19.8867 18.2638C18.4814 18.2638 17.3147 17.2378 17.0884 15.8964H13.2579V16.8434H12.3109V15.8964H11.364V17.7904H10.417V14.9495H17.0884ZM17.9922 15.4252C17.9922 16.4697 18.8417 17.3191 19.8862 17.3191C20.9307 17.3191 21.7801 16.4697 21.7801 15.4252C21.7801 14.3807 20.9307 13.5312 19.8862 13.5312C18.8417 13.5312 17.9922 14.3807 17.9922 15.4252Z" fill={color}/>
    </Svg>
  )
}

export default styled(Tab)`
  box-shadow: 0px 5px 18px rgba(70, 60, 108, 0.09);
  border-radius: 16px;
  background: ${props => props.active ? '#272C5E' : '#FFFFFF'};
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  margin: 15px;
`
