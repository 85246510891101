const initialState = {
  data: [],
  loading: false,
  error: null
}

const messages = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return {
        ...state,
        data: [...state.data, action.message]
      }

    case 'ADD_MESSAGES':
      return {
        ...state,
        data: [...state.data, ...action.messages]
      }

    case 'UPDATE_MESSAGE':
      return {
        ...state,
        data: state.data.map(m => m.id === action.message.id ? action.message : m)
      }

    case 'UPDATE_MESSAGE_ANSWER':
      return {
        ...state,
        data: state.data.map(m => (
          m.id === action.question.id
          ? {
              ...m,
              data: {
                ...m.data,
                answer: action.question.answer,
              },
              answered_by: action.question.answered_by
            }
          : m
        ))
      }

    case 'REMOVE_MESSAGE':
      return {
        ...state,
        data: state.data.filter(m => m.id !== action.id)
      }

    case 'CLEAR_MESSAGES':
      if (state.data.length) {
        return {
          ...state,
          data: []
        }
      }
      return state

    default:
      return state
  }
}

export default messages
