import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { SafeAreaView, View, ScrollView } from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import { BlurView } from 'expo-blur'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import moment from 'moment-timezone'
import { WebinarContext } from './Context'
import File from '../Chat/message/File'
import Avatar from '../Chat/message/Avatar'
import { selectFiles } from '../../selectors'
import i18n from '../../services/i18n'

const timeZone = moment.tz.guess()
const getHumanCreatedAt = val => moment(val).tz(timeZone).format('LT')

const Files = props => {
  const files = useSelector(selectFiles)

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={props.style}>
        <Title>{ i18n.t('tabs.files') }</Title>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          {
            files.map(f => (
              <FileContainer key={f.id}>
                <Wrapper>
                  <Avatar isAdmin={!f.author.cid} avatarUrl={f.author.avatar_url} size="large" />
                  <Body>
                    <FileTitle>
                      <Author>{ f.author.name }</Author>
                      <SentAt>{ getHumanCreatedAt(f.created_at) }</SentAt>
                    </FileTitle>
                    <File data={f} />
                  </Body>
                </Wrapper>
              </FileContainer>
            ))
          }
        </ScrollView>
      </View>
    </SafeAreaView>
  )
}
Files.propTypes = {
}

const Title = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 28px;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
  margin-bottom: 20px;
`

const Body = styled.View`
  flex: 1;
  justify-content: flex-start;
  margin-left: 10px;
`

const FileTitle = styled.View`
  flex-direction: row;
  align-items: center;
`

const FileContainer = styled.View`
  flex: 1;
  margin-top: 15px;
`

const Wrapper = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
  border-radius: 6px;
  padding-left: 11px;
  padding-right: 11px;
`

const Author = styled.Text`
  font-size: 18px;
  font-family: Gilroy-SemiBold;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
`

const SentAt = styled.Text`
  margin-left: 9px;
  font-size: 14px;
  font-family: Gilroy-Regular;
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.3)' : '#9ea4b4'};
`

export default styled(React.memo(Files))`
  flex: 1;
  justify-content: flex-start;
  padding-top: ${hp(1)}px;
  padding-bottom: ${hp(2.5)}px;
  padding-left: ${wp(5.3)}px;
  padding-right: ${wp(5.3)}px;
`
