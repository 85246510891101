import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Platform, SafeAreaView, View, ScrollView } from 'react-native'
import styled from 'styled-components/native'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import secrets from '../../config/secrets'
import Avatar from '../Chat/message/Avatar'
import BodyHtml from './BodyHtml'
import { addDirectMessage } from '../../actions'
import i18n from '../../services/i18n'

const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig

const DM = props => {
  const dispatch = useDispatch()
  const { cid } = useSelector(state => state.viewer.data)
  const messages = useSelector(state => state.direct_messages.data)
  const [text, setText] = useState('')

  const handleChange = val => {
    setText(val)
  }

  const handleSubmit = () => {
    axios({
      url: `${secrets.domain}/meeting/direct_messages`,
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        cid,
        message: {
          message: text
        }
      }
    })
    .then(response => {
      dispatch(addDirectMessage(response.data.message))
    })
    .catch(error => {
      console.log('error', error)
    })

    setText('')
  }

  const linkify = text => {
    return text.replace(URL_REGEX, url => {
      const styles = "color: #ff805c; cursor: pointer; text-decoration: none;"

      return `<a target="_blank" href="${url}" style="${styles}">${url}</a>`
    })
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={props.style}>
        <Title>{ i18n.t('tabs.dm') }</Title>
        <ScrollView style={{ paddingLeft: 8 }}>
          {
            messages.map(m => (
              <Message key={ m.id }>
                <Avatar isAdmin={!m.author.cid} avatarUrl={m.author.avatar_url} size="large" borderRadius={12} />
                <Body>
                  <Name>{ m.author.name }</Name>
                  <BodyHtml text={m.message} />
                </Body>
              </Message>
            ))
          }
        </ScrollView>
        <StyledInput
          placeholder={'Type your reply'}
          placeholderTextColor="#8b8f99"
          value={text}
          onChangeText={handleChange}
          onSubmitEditing={handleSubmit}
        />
      </View>
    </SafeAreaView>
  )
}
DM.propTypes = {
}


const Title = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 28px;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
  margin-bottom: 20px;
`

const Message = styled.View`
  flex-direction: row;
  margin-top: 12px;
`

const Body = styled.View`
  flex: 1;
  justify-content: center;
  margin-left: ${wp(2.4)}px;
`

const Name = styled.Text`
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
  font-family: Gilroy-SemiBold;
  font-size: 18px;
`

const StyledInput = styled.TextInput`
  height: 45px;
  background-color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.11)' : '#f2f2fa'};
  border-radius: 25px;
  padding-right: 50px;
  padding-left: 15px;
  font-size: 17px;
  border: none;
  font-family: Gilroy-Regular;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#757f99'};
`

export default styled(React.memo(DM))`
  flex: 1;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
`
