import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Animated, View } from 'react-native'
import styled from 'styled-components/native'
import throttle from 'lodash/throttle'
import moment from 'moment-timezone'
import '@expo/vector-icons'
import Svg, { Path } from 'react-native-svg'
import { LinearGradient } from 'expo-linear-gradient'
import Controls from '../Controls'
import visitor from '../../../services/visitor'
import { EventEmitter } from '../../../services/events'
import i18n from '../../../services/i18n'
import useDimensionsChange from '../useDimensionsChange'
import Closeout from '../Closeout/Closeout'

const videoInit = (el, config, onReady) => {
  el.src = config.src
  el.controls = config.controls
  el.autoplay = config.autoplay
  el.muted = config.muted

  const api = {
    el: el,
    playAllowed: false,
    on: (event, callback) => {
      el.addEventListener(event, callback)
    },
    muted: flag => {
      el.muted = flag
    },
    play: () => {
      el.play()
    },
    pause: () => {
      el.pause()
    },
    currentTime: time => {
      if (time && time > 0) {
        return el.currentTime = time
      } else {
        return el.currentTime
      }
    },
    togglePlay: () => {
      if (el.paused) {
        el.play()
      } else {
        el.pause()
      }
    },
    removeControls: () => {
      if (el.hasAttribute("controls")) {
        el.removeAttribute("controls")
      }
    },
    toggleControls: () => {
      if (el.hasAttribute("controls")) {
        el.removeAttribute("controls")
      } else {
        el.setAttribute("controls","controls")
      }
    },
    dispose: () => {
      el.setAttribute('src', '')
      el.load()
      el.remove()
    },
    load: () => {
      el.load()
    },
    error: () => el.error
  }

  return api
}

const liveConfig = { autoplay: true, muted: true }
const replayConfig = { autoplay: false, muted: false }

const VideoPlayer = props => {
  const { hp, wp } = useDimensionsChange()
  const [webPlaceholder, setWebPlaceholder] = useState(true)
  const [videoLoading, setVideoLoading] = useState(false)
  const [audioPlayback, setAudioPlayback] = useState()
  const [videoPlayback, setVideoPlayback] = useState()
  const [controlsVisible, setControlsVisible] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playerDisabled, setPlayerDisabled] = useState(false)

  const videoWrapperRef = useRef(null)
  const videoRef = useRef(null)
  const [animatePause] = useState(new Animated.Value(0))
  const [animatePlay] = useState(new Animated.Value(0))


  useEffect(() => {
    const config = visitor.viewer_session.is_replay ? replayConfig : liveConfig
    config.src = props.link
    const videoEl = document.createElement('video')
    videoEl.setAttribute('playsinline', '')
    videoEl.setAttribute('controls', false)
    videoEl.style.width = `100%`
    videoEl.style.height = `100%`
    if (visitor.viewer_session.is_replay) {
      const poster = visitor.viewer_session.video.replay_start_image_cf_url
      if (poster) {
        videoEl.setAttribute('poster', poster)
      }
    }
    videoWrapperRef.current.appendChild(videoEl)

    videoRef.current = videoInit(videoEl, config, e => {
      console.log('ready')
    })
    videoRef.current.on('play', () => {
      setIsPlaying(true)
    })
    videoRef.current.on('pause', () => {
      setIsPlaying(false)
    })
    videoRef.current.on('ended', () => {
      setIsPlaying(false)
    })
    if (visitor.viewer_session.is_replay) {
      videoRef.current.on('timeupdate', throttle(() => {
        EventEmitter.dispatch('time', videoRef.current.currentTime())
        visitor.setTime(videoRef.current.currentTime())
      }, 1000))
    } else {
      videoEl.addEventListener('loadedmetadata', () => {
        videoEl.currentTime = visitor.time
      }, false)

      videoRef.current.on('resume', e => {
        videoRef.current.currentTime(visitor.time)
      })
    }
    videoRef.current.on('error', () => {
      console.error('Video Error:', videoRef.current, videoEl)

      setTimeout(() => {
        videoRef.current.load()
        videoRef.current.currentTime(visitor.time)
      }, 3000)

      console.error(`Error ${videoRef.current.error().code}; details: ${videoRef.current.error().message}`)
    })
    window.videoRef = videoRef.current

    EventEmitter.subscribe('timeChanged', () => {
      videoRef.current.currentTime(Math.abs(visitor.time))
    })

    return () => {
      EventEmitter.unsubscribe('timeChanged')
      if (videoEl) {
        videoEl.pause()
        videoEl.removeAttribute('src')
        videoEl.load()
        videoEl.remove()
      }
    }
  }, [])

  const notifyPlay = () => {
    Animated.sequence([
      Animated.timing(animatePlay, {
        toValue: 0.6,
        duration: 150,
        useNativeDriver: true
      }),
      Animated.timing(animatePlay, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true
      })
    ]).start()
  }

  const notifyPause = () => {
    Animated.sequence([
      Animated.timing(animatePause, {
        toValue: 0.6,
        duration: 150,
        useNativeDriver: true
      }),
      Animated.timing(animatePause, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true
      })
    ]).start()
  }

  const toggleControls = () => {
    setControlsVisible(prev => {
      if (visitor.viewer_session.is_replay) {
        if (prev) {
          videoRef.current.play()
          notifyPlay()
        } else {
          videoRef.current.pause()
          notifyPause()
        }
      }

      return !prev
    })
  }

  const startVideo = () => {
    videoRef.current.muted(false)
    videoRef.current.play()
    setWebPlaceholder(false)
    props.setPlaybackStarted(true)
  }

  const enableSound = () => {
    videoRef.current.muted(false)
    // videoRef.current.playAllowed = true
    setWebPlaceholder(false)
  }

  const playPauseStyles = {
    backgroundColor: '#ffffff',
    borderRadius: 40
  }

  const pauseStyles = {
    ...playPauseStyles,
    opacity: animatePause,
    transform: [{
      scale: animatePause.interpolate({
        inputRange: [0, 1],
        outputRange: [0.9, 1]
      }),
    }]
  }

  const playStyles = {
    ...playPauseStyles,
    opacity: animatePlay,
    transform: [{
      scale: animatePlay.interpolate({
        inputRange: [0, 1],
        outputRange: [0.9, 1]
      }),
    }]
  }

  const fullScreenStyles = {
    display: 'flex',
    width: wp(100),
    height: hp(100),
  }
  const controlsWidth = props.fullScreenVideo && hp(100) * props.height / props.width

  const disablePlayer = () => {
    videoRef.current.playAllowed = false
    videoRef.current.controls = false
    videoRef.current.pause()
    videoRef.current.removeControls()
    setWebPlaceholder(false)
    setControlsVisible(false)
    setPlayerDisabled(true)
  }

  return (
    <View style={props.fullScreenVideo ? { backgroundColor: '#000000', height: '100%' } : {}}>
      <View style={[props.fullScreenVideo && fullScreenStyles, { opacity: props.opacity || 1 }]}>
        <StyledVideo
          show={ !videoLoading }
          ref={videoWrapperRef}
          width={ props.fullScreenVideo ? wp('100') : '100%' }
          height={ props.fullScreenVideo ? hp('100') : '100%' }
        />
        <PlayPauseNotifier>
          <Animated.View style={pauseStyles}><PauseIcon /></Animated.View>
        </PlayPauseNotifier>
        <PlayPauseNotifier>
          <Animated.View style={playStyles}><PlayIcon /></Animated.View>
        </PlayPauseNotifier>
        <ControlsOverlay activeOpacity={1} onPress={toggleControls} />
        {controlsVisible && !props.isVertical &&
          <Controls
            position="bottom"
            duration={props.duration}
            value={(videoRef.current ? videoRef.current.currentTime() : 0) / props.duration * 100}
            onChange={value => {
              videoRef.current.currentTime(value * props.duration / 100)
            }}
            showBar={visitor.showControls}
            width={controlsWidth}
          />
        }
        {webPlaceholder &&
          (visitor.viewer_session.is_replay
          ? <WebWrapper onPress={startVideo}>
              <PlayIcon fill="#ffffff" />
            </WebWrapper>
          : <WebWrapper onPress={enableSound}>
              <WebIcon>
                <SoundIcon />
              </WebIcon>
              <WebText>{ i18n.t('player.click_for_sound1') }</WebText>
            </WebWrapper>
          )
        }
      </View>
      {
        props.isVertical && !webPlaceholder
        ? <StyledGradient colors={['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 1)']}>
            <GradientContent onPress={toggleControls} />
          </StyledGradient>
        : null
      }
      {controlsVisible && props.isVertical && props.currentTab === 'chat' &&
        <Controls
          position="top"
          duration={props.duration}
          value={(videoRef.current ? videoRef.current.currentTime() : 0) / props.duration * 100}
          onChange={value => {
            videoRef.current.currentTime(value * props.duration / 100)
          }}
          showBar={visitor.showControls}
        />
      }
      { !webPlaceholder && playerDisabled && <WebWrapper activeOpacity={1} style={{ cursor: 'default' }} /> }
      {
        visitor.viewer_session.is_rewatch && (
          <Closeout
            options={visitor.viewer_session.closeout}
            onClick={url => visitor.openUrl(url, { newTab: true })}
            onExpire={disablePlayer}
            display={!isPlaying}
          />
        )
      }
    </View>
  )
}

VideoPlayer.propTypes = {
  isVertical: PropTypes.bool,
  currentTab: PropTypes.string,
  duration: PropTypes.number,
  fullScreenVideo: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  opacity: PropTypes.number,
}

const ControlsOverlay = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const SoundIcon = () => (
  <Svg width="60" height="60" viewBox="0 0 75 75">
    <Path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z" stroke="#ffffff" strokeWidth="5" strokeLinejoin="round" fill="#ffffff" />
    <Path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6" fill="none" stroke="#ffffff" strokeWidth="5" strokeLinecap="round" />
  </Svg>
)

const StyledVideo = styled.View`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: ${props => props.show ? 'flex' : 'none'};
`

const WebWrapper = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 100;
  align-items: center;
  justify-content: flex-start;
  padding: 60px 20px;
`

const WebText = styled.Text`
  color: #ffffff;
  font-size: 24px;
  margin-top: 20px;
`

const WebIcon = styled.View`
`

const StyledGradient = styled(LinearGradient)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const GradientContent = styled.TouchableOpacity`
  width: 100%;
  height: 100%;
`

const PlayPauseNotifier = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PauseIcon = () => (
  <Svg viewBox="0 0 1792 1792" width="80" height="80">
    <Path d="M832 1184v-576q0-14-9-23t-23-9h-256q-14 0-23 9t-9 23v576q0 14 9 23t23 9h256q14 0 23-9t9-23zm448 0v-576q0-14-9-23t-23-9h-256q-14 0-23 9t-9 23v576q0 14 9 23t23 9h256q14 0 23-9t9-23zm384-288q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
  </Svg>
)

const PlayIcon = props => (
  <Svg viewBox="0 0 1792 1792" height="80" width="80">
    <Path fill={props.fill} d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5-103 385.5-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103zm384 823q32-18 32-55t-32-55l-544-320q-31-19-64-1-32 19-32 56v640q0 37 32 56 16 8 32 8 17 0 32-9z"/>
  </Svg>
)

export default VideoPlayer
