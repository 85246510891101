const fr = {
  app: {
    title: 'Participer à un webinaire',
    description: 'Entrez votre code de participation ci-dessous',
    type_code: 'Tapez le code',
    submit: 'Rejoindre le webinaire',
    token_not_found: "Le webinaire est introuvable. Ce webinaire ou son enregistrement a été supprimé. Pour plus d'informations, veuillez contacter l'organisateur.",
    token_not_exist: 'Entrez votre code de participation ci-dessous.',
    admin: 'Administrateur'
  },
  tabs: {
    messages: 'Discussion',
    qa: 'Q&R',
    hosts: 'Présentateurs',
    attendees: 'Participants',
    files: 'Fichiers',
    dm: 'MP',
    quit: 'Quitter',
    quit_confirmation_title: 'Quitter le webinaire',
    quit_confirmation_message: 'Êtes-vous sûr de vouloir quitter?',
  },
  finish: {
    title: "L'organisateur a terminé ce webinaire",
    notice: "L'application va se fermer automatiquement dans 30 secondes",
    close: 'Fermer'
  },
  connect: {
    starts_in: 'Le webinaire commence dans',
    hours: 'H',
    minutes: 'Min' ,
    seconds: 'S',
    notice: "La présentation débutera à l'arrivée de l'organisateur."
  },
  player: {
    click_for_sound: 'Cliquez pour avoir le son'
  },
  update: {
    title: 'Mise à jour...'
  },
  chat: {
    shared_file: 'Partager un fichier',
    poll: 'Sondage',
    asked_question: 'Partager une question',
    message_placeholder: 'Écrire un message'
  },
  qa: {
    question_placeholder: 'Écrire une question',
    answered: 'Répondu',
    not_answered: 'Pas répondu'
  }
}

export default fr
