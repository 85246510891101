import * as Device from 'expo-device'

const deviceTypeMap = {
  0: 'phone', // unknown
  1: 'phone', // phone
  2: 'tablet', // tablet
  3: 'desktop', // desktop
  4: 'desktop' // tv
}

class ScreenInfo {
  async assignData() {
    // this.isTablet = await isTablet()
    // this.isLandscape = await isLandscape()
    const deviceTypeId = await Device.getDeviceTypeAsync()
    this.deviceType = deviceTypeMap[deviceTypeId]
  }
}

export default new ScreenInfo()
