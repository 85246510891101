import Constants from 'expo-constants'
import { Platform } from 'react-native'

const config = {
  development: {
    domain: 'http://meeting.lvh.me:3000',
    ws_domain: 'ws://meeting.lvh.me:3334/cable'
  },
  staging: {
    domain: 'https://embed.staging.webby.app',
    ws_domain: 'wss://embed.staging.webby.app/cable'
  },
  production: {
    domain: 'https://embed.webby.app',
    ws_domain: 'wss://embed.webby.app/cable'
  },
  remote: {
    domain: 'http://meeting.192.168.1.41.xip.io:3000',
    ws_domain: 'ws://meeting.192.168.1.41.xip.io:3334/cable'
  },
}

const envByReleaseChannel = () => {
  const { releaseChannel } = Constants.manifest

  if (releaseChannel.indexOf('development') !== -1) return 'development'
  if (releaseChannel.indexOf('eject') !== -1) return 'production'
  if (releaseChannel.indexOf('production') !== -1) return 'production'
  if (releaseChannel.indexOf('staging') !== -1) return 'staging'
}

let env
const mobile = Platform.OS === 'ios' || Platform.OS === 'android'
if (mobile) {
  env = envByReleaseChannel()
} else {
  env = process.env.EXPO_APP_ENV || process.env.WEBBY_APP_ENV || 'development'
}

export { env }

export default config[env]
// export default config['remote']
