const initialState = {
  data: [],
  loading: false,
  error: null
}

const offers = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_OFFERS_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'FETCH_OFFERS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.offers
      }

    case 'FETCH_OFFERS_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case 'ADD_OFFERS':
      return {
        ...state,
        data: [...state.data, ...action.offers]
      }

    default:
      return state
  }
}

export default offers
