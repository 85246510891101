import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import padStart from 'lodash/padStart'

const formatSeconds = secs => {
  const days = Math.max(0, Math.floor(secs / 60 / 60 / 24))

  const divisor_for_hours = secs % (60 * 60 * 24)
  const hours = Math.max(0, Math.floor(divisor_for_hours / (60 * 60)))

  const divisor_for_minutes = secs % (60 * 60)
  const minutes = Math.max(0, Math.floor(divisor_for_minutes / 60))

  const divisor_for_seconds = divisor_for_minutes % 60
  const seconds = Math.max(0, Math.ceil(divisor_for_seconds))

  return {
    days: padStart(days, 2, '0'),
    hours: padStart(hours, 2, '0'),
    minutes: padStart(minutes, 2, '0'),
    seconds: padStart(seconds, 2, '0'),
    totalSeconds: Math.max(0, secs)
  }
}

const startTimerFn = (startPosition, setSecondsFn, onFinishCallback) => {
  let secs = startPosition
  setSecondsFn(formatSeconds(secs))
  const interval = setInterval(() => {
    secs -= 1

    if (secs <= 0) {
      clearInterval(interval)
      onFinishCallback && onFinishCallback()
    }

    setSecondsFn(formatSeconds(secs))
  }, 1000)

  return interval
}

const Timer = props => {
  const { seconds, onFinishCallback } = props
  const [remain, setRemain] = useState({})

  useEffect(() => {
    const interval = startTimerFn(seconds, setRemain, onFinishCallback)

    return () => {
      interval && clearInterval(interval)
    }
  }, [seconds])

  return (
    <Wrapper>
      <Column>
        <Value>{ remain.days }</Value>
        <Label>Days</Label>
      </Column>
      <Column>
        <Value>{ remain.hours }</Value>
        <Label>Hours</Label>
      </Column>
      <Column>
        <Value>{ remain.minutes }</Value>
        <Label>Mins</Label>
      </Column>
      <Column>
        <Value>{ remain.seconds }</Value>
        <Label>Seconds</Label>
      </Column>
    </Wrapper>
  )
}

Timer.propTypes = {
  className: PropTypes.string,
  seconds: PropTypes.number,
  onFinishCallback: PropTypes.func
}

const Column = styled.View`
  flex: 1;
`

const Label = styled.Text`
  color: rgba(172,181,195,1);
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 0.6em;
  font-weight: bold;
`

const Value = styled.Text`
  color: rgba(85,97,146,1);
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 1.3em;
  font-weight: bold;
  letter-spacing: normal;
`

const Wrapper = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  transition: opacity 150ms ease-in-out;
`

export default React.memo(Timer)
