import { ActionCable, Cable } from '@kesha-antonov/react-native-action-cable'
import secrets from '../config/secrets'

const cableService = {
  init(cid) {
    if (!cid) return

    if (cid !== this.cid) {
      console.log('cable init', `${secrets.ws_domain}?cid=${cid}`)
      if (this.cid) this.actionCable.disconnect()
      this.cid = cid
      this.actionCable = ActionCable.createConsumer(`${secrets.ws_domain}?cid=${cid}`)
      this.cable = new Cable({})
    }
  },
  disconnect() {
    if (!this.actionCable) return

    this.actionCable.disconnect()
  }
}

export default cableService
