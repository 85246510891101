import { createSelector } from 'reselect'
import orderBy from 'lodash/orderBy'

const getSessionForMessages = state => {
  const viewer = state.viewer.data
  return {
    cid: viewer.cid,
    viewerToken: viewer.viewer_token,
    chatTab: viewer.webinar.chat_tab,
    qaTab: viewer.webinar.qa_tab,
    isReplay: viewer.is_replay
  }
}
const getMessages = state => state.messages.data
const getOffers = state => state.offers.data
const getFiles = state => state.files.data
const getQuestions = state => state.questions.data
const getDirectMessages = state => state.direct_messages.data
const getStickyMessages = state => state.sticky_messages.data

export const selectOffers = createSelector(
  [getOffers],
  offers => offers
)

export const selectMessages = createSelector(
  [getSessionForMessages, getMessages],
  (session, messages) => orderBy(messages.filter(m => {
    if (m.type === 'sticky') return false

    if (session.chatTab === 'private') {
      if (m.type !== 'question' && m.author.cid !== session.cid && m.author.cid) return false
    }

    if (m.type === 'question') {
      if (session.qaTab === 'private' && m.author.cid !== session.cid && m.author.cid) return false
    }

    // filter own messages
    if (!session.isReplay && m.author.cid !== session.cid && m.author.viewer_token === session.viewerToken) return false

    return true
  }), ['appear_in', 'created_at', 'id'])
)

export const selectStickyMessages = createSelector(
  [getStickyMessages],
  stickyMessages => stickyMessages
)

export const selectFiles = createSelector(
  [getFiles],
  files => files
)

export const makeFilteredQuestionsSelector = () => createSelector(
    getSessionForMessages,
    getQuestions,
    (_, isAnswered) => isAnswered,
    (session, questions, isAnswered) => orderBy(questions.filter(q => {
      // Filter by qaTabMode
      if (session.qaTab === 'private') {
        if (q.author.cid !== session.cid && q.author.cid) return false
      }

      if (!session.isReplay && q.author.cid !== session.cid && q.author.viewer_token === session.viewerToken) return false

      return isAnswered ? q.answer : !q.answer
    }), ['appear_in', 'created_at'])
  )

export const getDirectMessagesLength = createSelector(
  [getDirectMessages],
  messages => messages.length
)

export const getFilesLength = createSelector(
  [getFiles],
  files => files.length
)
