import React, { useState } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { Anchorme } from 'react-anchorme'

const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig

const CustomLink = props => {
  const styles = {
    color: '#ff805c',
    cursor: 'pointer',
    textDecoration: 'none'
  }

  return (
    <a {...props} style={styles} />
  )
}

const BodyHtml = props => {
  // const linkify = text => {
  //   return text.replace(URL_REGEX, url => {
  //     const styles = "color: #ff805c; cursor: pointer; text-decoration: none;"

  //     return `<a target="_blank" href="${url}" style="${styles}">${url}</a>`
  //   })
  // }

  return (
    <View style={props.style}>
      <div>
        <Anchorme linkComponent={CustomLink} target="_blank" rel="noreferrer noopener">
          { props.text }
        </Anchorme>
      </div>
    </View>
  )
}
BodyHtml.propTypes = {
}

export default styled(BodyHtml)`
  flex-direction: row;
  margin-top: 2px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  line-height: 18px;
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.7)' : 'rgba(19, 29, 64, 0.7)'};
`
