import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Platform, StatusBar, Keyboard, KeyboardAvoidingView } from 'react-native'
import styled, { ThemeProvider } from 'styled-components/native'
import { useSelector } from 'react-redux'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import Portal from '@burstware/react-native-portal'
import * as ScreenOrientation from 'expo-screen-orientation'
import { WebinarContext } from '../Context'
import { getDirectMessagesLength } from '../../../selectors'
import Chat from '../../Chat'
import Hosts from '../Hosts'
import QA from '../QA'
import Attendees from '../Attendees'
import Files from '../Files'
import DM from '../DM'
import LiveOffers from './Offers/LiveOffers'
import ReplayOffers from './Offers/ReplayOffers'
import Tab from './Tab'
import VideoPlayer from './VideoPlayer'
import Info from '../Info'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const VerticalLayout = props => {
  const { cid, webinar, is_replay, tabsNode, tabs, currentTab, onTabSelect, video, videoWidth, videoHeight, darkScheme,
    topPadding, fullScreenVideo, statusBarPadding } = useContext(WebinarContext)
  const [hasOffers, setHasOffers] = useState(false)
  const [playbackStarted, setPlaybackStarted] = useState(!is_replay)
  const directMessagesLength = useSelector(getDirectMessagesLength)
  const [keyboardShown, setKeyboardShown] = useState(false)

  useEffect(() => {
    if (mobile) ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)

    EventEmitter.subscribe('Layout.activeOffers', activeOffersCount => {
      setHasOffers(!!activeOffersCount)
    })
    Keyboard.addListener('keyboardDidShow', () => setKeyboardShown(true))
    Keyboard.addListener('keyboardDidHide', () => setKeyboardShown(false))

    return () => {
      EventEmitter.unsubscribe('Layout.activeOffers')
      Keyboard.removeListener('keyboardDidShow', () => setKeyboardShown(true))
      Keyboard.removeListener('keyboardDidHide', () => setKeyboardShown(false))
    }
  }, [])

  useEffect(() => {
    if (directMessagesLength) {
      onTabSelect('dm')
    }
  }, [directMessagesLength])

  const renderContent = () => {
    if (!currentTab) return null

    switch (currentTab.id) {
      case 'chat': return (
        <Chat
          cid={cid}
          loadMoreItems={props.loadMoreMessages}
          tabs={tabs}
        />
      )
      case 'qa': return (
        <QA
          loadMoreItems={props.loadMoreQuestions}
        />
      )
      case 'hosts': return <Hosts />
      case 'attendees': return <Attendees cid={cid} />
      case 'files': return <Files />
      case 'dm': return <DM />
      default: return null
    }
  }

  const Offers = is_replay ? ReplayOffers : LiveOffers
  const wrapperStyles = {
    top: (currentTab.id === 'chat') ? topPadding : 0,
    display: fullScreenVideo ? 'none' : 'flex'
  }

  const displayStyles = {
    display: fullScreenVideo || !playbackStarted ? 'none' : 'flex'
  }

  return (
    <KeyboardAvoidingView
      behavior={ Platform.OS === 'ios' ? 'position' : null }
      contentContainerStyle={{ flex: 1 }}
      keyboardVerticalOffset={0}
      style={[props.style, { backgroundColor: darkScheme ? '#1c1d38' : '#ffffff' }]}>
      <StatusBar hidden barStyle={darkScheme ? 'light-content' : 'dark-content'} />
      {
        mobile
        ? <VideoPlayer
            width={ videoWidth }
            height={ videoHeight }
            link={ video.link }
            duration={ video.duration }
            currentTab={ currentTab && currentTab.id }
            opacity={ (currentTab && currentTab.id === 'chat') ? 1 : 0 }
            isVertical
            fullScreenIcon={ webinar.fullscreen_icon !== 'disabled' }
            fullScreenVideo={ fullScreenVideo }
            statusBarPadding={ statusBarPadding }
            setPlaybackStarted={setPlaybackStarted}
          />
        : <VideoPlayer
            width={ videoWidth }
            height={ videoHeight }
            link={ video.link }
            duration={ video.duration }
            currentTab={ currentTab && currentTab.id }
            opacity={ (currentTab && currentTab.id === 'chat') ? 1 : 0 }
            isVertical
            fullScreenVideo={ fullScreenVideo }
            setPlaybackStarted={setPlaybackStarted}
          />
      }
      { !is_replay && <Info /> }
      {is_replay && !playbackStarted
        && <ReplayStartWrapper pointerEvents="box-none">
          <WebText>{ visitor.viewer_session.video.replay_start_text }</WebText>
        </ReplayStartWrapper>
      }
      <ThemeProvider theme={{ isVertical: true, darkScheme }} style={ displayStyles }>
        <Wrapper style={wrapperStyles}>
          <ContentWrapper style={ displayStyles }>
            { renderContent() }
          </ContentWrapper>
          { !keyboardShown
            && <>
              <Tabs style={ displayStyles } hasOffers={hasOffers}>
                <TabsWrapper ref={tabsNode}
                  data={tabs}
                  renderItem={({ item }) => <Tab hidden={!item.visible} activeTab={currentTab.id} id={item.id} name={item.name} onTabSelect={onTabSelect} />
                  }
                  keyExtractor={item => item.id}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  ListHeaderComponent={ListHeader}
                  ListFooterComponent={ListFooter}
                  paddingBottom={hasOffers ? 20 : 40}
                />
              </Tabs>
              <Portal>
                <Offers cid={cid} style={ displayStyles } isVertical />
              </Portal>
            </>
          }
        </Wrapper>
      </ThemeProvider>
    </KeyboardAvoidingView>
  )
}

VerticalLayout.propTypes = {
  loadMoreMessages: PropTypes.func,
  loadMoreQuestions: PropTypes.func,
}

const Wrapper = styled.View`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
`

const ContentWrapper = styled.View`
  flex: 1;
`

const WebText = styled.Text`
  font-size: 24px;
  margin: 50% 10px;
  text-align: center;
  color: #ffffff;
`

const ReplayStartWrapper = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.15);
`

const Tabs = styled.View`
  ${props => props.hasOffers && 'margin-bottom: 71px;'}
`

const TabsWrapper = styled.FlatList`
  padding-top: 4px;
  padding-bottom: ${props => props.paddingBottom}px;
`

const ListHeader = styled.View`
  margin-left: ${wp(50) - 52}px;
`

const ListFooter = styled.View`
  margin-right: ${wp(50) - 32}px;
`

export default styled(VerticalLayout)`
  flex: 1;
`
