const ru = {
  app: {
    title: 'Принять участие в вебинаре',
    description: 'Введите код вебинара',
    type_code: 'Введите код',
    submit: 'Присоединиться',
    token_not_found: 'Вебинар или его запись были удалены. Пожалуйста, свяжитесь с организатором для получения дополнительной информации.',
    token_not_exist: 'Введите код вебинара',
    admin: 'Организатор'
  },
  tabs: {
    messages: 'Сообщения',
    qa: 'В&О',
    hosts: 'Организаторы',
    attendees: 'Участники',
    files: 'Файлы',
    dm: 'ПМ',
    quit: 'Выйти',
    quit_confirmation_title: 'Покинуть вебинар',
    quit_confirmation_message: 'Вы уверены, что хотите выйти?',
  },
  finish: {
    title: 'Вебинар был завершен организатором',
    notice: 'Это окно закроется через 30 секунд',
    close: 'Закрыть'
  },
  connect: {
    starts_in: 'Вебинар начнется через',
    hours: 'Ч',
    minutes: 'Мин' ,
    seconds: 'Сек',
    notice: 'Вебинар начнется, когда присоединится организатор'
  },
  player: {
    click_for_sound: 'Включить звук'
  },
  update: {
    title: 'Обновление...'
  },
  chat: {
    shared_file: 'Поделился файлом',
    poll: 'Опрос',
    asked_question: 'Задал вопрос',
    message_placeholder: 'Введите сообщение'
  },
  qa: {
    question_placeholder: 'Введите вопрос',
    answered: 'Отвеченные',
    not_answered: 'Неотвеченные'
  }
}

export default ru
