import axios from 'axios'
import secrets from '../config/secrets'

const fetchOffersBegin = () => ({ type: 'FETCH_OFFERS_BEGIN' })
const fetchOffersSuccess = ({ offers }) => ({ type: 'FETCH_OFFERS_SUCCESS', offers })
const fetchOffersError = error => ({ type: 'FETCH_OFFERS_ERROR', error })

export function fetchOffers(cid) {
  return dispatch => {
    dispatch(fetchOffersBegin())

    return axios({
      url: `${secrets.domain}/meeting/messages/offers?cid=${cid}`
    })
    .then(response => {
      dispatch(fetchOffersSuccess(response.data))
    })
    .catch(error => {
      dispatch(fetchOffersError(error))
    })
  }
}

export const addOffers = offers => ({ type: 'ADD_OFFERS', offers })
