import React, { useState, useEffect, useRef } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import moment from 'moment-timezone'
import getScreenInfo from '../../services/screen'
import Avatar from '../Chat/message/Avatar'

const Connect = props => {
  const { name, cid, buttonName, onSelect } = props

  const timeZone = moment.tz.guess()
  const humanScheduledAt = moment(props.scheduledAt).tz(timeZone).format('LLLL')

  const containerWidth = (() => {
    switch (getScreenInfo.deviceType) {
      case 'phone': return '100%'
      case 'tablet': return '50%'
      case 'desktop': return '33.3%'
      default: '100%'
    }
  })()

  return (
    <View style={[props.style, { width: containerWidth }]}>
      <SessionContent>
        <Host>
          <HostAvatar><Avatar avatarUrl={props.hostAvatarUrl} size="xlarge" borderRadius={12} /></HostAvatar>
          <HostName>{ props.hostName }</HostName>
        </Host>
        <WebinarName>{ props.webinarName }</WebinarName>
        <SessionName>{ humanScheduledAt }</SessionName>
        <SessionButton onPress={() => onSelect(cid)}><ButtonText>{ buttonName }</ButtonText></SessionButton>
      </SessionContent>
    </View>
  )
}

const Host = styled.View`
  flex-direction: row;
`

const HostAvatar = styled.View`
`

const HostName = styled.Text`
  margin-left: 10px;
`

const SessionContent = styled.View`
  margin: 10px 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;

  &:hover {
    border-color: #FF7E5C;
  }
`

const WebinarName = styled.Text`
  margin-top: 10px;
`

const SessionName = styled.Text`
  margin-top: 10px;
`

const SessionButton = styled.TouchableOpacity`
  margin-top: 10px;
  border-radius: 11px;
  padding: 13px 18px;
  background: #ff7e5c;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonText = styled.Text``

export default styled(Connect)`
  width: 50%;
`
