import React, { createContext, useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import { Alert, Platform, Appearance } from 'react-native'
import PropTypes from 'prop-types'
import { Constants } from 'react-native-unimodules'
import moment from 'moment-timezone'
import axios from 'axios'
import CableService from '../../services/cable'
import { getDirectMessagesLength, getFilesLength } from '../../selectors'
import useOrientationChange from './useOrientationChange'
import visitor from '../../services/visitor'
import { EventEmitter } from '../../services/events'
import i18n from '../../services/i18n'
import secrets from '../../config/secrets'
import Poller from '../../services/poller'
import getScreenInfo from '../../services/screen'
import {
  addOffers,
  fetchHosts,
  fetchDirectMessages,
  addDirectMessage,
  removeDirectMessage,
  removeSession,
  clearMessages,
  clearQuestions,
  clearFiles,
  clearDirectMessages,
  clearStickyMessages,
} from '../../actions'

window.visitor = visitor

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

export const WebinarContext = createContext()

const fetchOffers = (cid, fn) => axios({
    url: `${secrets.domain}/meeting/messages/offers?cid=${cid}`
  })
  .then(fn)
  .catch(() => {})

const poller = new Poller()
poller.start()

const WebinarContextProvider = props => {
  // const isDesktop = ['desktop', 'tablet'].includes(getScreenInfo.deviceType)
  const isDesktop = ['desktop'].includes(getScreenInfo.deviceType)

  const TABS = [
    { id: 'chat', name: i18n.t('tabs.messages') },
    { id: 'qa', name: i18n.t('tabs.qa') },
    { id: 'hosts', name: i18n.t('tabs.hosts') },
    { id: 'attendees', name: i18n.t('tabs.attendees') },
    { id: 'files', name: i18n.t('tabs.files') },
    { id: 'dm', name: i18n.t('tabs.dm') },
    { id: 'quit', name: i18n.t('tabs.quit') }
  ]

  const { orientation: deviceOrientation, initialOrientation: initialDeviceOrientation } = useOrientationChange()
  const getFullScreenValue = orientation => {
    if (isDesktop) return false

    return orientation === 'landscape'
  }
  const defaultFullScreenValue = isDesktop ? false : getFullScreenValue(deviceOrientation)
  const [fullScreenVideo, setFullScreenVideo] = useState(defaultFullScreenValue)

  useEffect(() => {
    setFullScreenVideo(getFullScreenValue(deviceOrientation))
  }, [deviceOrientation])

  const dispatch = useDispatch()
  const directMessagesLength = useSelector(getDirectMessagesLength)
  const filesLength = useSelector(getFilesLength)
  const { cid, webinar, video, attendees, is_replay, conversation_id, room_id } = props.session
  const { chat_tab, qa_tab, presenters_tab, attendees_tab, files_tab, replay_chat_tab, replay_qa_tab, replay_presenters_tab, replay_files_tab } = webinar
  const videoWidth = wp('100')
  const videoHeight = Math.max(video.height || hp('100')) / video.width * videoWidth
  const isVertical = initialDeviceOrientation === 'portrait' ? videoHeight > hp('50') : false
  const colorScheme = Appearance.getColorScheme()
  const darkScheme = isDesktop ? false : (isVertical || colorScheme === 'dark')
  let topPadding = isVertical ? hp(45) : videoHeight
  if (!is_replay) {
    topPadding += 47 // 47 - icons height
  }
  if (videoWidth / videoHeight > 1) {
    topPadding += Constants.statusBarHeight
  }

  const tabs = TABS.reduce((tabs, t) => {
    switch (t.id) {
      case 'chat':
        return (is_replay ? replay_chat_tab !== 'disabled' : chat_tab !== 'disabled') ? tabs.concat([{ ...t, visible: true }]) : tabs
      case 'qa':
        return (is_replay ? replay_qa_tab !== 'disabled' : qa_tab !== 'disabled') ? tabs.concat([{ ...t, visible: true }]) : tabs
      case 'hosts':
        return (is_replay ? replay_presenters_tab : presenters_tab) ? tabs.concat([{ ...t, visible: true }]) : tabs
      case 'attendees':
        return (is_replay ? false : attendees_tab !== 'disabled') ? tabs.concat([{ ...t, visible: true }]) : tabs
      case 'files':
        return (is_replay ? replay_files_tab : files_tab) ? tabs.concat([{ ...t, visible: !!filesLength }]) : tabs
      case 'dm':
        return tabs.concat([{ ...t, visible: !!directMessagesLength }])
      case 'quit':
        return tabs.concat([{ ...t, visible: true }])
    }
  }, [])
  const defaultTab = tabs.find(t => t.visible)
  const tabsNode = useRef(null)
  const [tab, setTab] = useState(defaultTab.id)
  const currentTab = tabs.find(i => i.id === tab)

  const statusBarPadding = mobile ? Constants.statusBarHeight + 10 : hp(2)

  const onTabSelect = useCallback(id => {
    if (id === 'quit') {
      if (mobile) {
        Alert.alert(
          i18n.t('tabs.quit_confirmation_title'),
          i18n.t('tabs.quit_confirmation_message'),
          [
            {
              text: 'Cancel',
              onPress: () => {},
              style: 'cancel',
            },
            {
              text: 'OK',
              onPress: () => {
                visitor.trackLeave()
                batch(() => {
                  dispatch(removeSession())
                  dispatch(clearMessages())
                  dispatch(clearQuestions())
                  dispatch(clearFiles())
                  dispatch(clearDirectMessages())
                  dispatch(clearStickyMessages())
                })
                visitor.cleanSession()
              }
            },
          ],
          { cancelable: false }
        )
      } else {
        setTimeout(() => {
          if (window.confirm(i18n.t('tabs.quit_confirmation_message'))) {
            visitor.trackLeave()
            batch(() => {
              dispatch(removeSession())
              dispatch(clearMessages())
              dispatch(clearQuestions())
              dispatch(clearFiles())
              dispatch(clearDirectMessages())
              dispatch(clearStickyMessages())
            })
          } else {
            tabsNode.current?.scrollToIndex({ viewPosition: 0.5, index: tabs.findIndex(i => i.id === currentTab.id) })
          }
        }, 0)
      }
    } else {
      setTab(id)
      tabsNode.current?.scrollToIndex({ viewPosition: 0.5, index: tabs.findIndex(i => i.id === id) })
    }
  }, [])

  useEffect(() => {
    batch(() => {
      dispatch(fetchHosts(cid))
    })

    async function fetchData() {
      fetchOffers(cid, response => {
        const visibleMessages = []
        const scheduledMessages = {}
        response.data.offers.map(message => {
          const scheduleIn = moment.duration(message.appear_in).asSeconds()

          if (!visitor.viewer_session.is_replay && visitor.time < scheduleIn) {
            scheduledMessages[scheduleIn] = scheduledMessages[scheduleIn] || { messages: [] }
            scheduledMessages[scheduleIn].messages.push(message)
          } else {
            visibleMessages.push(message)
          }
        })
        poller.add('addOffers', 1000, () => {
          Object.keys(scheduledMessages).forEach(time => {
            if (visitor.time > parseInt(time)) {
              const data = scheduledMessages[time]
              dispatch(addOffers(data.messages))
              delete scheduledMessages[time]
            }
          })
        })
        dispatch(addOffers(visibleMessages))
      })
    }
    fetchData()

    return () => {
      EventEmitter.unsubscribe('webinar.start')
      EventEmitter.unsubscribe('webinar.end')
    }
  }, [cid])

  useEffect(() => {
    CableService.init(cid)
    if (conversation_id) {
      dispatch(fetchDirectMessages(cid))

      const channel = { channel: 'ConversationsChannel', conversation_id }

      const subscription = CableService.cable.setChannel(
        `rooms_${room_id}`,
        CableService.actionCable.subscriptions.create(channel)
      )

      subscription
        .on('received', response => {
          console.log('onReceive direct', response)
          const { type, data: message } = response

          switch (type) {
            case 'message':
              if (message && message.author) {
                setFullScreenVideo(false)
                dispatch(addDirectMessage(message, !message.author.cid))
              }
              break
            case 'message.deleted':
              dispatch(removeDirectMessage(message.id))
              break
          }
        })
        .on('connected', () => console.log('ws connected'))
        .on('rejected', () => console.log('ws rejected'))
        .on('disconnected', () => console.log('ws disconnected'))

      return () => {
        if (subscription) {
          subscription.removeListener('received')
          subscription.removeListener('connected')
          subscription.removeListener('rejected')
          subscription.removeListener('disconnected')
          subscription.unsubscribe()
        }
      }
    }
      dispatch(clearDirectMessages())
  }, [conversation_id])

  const data = {
    mobile,
    statusBarPadding,
    cid,
    webinar,
    video,
    videoWidth,
    videoHeight,
    attendees,
    is_replay,
    isVertical,
    tabs,
    tabsNode,
    currentTab,
    onTabSelect,
    darkScheme,
    topPadding,
    fullScreenVideo,
    initialDeviceOrientation,
  }

  return (
    <WebinarContext.Provider value={{ ...data }}>
      {props.children}
    </WebinarContext.Provider>
  )
}

WebinarContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default WebinarContextProvider
