import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'
import Svg, { Path } from 'react-native-svg'
import downloadFile from '../../../services/downloadFile'
import { humanFileSize } from '../../../helpers/bytes'

const Downloads = props => {
  const triggerRef = useRef()
  const [rect, setRect] = useState({ top: 35, right: -10 })
  const [showPopover, setShowPopover] = useState(false)
  const files = useSelector(state => state.files.data)

  const onDownload = (downloadUrl, outputName) => {
    downloadFile(downloadUrl, outputName)
  }

  const toggleTrigger = () => {
    setShowPopover(prev => !prev)
  }

  if (!files.length) return null

  return (
    <View style={props.style}>
      <Item onPress={toggleTrigger} ref={triggerRef}>
        <Icon />
        <Label><LabelText>Downloads</LabelText></Label>
        <Bubble><DownloadsCount>{ files.length }</DownloadsCount></Bubble>
      </Item>
      <Popover
        placement="bottom"
        debug
        top={rect.top}
        right={rect.right}
        isVisible={showPopover}
        onRequestClose={() => setShowPopover(false)}
      >
        <Content>
          {
            files.map(file => (
              <DownloadFile key={file.id}>
                <FileLogo><PreviewIcon /></FileLogo>
                <FileContent>
                <FileName>{ file.data.name }</FileName>
                <FileSize>{ humanFileSize(file.data.file_size) }</FileSize>
                </FileContent>
              <FileLink onPress={() => onDownload(file.data.download_url, file.data.name)}><FileLinkLogo /></FileLink>
              </DownloadFile>
            ))
          }
        </Content>
      </Popover>
    </View>
  )
}
Downloads.propTypes = {
}

const Item = styled.TouchableOpacity`
  margin-left: 30px;
  flex-direction: row;
  align-items: center;
`

const Icon = () => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <Path d="M19.4337 13.75C20.0754 12.65 20.3504 11.4584 20.0754 10.175C19.5254 8.34168 17.8754 6.96668 16.0421 6.96668H14.9421C14.3004 4.21668 12.0087 2.20002 9.25874 1.83335C6.50874 1.55835 3.8504 3.02502 2.56707 5.50002C1.46707 7.79168 1.6504 11.4584 3.0254 13.5667M11.0004 18.15V11M14.6671 15.5834L11.0004 19.25L7.33374 15.5834" stroke="#272C5E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
)

const Label = styled.Text`
  margin-left: 8px;
`

const LabelText = styled.Text`
  font-size: 14px;
  color: #272C5E;
`

const Bubble = styled.View`
  margin-left: 8px;
  background-color: #FF805C;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`

const DownloadsCount = styled.Text`
  color: #ffffff;
  font-size: 12px;
`

const Content = styled.View`
  background-color: #ffffff;
  box-shadow: 0px 7px 10px rgba(39, 44, 94, 0.05);
  border-radius: 18px;
  padding: 10px;
`

const DownloadFile = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background: #EDF0FB;
  border-radius: 8px;
`

const FileLogo = styled.View`
  flex: 0 0 27px;
`

const PreviewIcon = () => (
  <Svg width="27" height="29" viewBox="0 0 27 29" fill="none">
    <Path fillRule="evenodd" clipRule="evenodd" d="M19.5271 0.16836L26.8036 7.72942C26.9021 7.83178 26.9572 7.96829 26.9572 8.11032V26.3668C26.9572 27.5772 25.9697 28.562 24.756 28.562H8.28611C7.0724 28.562 6.08494 27.5767 6.08494 26.3656V21.4215H1.6898C0.781731 21.4215 0.0429688 20.6829 0.0429688 19.7752V11.5332C0.0429688 10.6254 0.781731 9.88685 1.6898 9.88685H6.08494V2.20028C6.08494 0.987062 7.0724 0 8.28611 0H19.1313C19.2806 0 19.4235 0.0608058 19.5271 0.16836ZM25.2408 7.68976L19.2674 1.48282V7.68976H25.2408ZM1.6898 20.323C1.38745 20.323 1.14155 20.0772 1.14155 19.7752V11.5332C1.14155 11.2312 1.3875 10.9854 1.6898 10.9854H16.5221C16.8244 10.9854 17.0703 11.2312 17.0703 11.5332V19.7752C17.0703 20.0772 16.8244 20.323 16.5221 20.323H1.6898ZM24.7559 27.4635C25.3639 27.4635 25.8586 26.9715 25.8586 26.3668V8.78828H18.7181C18.4148 8.78828 18.1688 8.54238 18.1688 8.23902V1.09852H8.28611C7.67811 1.09852 7.18346 1.59272 7.18346 2.20028V9.88685H16.5221C17.4301 9.88685 18.1689 10.6254 18.1689 11.5332V19.7752C18.1689 20.6829 17.4301 21.4215 16.5221 21.4215H7.18346V26.3656C7.18346 26.971 7.67811 27.4635 8.28611 27.4635H24.7559Z" fill="#FF805C"/>
    <Path fillRule="evenodd" clipRule="evenodd" d="M5.93066 13.4785C6.201 13.5493 6.42737 13.7033 6.60974 13.9404C6.7921 14.1774 6.88331 14.4837 6.88336 14.8592C6.88336 15.1488 6.83081 15.3924 6.72566 15.5898C6.6205 15.7871 6.48695 15.9421 6.32495 16.0548C6.16295 16.1675 5.99827 16.242 5.83092 16.2785C5.60348 16.3235 5.27413 16.3461 4.84285 16.3461H4.22169V18.1259H3.26904V13.4077H4.79778C5.37711 13.4077 5.75472 13.4313 5.93066 13.4785ZM5.49615 15.4707C5.62278 15.4213 5.72203 15.3441 5.79388 15.239C5.86573 15.1338 5.90171 15.0115 5.90165 14.8721C5.90165 14.7005 5.85122 14.5588 5.75037 14.4473C5.64956 14.3357 5.52187 14.266 5.3674 14.2381C5.25371 14.2166 5.02521 14.2059 4.68191 14.2059H4.22169V15.5447H4.74305C5.11854 15.5447 5.36952 15.5201 5.49615 15.4707Z" fill="#FF805C"/>
    <Path fillRule="evenodd" clipRule="evenodd" d="M11.0125 13.9323C11.2098 14.1405 11.36 14.3952 11.463 14.6967C11.566 14.9981 11.6175 15.3698 11.6175 15.8118C11.6175 16.2002 11.5692 16.5349 11.4727 16.816C11.3546 17.1593 11.1862 17.4371 10.9674 17.6495C10.8022 17.8104 10.5791 17.936 10.298 18.0261C10.0877 18.0926 9.80666 18.1259 9.45476 18.1259H7.66211V13.4077H9.40327C9.79589 13.4077 10.0952 13.4377 10.3012 13.4978C10.5779 13.5794 10.8151 13.7242 11.0125 13.9323ZM10.2867 17.0895C10.3886 16.9973 10.4717 16.8455 10.5361 16.6342C10.6005 16.4228 10.6327 16.1348 10.6327 15.77C10.6327 15.4053 10.6005 15.1253 10.5361 14.93C10.4717 14.7348 10.3816 14.5825 10.2658 14.473C10.1499 14.3636 10.0029 14.2896 9.82484 14.251C9.69185 14.2209 9.43111 14.2059 9.04279 14.2059H8.61475V17.3309H9.32601C9.59205 17.3309 9.78412 17.3159 9.90211 17.2858C10.0566 17.2472 10.1848 17.1818 10.2867 17.0895Z" fill="#FF805C"/>
    <Path d="M15.6661 14.2059V13.4077H12.4316V18.1259H13.3843V16.1208H15.3539V15.3227H13.3843V14.2059H15.6661Z" fill="#FF805C"/>
  </Svg>
)

const FileContent = styled.View`
  flex: 1 1 auto;
  margin: 0 10px;
  min-width: 150px;
`

const FileName = styled.Text`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`

const FileSize = styled.Text`
  margin-top: 5px;
  font-size: 10px;
  line-height: 12px;
`

const FileLink = styled.TouchableOpacity`

`

const FileLinkLogo = () => (
  <Svg width="20" height="19" viewBox="0 0 20 19" fill="none">
    <Path d="M16.0892 5.01595C15.4163 1.63985 12.134 -0.551446 8.75793 0.121432C6.28864 0.613589 4.35786 2.54315 3.86418 5.01215C1.47042 5.28802 -0.246655 7.45211 0.029062 9.84602C0.282892 12.0492 2.14892 13.7114 4.36667 13.7099H7.48316C7.82743 13.7099 8.10649 13.4308 8.10649 13.0865C8.10649 12.7423 7.82743 12.4632 7.48316 12.4632H4.36667C2.64534 12.453 1.25839 11.0495 1.26842 9.32833C1.27861 7.60699 2.68212 6.21989 4.40345 6.23008C4.72112 6.23008 4.98802 5.99114 5.02298 5.6753C5.33943 2.93971 7.81375 0.978527 10.5495 1.29498C12.8495 1.56097 14.6638 3.37532 14.9298 5.6753C14.9821 5.99677 15.2605 6.23221 15.5861 6.23008C17.3075 6.23008 18.7028 7.62538 18.7028 9.34672C18.7028 11.0679 17.3075 12.4632 15.5861 12.4632H12.4696C12.1254 12.4632 11.8463 12.7423 11.8463 13.0865C11.8463 13.4308 12.1254 13.7099 12.4696 13.7099H15.5863C17.9958 13.6948 19.9371 11.7292 19.9222 9.31966C19.9085 7.12532 18.2673 5.28255 16.0892 5.01595Z" fill="#FF805C"/>
    <Path d="M12.1796 14.9877L10.5995 16.5683V8.72343C10.5995 8.37916 10.3204 8.1001 9.97632 8.1001C9.63206 8.1001 9.35299 8.37916 9.35299 8.72343V16.5683L7.77286 14.9877C7.53378 14.74 7.13905 14.7331 6.89145 14.9724C6.64385 15.2114 6.63701 15.606 6.8761 15.8536C6.88127 15.8589 6.88628 15.864 6.89145 15.869L9.53493 18.5131C9.59269 18.5707 9.66109 18.6164 9.73632 18.6477C9.88969 18.7126 10.0628 18.7126 10.2163 18.6477C10.2916 18.6164 10.36 18.5707 10.4176 18.5131L13.061 15.869C13.3086 15.6299 13.3155 15.2353 13.0764 14.9877C12.8372 14.7401 12.4426 14.7333 12.195 14.9724C12.1898 14.9774 12.1846 14.9824 12.1796 14.9877Z" fill="#FF805C"/>
  </Svg>
)

const Help = styled.View`
  margin-left: 30px;
  flex-direction: row;
  align-items: center;
`

const HelpIcon = () => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <Path d="M10.9997 20.1667C16.0623 20.1667 20.1663 16.0627 20.1663 11C20.1663 5.93743 16.0623 1.83337 10.9997 1.83337C5.93706 1.83337 1.83301 5.93743 1.83301 11C1.83301 16.0627 5.93706 20.1667 10.9997 20.1667Z" stroke="#272C5E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <Path d="M8.33203 8.24991C8.54754 7.63727 8.97292 7.12068 9.53282 6.79162C10.0927 6.46256 10.751 6.34227 11.3911 6.45206C12.0312 6.56186 12.6118 6.89464 13.03 7.39148C13.4483 7.88832 13.6772 8.51714 13.6762 9.16658C13.6762 10.9999 10.9262 11.9166 10.9262 11.9166" stroke="#272C5E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <Path d="M11 15.5834H11.01" stroke="#272C5E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
)

const Popover = styled.View`
  position: absolute;
  top: ${props => props.top}px;
  right: ${props => props.right}px;
  opacity: ${props => props.isVisible ? 1 : 0};
  display: ${props => props.isVisible ? 'flex' : 'none'};
`

export default Downloads
