import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import { Button } from 'react-native-elements'
import styled from 'styled-components/native'
import Swipeable from 'react-native-gesture-handler/Swipeable';
import visitor from '../../../../services/visitor'
import secrets from '../../../../config/secrets'
import { openUrl } from './Offer'

const FullScreenOffer = props => {
  const { cid, topOffer, offerHumanCreatedAt } = props

  const [showed, setShowed] = useState(true)

  useEffect(() => {
    setShowed(true)
  }, [topOffer.id])

  const onLinkClick = () => {
    const offerLink = encodeURIComponent(topOffer.data.link)
    const offerUrl = `${secrets.domain}/offer/${cid}?url=${offerLink}&offer_id=${topOffer.id}&primary=true`
    openUrl(offerUrl)
    visitor.trackClickOffer(topOffer.id, topOffer.webinar_cta.id)
  }

  const closeOffer = () => setShowed(false)

  const renderLeftActions = () => <HiddenElement />

  if (!showed) return null

  return (
    <Layout>
      <Swipeable
        renderLeftActions={renderLeftActions}
        renderRightActions={renderLeftActions}
        onSwipeableOpen={closeOffer}
        friction={0.7}
      >
        <Content>
          <SafeWrap>
            <Header>
              <HeaderWrapper>
                <HeaderAvatar>
                  <HeaderAvatarImage source={{ uri: topOffer.author.avatar_url }} />
                </HeaderAvatar>
                <HeaderBody>
                  <HeaderTitle>
                    <HeaderAuthor>{ topOffer.author.name }</HeaderAuthor>
                    <HeaderSentAt>{ offerHumanCreatedAt }</HeaderSentAt>
                  </HeaderTitle>
                  <HeaderContent>
                    <HeaderMessageText>{ topOffer.data.offer }</HeaderMessageText>
                  </HeaderContent>
                </HeaderBody>
              </HeaderWrapper>
              <View>
                <Button
                  buttonStyle={{ backgroundColor: '#ffffff', borderRadius: 8, paddingLeft: 12, paddingRight: 12 }}
                  titleStyle={{ color: '#ff7e5c', fontSize: 17, fontWeight: 'bold' }}
                  title={topOffer.data.link_label}
                  onPress={onLinkClick}
                />
              </View>
              <View>
                <Button
                  buttonStyle={{ backgroundColor: '#ff7e5c', borderRadius: 8, marginLeft: 20, paddingLeft: 15, paddingRight: 15 }}
                  titleStyle={{ color: '#ffffff', fontSize: 20 }}
                  title='&times;'
                  onPress={closeOffer}
                />
              </View>
            </Header>
          </SafeWrap>
        </Content>
      </Swipeable>
    </Layout>
  )
}
FullScreenOffer.propTypes = {
  className: PropTypes.string,
  offers: PropTypes.array,
  cid: PropTypes.string
}

const SafeWrap = styled.SafeAreaView``


const Header = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding-left: 14px;
  padding-right: 8px;
`

const HeaderWrapper = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
  border-radius: 6px;
`

const HeaderAvatar = styled.View`
  width: 31px;
  height: 31px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
`

const HeaderAvatarImage = styled.Image`
  width: 31px;
  height: 31px;
  border-radius: 4px;
`

const HeaderBody = styled.View`
  flex: 1;
  justify-content: flex-start;
  margin-left: 10px;
`

const HeaderTitle = styled.View`
  flex-direction: row;
  align-items: center;
`

const HeaderAuthor = styled.Text`
  font-size: 17px;
  font-family: Gilroy-Bold;
  color: #ffffff;
`

const HeaderSentAt = styled.Text`
  margin-left: 6px;
  font-size: 13px;
  font-family: Gilroy-Regular;
  color: rgba(255, 255, 255, 0.6);
`

const HeaderContent = styled.View`
  margin-top: 6px;
`

const HeaderMessageText = styled.Text`
  font-size: 15px;
  line-height: 15px;
  font-family: Gilroy-Regular;
  color: rgba(255, 255, 255, 0.7);
`
const Content = styled.View`
  display: flex;
  background-color: rgb(255, 128, 92);
  padding: 12px;
`

const Layout = styled.View`
  position: absolute;
  width: 100%;
  height: 70px;
  top: 0;
  z-index: 1;
`

const HiddenElement = styled.View`
  width: 100%
`

export default React.memo(FullScreenOffer)
