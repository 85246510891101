import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Platform, FlatList, SafeAreaView, View, ScrollView } from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import axios from 'axios'
import { BlurView } from 'expo-blur'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import secrets from '../../config/secrets'
import i18n from '../../services/i18n'
import { EventEmitter } from '../../services/events'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const areEqual = (prev, next) => {
  return true
}

const RenderItem = React.memo(({ item }) => (
  <AttendeeContainer key={item.id}>
    <Attendee>
      <Avatar color={ item.color }>
        <AvatarInitial color={ item.color }>{ item.name.substring(0, 1) }</AvatarInitial>
      </Avatar>
      <Body><Name>{ item.name }</Name></Body>
    </Attendee>
    <Divider />
  </AttendeeContainer>
), areEqual)

const fetchAttendees = (cid, params, fn) => {
  let qs = `cid=${cid}`
  if (params.nextPosition) {
    qs += `&next_position=${params.nextPosition}`
  }
  axios({
    url: `${secrets.domain}/meeting/attendees?${qs}`
  })
  .then(fn)
  .catch(() => {})
}

const Attendees = props => {
  const [time, setTime] = useState()
  const prevPosition = useRef(0)
  const flatList = useRef(null)
  const [attendees, setAttendees] = useState([])
  const [isBottom, setIsBottom] = useState(false)
  const [isNextPageLoading, setNextPageLoading] = useState(false)

  const loadNextPage = () => {
    fetchAttendees(props.cid, { nextPosition: prevPosition.current }, response => {
      console.log('response', response.data)
      setAttendees(prev => [...prev, ...response.data.attendees])
      prevPosition.current = response.data.meta.next_position
      setNextPageLoading(false)
    })
  }

  useEffect(() => {
    loadNextPage()
  }, [])

  const loadMoreItems = (isNextPageLoading || !prevPosition.current) ? () => {} : loadNextPage

  useEffect(() => {
    if (isBottom) {
      loadMoreItems()
    }
  }, [isBottom])

  const onScroll = e => {
    const paddingToBottom = 20
    const atBottom = e.nativeEvent.layoutMeasurement.height + e.nativeEvent.contentOffset.y >= e.nativeEvent.contentSize.height - paddingToBottom
    setIsBottom(atBottom)
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={props.style}>
        <Title>{ i18n.t('tabs.attendees') }</Title>
        <FlatList
          ref={flatList}
          data={attendees}
          renderItem={({ item }) => <RenderItem item={item} />}
          keyExtractor={item => item.id.toString()}
          onScroll={onScroll}
        />
      </View>
    </SafeAreaView>
  )
}
Attendees.propTypes = {
}

const Title = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 28px;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
  margin-bottom: 20px;
`

const Attendee = styled.View`
  flex-direction: row;
`

// TODO: separate avatar elements depending on platform
const AvatarTag = mobile ? BlurView : View
const Avatar = styled(AvatarTag).attrs({ intensity: 40 })`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: rgba(${props => props.color}, 0.3);
  align-items: center;
  justify-content: center;
`

const AvatarInitial = styled.Text`
  font-size: 22px;
  font-family: Gilroy-Medium;
  text-transform: uppercase;
  color: rgb(${props => props.color});
`

const Body = styled.View`
  flex: 1;
  justify-content: center;
`

const Name = styled.Text`
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#131d40'};
  font-family: Gilroy-SemiBold;
  font-size: 20px;
  margin-left: ${wp(2.4)}px;
`

const Divider = styled.View`
  height: 1px;
  background-color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};
  margin-top: ${wp(2.4)}px;
  margin-bottom: ${wp(2.4)}px;
  margin-left: ${wp(2.4) + 40}px;
`

const AttendeeContainer = styled.View`
  flex: 0 0 auto;
  margin-top: 15px;
`

export default styled(React.memo(Attendees))`
  flex: 1;
  justify-content: flex-start;
  padding-top: ${hp(1)}px;
  padding-bottom: ${hp(2.5)}px;
  padding-left: ${wp(5.3)}px;
  padding-right: ${wp(5.3)}px;
`

export {Avatar as AttendeeAvatar, AvatarInitial}
