import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import { selectStickyMessages } from '../../selectors'
import StickyMessage from './StickyMessage'

const StickyMessages = () => {
  const stickyMessages = useSelector(selectStickyMessages)
  if (!stickyMessages.length) return null

    return (
      <Container>
        {
          stickyMessages.map(message => (
            <StickyMessage
              key={message.id}
              id={message.id}
              author={message.author}
              appearIn={message.appear_in}
              data={message.data}
              type={message.type}
            />
          ))
        }
      </Container>
  )
}

const Container = styled.View`
  background-color: ${props => props.theme.darkScheme ? 'transparent' : '#ffffff'};
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.15)' : '#ececec'};
  border-top-width: 1px;
`

export default StickyMessages
