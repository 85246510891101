import React from 'react'
import PropTypes from 'prop-types'
import { Platform, View, Image, TextInput } from 'react-native'
import styled from 'styled-components/native'
import Avatar from './Avatar'
import i18n from '../../../services/i18n'

const mobile = Platform.OS === 'ios' || Platform.OS === 'android'

const Question = props => {
  const { question, answer, answeredBy: author } = props.data

  return (
    <View style={props.style}>
      <Title>{ i18n.t('chat.asked_question') }</Title>
      <QuestionText>{ question }</QuestionText>
      {
        author
        ? <Answer>
            <Avatar isAdmin={!author.cid} avatarUrl={author.avatar_url} size="small" />
            <Body>
              <AnswerWrapper>
                <AnswerText>{ author.name } ({ i18n.t('app.admin') }): { answer }</AnswerText>
              </AnswerWrapper>
            </Body>
          </Answer>
        : null
      }
    </View>
  )
}
Question.propTypes = {
  style: PropTypes.array,
  data: PropTypes.object,
}

const Title = styled.Text`
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.darkScheme ? 'rgba(255, 255, 255, 0.7)' : '#555c73'};
  text-transform: uppercase;
`

const QuestionText = styled.Text`
  font-size: 22px;
  font-family: Gilroy-Regular;
  color: #3bbf9e;
  line-height: 21px;
`

const Answer = styled.View`
  margin-top: 13px;
  flex-direction: row;
  align-items: flex-start;
`

const Body = styled.View`
  margin-left: 6px;
  flex: 1;
`

const AnswerWrapper = styled.View`
  flex-direction: row;
`

const AnswerText = styled.Text`
  font-family: Gilroy-Regular;
  font-size: 16px;
  line-height: 21px;
  color: ${props => props.theme.darkScheme ? '#ffffff' : '#484d60'};
  ${() => mobile ? '' : 'white-space: pre-wrap;'}
`

export default styled(Question)`

`
