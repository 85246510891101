import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Svg, { Path } from 'react-native-svg'

const TabsHandler = props => (
  <TouchableOpacity style={props.style} onPress={props.onPress}>
    <TabsHandlerIcon />
  </TouchableOpacity>
)
TabsHandler.propTypes = {
}

const TabsHandlerIcon = () => (
  <Svg width="15" height="32" viewBox="0 0 15 32" fill="none">
    <Path d="M6.63501 22.6667L3.61914 16L6.63501 9.33337M10.8572 22.6667L7.84136 16L10.8572 9.33337" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
)

export default styled(TabsHandler)`
  position: absolute;
  left: 3px;
  flex-direction: row;
  align-items: center;
`
