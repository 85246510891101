import axios from 'axios'
import secrets from '../config/secrets'

const fetchStickyMessagesBegin = () => ({ type: 'FETCH_STICKY_MESSAGES_BEGIN' })
const fetchStickyMessagesSuccess = ({ sticky_messages }) => ({ type: 'FETCH_STICKY_MESSAGES_SUCCESS', sticky_messages })
const fetchStickyMessagesError = error => ({ type: 'FETCH_STICKY_MESSAGES_ERROR', error })

export function fetchStickyMessages(cid) {
  return dispatch => {
    dispatch(fetchStickyMessagesBegin())

    const appear_in = Math.round(visitor.time * 1000)
    return axios({
      url: `${secrets.domain}/meeting/messages/stickies?cid=${cid}&appear_in=${appear_in}`
    })
    .then(response => {
      dispatch(fetchStickyMessagesSuccess(response.data))
    })
    .catch(error => {
      dispatch(fetchStickyMessagesError(error))
    })
  }
}

export const addStickyMessage = stickyMessage => ({ type: 'ADD_STICKY_MESSAGE', stickyMessage })
export const clearStickyMessages = () => ({ type: 'CLEAR_STICKY_MESSAGES' })
